import { defineComponent, ref, reactive, watch, toRefs, } from '@vue/composition-api';
import { mixins } from '@girder/components/src';
import { clientSettings } from 'dive-common/store/settings';
import { itemsPerPageOptions } from 'dive-common/constants';
import { getDatasetList } from '../api';
import { useStore } from '../store/types';
export default defineComponent({
    name: 'DataShared',
    setup() {
        const total = ref();
        const dataList = ref([]);
        const tableOptions = reactive({
            page: 1,
            sortBy: ['created'],
            sortDesc: [true],
        });
        const store = useStore();
        const { getters } = store;
        const locationStore = store.state.Location;
        const headers = [
            { text: 'File Name', value: 'name' },
            { text: '', value: 'annotator', sortable: false },
            { text: 'File Size', value: 'formattedSize' },
            { text: 'Shared By', value: 'ownerLogin' },
        ];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fixSize = mixins.sizeFormatter.methods;
        const updateOptions = async () => {
            const { sortBy, page, sortDesc, } = tableOptions;
            const limit = clientSettings.rowsPerPage;
            const offset = (page - 1) * clientSettings.rowsPerPage;
            const sort = sortBy[0] || 'created';
            const sortDir = sortDesc[0] === false ? 1 : -1;
            const shared = true;
            const response = await getDatasetList(limit, offset, sort, sortDir, shared);
            dataList.value = response.data;
            total.value = Number.parseInt(response.headers['girder-total-count'], 10);
            dataList.value.forEach((element) => {
                // eslint-disable-next-line no-param-reassign
                element.formattedSize = fixSize.formatSize(element.size);
            });
        };
        function setLocation(location) {
            store.dispatch('Location/setRouteFromLocation', location);
        }
        function isAnnotationFolder(item) {
            return item._modelType === 'folder' && item.meta.annotate;
        }
        watch(tableOptions, updateOptions, {
            deep: true,
        });
        watch(() => clientSettings.rowsPerPage, updateOptions);
        updateOptions();
        return {
            isAnnotationFolder,
            dataList,
            getters,
            updateOptions,
            setLocation,
            total,
            locationStore,
            clientSettings,
            itemsPerPageOptions,
            ...toRefs(tableOptions),
            headers,
        };
    },
});
