var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"viewer"},[_c('v-app-bar',{attrs:{"app":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('EditorMenu',_vm._b({attrs:{"tail-settings":_vm.clientSettings.annotatorPreferences.trackTails},on:{"update:tailSettings":function($event){return _vm.$set(_vm.clientSettings.annotatorPreferences, "trackTails", $event)},"update:tail-settings":function($event){return _vm.$set(_vm.clientSettings.annotatorPreferences, "trackTails", $event)},"set-annotation-state":_vm.handler.setAnnotationState,"exit-edit":_vm.handler.trackAbort}},'EditorMenu',{
          editingMode: _vm.editingMode, visibleModes: _vm.visibleModes, editingTrack: _vm.editingTrack, recipes: _vm.recipes,
          multiSelectActive: _vm.multiSelectActive, editingDetails: _vm.editingDetails,
          groupEditActive: _vm.editingGroupId !== null,
        },false),[_c('template',{slot:"delete-controls"},[_c('delete-controls',_vm._b({staticClass:"mr-2",on:{"delete-point":_vm.handler.removePoint,"delete-annotation":_vm.handler.removeAnnotation}},'delete-controls',{ editingMode: _vm.editingMode, selectedFeatureHandle: _vm.selectedFeatureHandle },false))],1)],2),(_vm.multiCamList.length > 1)?_c('v-select',{staticClass:"shrink",staticStyle:{"width":"180px"},attrs:{"value":_vm.selectedCamera,"items":_vm.multiCamList,"label":"Camera","outlined":"","hide-details":"","dense":""},on:{"change":_vm.changeCamera},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" "+_vm._s(item === _vm.defaultCamera ? '(Default)': '')+" ")]}}],null,false,1286060695)}):_vm._e(),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.context.toggle()}}},on),[_vm._v(" "+_vm._s(_vm.context.state.active ? 'mdi-chevron-right-box' : 'mdi-chevron-left-box')+" ")])]}}])},[_c('span',[_vm._v("Menus for Advanced Tools/Settings")])]),_vm._t("extension-right")]},proxy:true}],null,true)},[_vm._t("title"),_c('span',{staticClass:"title pl-3 flex-row",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.datasetName)+" "),(_vm.currentSet || _vm.sets.length > 0 || _vm.comparisonSets.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"outlined":"","color":_vm.annotationSetColor(_vm.currentSet || 'default'),"small":""},on:{"click":function($event){return _vm.context.toggle('AnnotationSets')}}},on),[_vm._v(" "+_vm._s(_vm.currentSet || 'default'))])]}}],null,false,370822814)},[_c('span',[_vm._v("Custom Annotation Set. Click to open the Annotation Set Settings")])]):_vm._e(),(_vm.displayComparisons && _vm.displayComparisons.length)?_c('span',{staticClass:"px-2",staticStyle:{"font-size":"small"}},[_vm._v(" Comparing: ")]):_vm._e(),(_vm.displayComparisons && _vm.displayComparisons.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var onIcon = ref.on;
return [_c('v-chip',_vm._g({staticClass:"pl-2",attrs:{"small":"","outlined":"","color":_vm.annotationSetColor(_vm.displayComparisons[0] || 'default')}},onIcon),[_vm._v(" "+_vm._s(_vm.displayComparisons[0]))])]}}],null,false,770468795)},[_vm._v(" Click on the "+_vm._s(_vm.currentSet || 'default')+" chip to open the Comparison Menu ")]):_vm._e(),(_vm.readonlyState)?_c('div',{staticClass:"mx-auto my-0 pa-0",staticStyle:{"line-height":"0.2em"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"warning pr-1",staticStyle:{"white-space":"nowrap","display":"inline"},attrs:{"small":""}},on),[_vm._v(" Read Only Mode "),_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1)]}}],null,false,3709663781)},[_c('span',[_vm._v("Read Only Mode: Editing, Deleting and Importing actions are disabled")])])],1):_vm._e()],1),_c('v-spacer'),_vm._t("title-right"),_c('user-guide-button',{attrs:{"annotating":""}}),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.readonlyState},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-badge',{attrs:{"overlap":"","bottom":"","color":_vm.readonlyState ? 'warning' : undefined,"icon":_vm.readonlyState ? 'mdi-exclamation-thick' : undefined,"content":!_vm.readonlyState ? _vm.pendingSaveCount : undefined,"value":_vm.readonlyState || _vm.pendingSaveCount > 0,"offset-x":"14","offset-y":"18"}},[_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.readonlyState || _vm.pendingSaveCount === 0 || _vm.saveInProgress},on:{"click":function($event){return _vm.save(_vm.currentSet)}}},[_c('v-icon',[_vm._v(" mdi-content-save ")])],1)],1)])]}}])},[_c('span',[_vm._v("Read only mode, cannot save changes")])])],2),_c('v-row',{staticClass:"fill-height",staticStyle:{"min-width":"700px"},attrs:{"no-gutters":""}},[_c('sidebar',{on:{"import-types":function($event){return _vm.trackFilters.importTypes($event)},"track-seek":function($event){return _vm.aggregateController.seek($event)}}},[[_c('v-divider'),_c('primary-attribute-track-filter',{attrs:{"toggle":_vm.context.toggle}}),(_vm.context.state.active !== 'TypeThreshold')?_c('ConfidenceFilter',{staticClass:"ma-2 mb-0",attrs:{"confidence":_vm.confidenceFilters.default},on:{"update:confidence":function($event){return _vm.$set(_vm.confidenceFilters, "default", $event)},"end":_vm.saveThreshold}},[_c('a',{staticStyle:{"text-decoration":"underline","color":"white"},on:{"click":function($event){return _vm.context.toggle('TypeThreshold')}}},[_vm._v(" Advanced ")])]):_vm._e()]],2),_c('v-col',{staticClass:"d-flex flex-column grow",staticStyle:{"position":"relative"},attrs:{"dense":""}},[(_vm.progress.loaded)?_c('div',{directives:[{name:"mousetrap",rawName:"v-mousetrap",value:([
          { bind: 'n', handler: function () { return !_vm.readonlyState && _vm.handler.trackAdd(); } },
          { bind: 'r', handler: function () { return _vm.aggregateController.resetZoom(); } },
          { bind: 'esc', handler: function () { return _vm.handler.trackAbort(); } } ]),expression:"[\n          { bind: 'n', handler: () => !readonlyState && handler.trackAdd() },\n          { bind: 'r', handler: () => aggregateController.resetZoom() },\n          { bind: 'esc', handler: () => handler.trackAbort() },\n        ]"}],staticClass:"d-flex flex-column grow"},[_c('div',{staticClass:"d-flex grow"},_vm._l((_vm.multiCamList),function(camera){return _c('div',{key:camera,staticClass:"d-flex flex-column grow",style:({ height: ("calc(100% - " + _vm.controlsHeight + "px)")}),on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.changeCamera(camera, $event)},"mouseup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return _vm.changeCamera(camera, $event)}}},[((_vm.imageData[camera].length || _vm.videoUrl[camera]) && _vm.progress.loaded)?_c(_vm.datasetType === 'image-sequence' ? 'image-annotator' :
                _vm.datasetType === 'video' ? 'video-annotator' : 'large-image-annotator',_vm._b({ref:"subPlaybackComponent",refInFor:true,tag:"component",staticClass:"fill-height",class:{'selected-camera': _vm.selectedCamera === camera && camera !== 'singleCam'},on:{"large-image-warning":function($event){return _vm.$emit('large-image-warning', true)}}},'component',{
                imageData: _vm.imageData[camera], videoUrl: _vm.videoUrl[camera],
                updateTime: _vm.updateTime, frameRate: _vm.frameRate, originalFps: _vm.originalFps, camera: camera, brightness: _vm.brightness,
                intercept: _vm.intercept, getTiles: _vm.getTiles, getTileURL: _vm.getTileURL },false),[_c('LayerManager',{attrs:{"camera":camera}})],1):_vm._e()],1)}),0),_c('ControlsContainer',_vm._b({ref:"controlsRef",staticClass:"shrink",attrs:{"collapsed":_vm.controlsCollapsed},on:{"update:collapsed":function($event){_vm.controlsCollapsed=$event},"select-track":_vm.handler.trackSelect}},'ControlsContainer',{ lineChartData: _vm.lineChartData, eventChartData: _vm.eventChartData, groupChartData: _vm.groupChartData, datasetType: _vm.datasetType },false))],1):_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[(_vm.loadError)?_c('v-alert',{attrs:{"type":"error","prominent":"","max-width":"60%"}},[_c('p',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(_vm.loadError)+" ")])]):_c('v-progress-circular',{staticClass:"main-progress-linear",attrs:{"indeterminate":_vm.progressValue === 0,"value":_vm.progressValue,"size":"100","width":"15","color":"light-blue","rotate":"-90"}},[(_vm.progressValue === 0)?_c('span',[_vm._v("Loading")]):_c('span',[_vm._v(_vm._s(_vm.progressValue)+"%")])])],1)]),_vm._t("right-sidebar")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }