import { defineComponent } from '@vue/composition-api';
import { useStore } from '../store/types';
export default defineComponent({
    name: 'ShareTab',
    props: {
        value: {
            type: Number,
            required: true,
        },
    },
    setup() {
        const store = useStore();
        const locationStore = store.state.Location;
        const { getters } = store;
        const clearSelected = () => {
            store.commit('Location/setSelected', []);
        };
        return {
            locationStore,
            getters,
            clearSelected,
        };
    },
});
