import { defineComponent, ref, watch, } from '@vue/composition-api';
import StackedVirtualSidebarContainer from 'dive-common/components/StackedVirtualSidebarContainer.vue';
import { useReadOnlyMode } from 'vue-media-annotator/provides';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import AttributeFilters from 'vue-media-annotator/components/AttributeFilters.vue';
import AttributeTimeline from 'vue-media-annotator/components/AttributeTimeline.vue';
import TooltipBtn from 'vue-media-annotator/components/TooltipButton.vue';
export default defineComponent({
    name: 'AttributesSideBar',
    components: {
        StackedVirtualSidebarContainer,
        AttributeFilters,
        AttributeTimeline,
        TooltipBtn,
    },
    props: {
        width: {
            type: Number,
            default: 300,
        },
        subCategory: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const readOnlyMode = useReadOnlyMode();
        const { visible } = usePrompt();
        const currentMode = ref(props.subCategory);
        const modes = ref(['Filtering', 'Timeline']);
        watch(() => props.subCategory, () => {
            if (props.subCategory !== undefined) {
                currentMode.value = props.subCategory;
            }
        });
        return {
            readOnlyMode,
            currentMode,
            modes,
            visible,
        };
    },
});
