import { defineComponent, ref, toRef, computed, reactive, watch, inject, nextTick, onBeforeUnmount, } from '@vue/composition-api';
import { cloneDeep } from 'lodash';
/* VUE MEDIA ANNOTATOR */
import { useAttributes, useImageEnhancements, useLineChart, useTimeObserver, useEventChart, } from 'vue-media-annotator/use';
import { Track, Group, CameraStore, StyleManager, TrackFilterControls, GroupFilterControls, } from 'vue-media-annotator/index';
import { provideAnnotator } from 'vue-media-annotator/provides';
import { ImageAnnotator, VideoAnnotator, LargeImageAnnotator, LayerManager, useMediaController, } from 'vue-media-annotator/components';
import { getResponseError } from 'vue-media-annotator/utils';
/* DIVE COMMON */
import PolygonBase from 'dive-common/recipes/polygonbase';
import HeadTail from 'dive-common/recipes/headtail';
import EditorMenu from 'dive-common/components/EditorMenu.vue';
import ConfidenceFilter from 'dive-common/components/ConfidenceFilter.vue';
import UserGuideButton from 'dive-common/components/UserGuideButton.vue';
import DeleteControls from 'dive-common/components/DeleteControls.vue';
import ControlsContainer from 'dive-common/components/ControlsContainer.vue';
import Sidebar from 'dive-common/components/Sidebar.vue';
import { useModeManager, useSave } from 'dive-common/use';
import clientSettingsSetup, { clientSettings } from 'dive-common/store/settings';
import { useApi } from 'dive-common/apispec';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import context from 'dive-common/store/context';
import GroupSidebarVue from './GroupSidebar.vue';
import MultiCamToolsVue from './MultiCamTools.vue';
import PrimaryAttributeTrackFilter from './PrimaryAttributeTrackFilter.vue';
export default defineComponent({
    components: {
        ControlsContainer,
        DeleteControls,
        Sidebar,
        LayerManager,
        VideoAnnotator,
        ImageAnnotator,
        LargeImageAnnotator,
        ConfidenceFilter,
        UserGuideButton,
        EditorMenu,
        PrimaryAttributeTrackFilter,
    },
    // TODO: remove this in vue 3
    props: {
        id: {
            type: String,
            required: true,
        },
        revision: {
            type: Number,
            default: undefined,
        },
        readOnlyMode: {
            type: Boolean,
            default: false,
        },
        currentSet: {
            type: String,
            default: '',
        },
        comparisonSets: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, ctx) {
        const { prompt } = usePrompt();
        const loadError = ref('');
        const baseMulticamDatasetId = ref(null);
        const datasetId = toRef(props, 'id');
        const multiCamList = ref(['singleCam']);
        const defaultCamera = ref('singleCam');
        const playbackComponent = ref(undefined);
        const readonlyState = computed(() => props.readOnlyMode
            || props.revision !== undefined || !!(props.comparisonSets && props.comparisonSets.length));
        const sets = ref([]);
        const displayComparisons = ref(props.comparisonSets.length
            ? props.comparisonSets.slice(0, 1) : props.comparisonSets);
        const selectedSet = ref('');
        const { aggregateController, onResize, clear: mediaControllerClear, } = useMediaController();
        const { time, updateTime, initialize: initTime } = useTimeObserver();
        const imageData = ref({ singleCam: [] });
        const datasetType = ref('image-sequence');
        const datasetName = ref('');
        const saveInProgress = ref(false);
        const videoUrl = ref({});
        const { loadDetections, loadMetadata, saveMetadata, getTiles, getTileURL, } = useApi();
        const progress = reactive({
            // Loaded flag prevents annotator window from populating
            // with stale data from props, for example if a persistent store
            // like vuex is used to drive them.
            loaded: false,
            // Tracks loaded
            progress: 0,
            // Total tracks
            total: 0,
        });
        const controlsRef = ref();
        const controlsHeight = ref(0);
        const controlsCollapsed = ref(false);
        const progressValue = computed(() => {
            if (progress.total > 0 && (progress.progress !== progress.total)) {
                return Math.round((progress.progress / progress.total) * 100);
            }
            return 0;
        });
        /**
         * Annotation window style source based on value of timeline visualization
         */
        const colorBy = computed(() => {
            var _a;
            if (((_a = controlsRef.value) === null || _a === void 0 ? void 0 : _a.currentView) === 'Groups') {
                return 'group';
            }
            return 'track';
        });
        const { save: saveToServer, markChangesPending, discardChanges, pendingSaveCount, addCamera: addSaveCamera, removeCamera: removeSaveCamera, } = useSave(datasetId, readonlyState);
        const { imageEnhancements, brightness, intercept, setSVGFilters, } = useImageEnhancements();
        const recipes = [
            new PolygonBase(),
            new HeadTail(),
        ];
        const vuetify = inject('vuetify');
        const trackStyleManager = new StyleManager({ markChangesPending, vuetify });
        const groupStyleManager = new StyleManager({ markChangesPending, vuetify });
        const cameraStore = new CameraStore({ markChangesPending });
        // This context for removal
        const removeGroups = (id) => {
            cameraStore.removeGroups(id);
        };
        const setTrackType = (id, newType, confidenceVal, currentType) => {
            cameraStore.setTrackType(id, newType, confidenceVal, currentType);
        };
        const removeTypes = (id, types) => cameraStore.removeTypes(id, types);
        const getTracksMerged = (id) => cameraStore.getTracksMerged(id);
        const groupFilters = new GroupFilterControls({
            sorted: cameraStore.sortedGroups,
            markChangesPending: markChangesPending,
            remove: removeGroups,
            setType: setTrackType,
            removeTypes,
        });
        // This context for removal
        const removeTracks = (id) => {
            cameraStore.removeTracks(id);
        };
        const trackFilters = new TrackFilterControls({
            sorted: cameraStore.sortedTracks,
            remove: removeTracks,
            markChangesPending: markChangesPending,
            lookupGroups: cameraStore.lookupGroups,
            getTrack: (track, camera = 'singleCam') => (cameraStore.getTrack(track, camera)),
            groupFilterControls: groupFilters,
            setType: setTrackType,
            removeTypes,
        });
        clientSettingsSetup(trackFilters.allTypes);
        // Provides wrappers for actions to integrate with settings
        const { linkingTrack, linkingCamera, multiSelectList, multiSelectActive, selectedFeatureHandle, selectedTrackId, editingGroupId, handler, editingMode, editingDetails, visibleModes, selectedKey, selectedCamera, editingTrack, } = useModeManager({
            recipes,
            trackFilterControls: trackFilters,
            groupFilterControls: groupFilters,
            cameraStore,
            aggregateController,
            readonlyState,
        });
        const { attributesList: attributes, loadAttributes, setAttribute, deleteAttribute, attributeFilters, deleteAttributeFilter, addAttributeFilter, modifyAttributeFilter, sortAndFilterAttributes, setTimelineEnabled, setTimelineFilter, attributeTimelineData, timelineFilter, timelineEnabled, } = useAttributes({
            markChangesPending,
            trackStyleManager,
            selectedTrackId,
            cameraStore,
        });
        const allSelectedIds = computed(() => {
            const selected = selectedTrackId.value;
            if (selected !== null) {
                return multiSelectList.value.concat(selected);
            }
            return multiSelectList.value;
        });
        const { lineChartData } = useLineChart({
            enabledTracks: trackFilters.enabledAnnotations,
            typeStyling: trackStyleManager.typeStyling,
            allTypes: trackFilters.allTypes,
        });
        const { eventChartData } = useEventChart({
            enabledTracks: trackFilters.enabledAnnotations,
            selectedTrackIds: allSelectedIds,
            typeStyling: trackStyleManager.typeStyling,
            getTracksMerged,
        });
        const { eventChartData: groupChartData } = useEventChart({
            enabledTracks: groupFilters.enabledAnnotations,
            typeStyling: groupStyleManager.typeStyling,
            selectedTrackIds: computed(() => {
                if (editingGroupId.value !== null) {
                    return [editingGroupId.value];
                }
                return [];
            }),
            getTracksMerged,
        });
        async function trackSplit(trackId, frame) {
            var _a, _b;
            if (typeof trackId === 'number') {
                const track = cameraStore.getTrack(trackId, selectedCamera.value);
                const groups = cameraStore.lookupGroups(trackId);
                let newtracks;
                try {
                    newtracks = track.split(frame, cameraStore.getNewTrackId(), cameraStore.getNewTrackId() + 1);
                }
                catch (err) {
                    await prompt({
                        title: 'Error while splitting track',
                        text: err,
                        positiveButton: 'OK',
                    });
                    return;
                }
                const result = await prompt({
                    title: 'Confirm',
                    text: 'Do you want to split the selected track?',
                    confirm: true,
                });
                if (!result) {
                    return;
                }
                const wasEditing = editingTrack.value;
                handler.trackSelect(null);
                const trackStore = (_a = cameraStore.camMap.value.get(selectedCamera.value)) === null || _a === void 0 ? void 0 : _a.trackStore;
                if (trackStore) {
                    trackStore.remove(trackId);
                    trackStore.insert(newtracks[0]);
                    trackStore.insert(newtracks[1]);
                }
                if (groups.length) {
                    // If the track belonged to groups, add the new tracks
                    // to the same groups the old tracks belonged to.
                    const groupStore = (_b = cameraStore.camMap.value.get(selectedCamera.value)) === null || _b === void 0 ? void 0 : _b.groupStore;
                    if (groupStore) {
                        groupStore.trackRemove(trackId);
                        groups.forEach((group) => {
                            group.removeMembers([trackId]);
                            group.addMembers({
                                [newtracks[0].id]: { ranges: [[newtracks[0].begin, newtracks[0].end]] },
                                [newtracks[1].id]: { ranges: [[newtracks[1].begin, newtracks[1].end]] },
                            });
                        });
                    }
                }
                handler.trackSelect(newtracks[1].id, wasEditing);
            }
        }
        // Remove a track from within a camera multi-track into it's own track
        function unlinkCameraTrack(trackId, camera) {
            var _a;
            const track = cameraStore.getTrack(trackId, camera);
            handler.trackSelect(null, false);
            const newTrack = Track.fromJSON({
                id: cameraStore.getNewTrackId(),
                meta: track.meta,
                begin: track.begin,
                end: track.end,
                features: track.features,
                confidencePairs: track.confidencePairs,
                attributes: track.attributes,
            });
            handler.removeTrack([trackId], true, camera);
            const trackStore = (_a = cameraStore.camMap.value.get(camera)) === null || _a === void 0 ? void 0 : _a.trackStore;
            if (trackStore) {
                trackStore.insert(newTrack, { imported: false });
            }
            handler.trackSelect(newTrack.trackId);
        }
        /**
         * Takes a BaseTrack and a merge Track and will attempt to merge the existing track
         * into the camera and baseTrack.
         * Requires that baseTrack doesn't have a track for the camera already
         * Also requires that the mergeTrack isn't a track across multiple cameras.
         */
        function linkCameraTrack(baseTrack, linkTrack, camera) {
            var _a;
            cameraStore.camMap.value.forEach((subCamera, key) => {
                const { trackStore } = subCamera;
                if (trackStore && trackStore.getPossible(linkTrack) && key !== camera) {
                    throw Error(`Attempting to link Track: ${linkTrack} to camera: ${camera} where there the track exists in another camera: ${key}`);
                }
            });
            const track = cameraStore.getTrack(linkTrack, camera);
            const selectedTrack = cameraStore.getAnyTrack(baseTrack);
            handler.removeTrack([linkTrack], true, camera);
            const newTrack = Track.fromJSON({
                id: baseTrack,
                meta: track.meta,
                begin: track.begin,
                end: track.end,
                features: track.features,
                confidencePairs: selectedTrack.confidencePairs,
                attributes: track.attributes,
            });
            const trackStore = (_a = cameraStore.camMap.value.get(camera)) === null || _a === void 0 ? void 0 : _a.trackStore;
            if (trackStore) {
                trackStore.insert(newTrack, { imported: false });
            }
            handler.trackSelect(newTrack.id);
        }
        watch(linkingTrack, () => {
            if (linkingTrack.value !== null && selectedTrackId.value !== null) {
                linkCameraTrack(selectedTrackId.value, linkingTrack.value, linkingCamera.value);
                handler.stopLinking();
            }
        });
        async function save(setVal) {
            // If editing the track, disable editing mode before save
            saveInProgress.value = true;
            if (editingTrack.value) {
                handler.trackSelect(selectedTrackId.value, false);
            }
            const saveSet = setVal === 'default' ? undefined : setVal;
            // Need to mark all items as updated for any non-default sets
            if (saveSet && setVal !== props.currentSet) {
                const singleCam = cameraStore.camMap.value.get('singleCam');
                if (singleCam) {
                    singleCam.trackStore.annotationMap.forEach((track) => {
                        markChangesPending({ action: 'upsert', track });
                    });
                }
            }
            try {
                await saveToServer({
                    customTypeStyling: trackStyleManager.getTypeStyles(trackFilters.allTypes),
                    customGroupStyling: groupStyleManager.getTypeStyles(groupFilters.allTypes),
                    confidenceFilters: trackFilters.confidenceFilters.value,
                }, saveSet);
            }
            catch (err) {
                let text = 'Unable to Save Data';
                if (err.response && err.response.status === 403) {
                    text = 'You do not have permission to Save Data to this Folder.';
                }
                await prompt({
                    title: 'Error while Saving Data',
                    text,
                    positiveButton: 'OK',
                });
                saveInProgress.value = false;
                throw err;
            }
            saveInProgress.value = false;
        }
        function saveThreshold() {
            saveMetadata(datasetId.value, {
                confidenceFilters: trackFilters.confidenceFilters.value,
            });
        }
        // Navigation Guards used by parent component
        async function warnBrowserExit(event) {
            if (pendingSaveCount.value === 0)
                return;
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        }
        async function navigateAwayGuard() {
            let result = true;
            if (pendingSaveCount.value > 0) {
                result = await prompt({
                    title: 'Save Items',
                    text: 'There is unsaved data, would you like to continue or cancel and save?',
                    positiveButton: 'Discard and Leave',
                    negativeButton: 'Don\'t Leave',
                    confirm: true,
                });
            }
            return result;
        }
        async function handleSetChange(set) {
            const guard = await navigateAwayGuard();
            if (guard) {
                ctx.emit('update:set', set);
            }
        }
        const selectCamera = async (camera, editMode = false) => {
            if (linkingCamera.value !== '' && linkingCamera.value !== camera) {
                await prompt({
                    title: 'In Linking Mode',
                    text: ['Currently in Linking Mode, please hit OK and Escape to exit',
                        'Linking mode or choose another Track in the highlighted Camera to Link'],
                    positiveButton: 'OK',
                });
                return;
            }
            // EditTrack is set false by the LayerMap before executing this
            if (selectedTrackId.value !== null) {
                // If we had a track selected and it still exists with
                // a feature length of 0 we need to remove it
                const track = cameraStore.getPossibleTrack(selectedTrackId.value, selectedCamera.value);
                if (track && track.features.length === 0) {
                    handler.trackAbort();
                }
            }
            selectedCamera.value = camera;
            /**
             * Enters edit mode if no track exists for the camera and forcing edit mode
             * or if a track exists and are alrady in edit mode we don't set it again
             * Remember trackEdit(number) is a toggle for editing mode
             */
            if (selectedTrackId.value !== null && (editMode || editingTrack.value)) {
                const track = cameraStore.getPossibleTrack(selectedTrackId.value, selectedCamera.value);
                if (track === undefined || !editingTrack.value) {
                    //Stay in edit mode for the current track
                    handler.trackEdit(selectedTrackId.value);
                }
            }
            ctx.emit('change-camera', camera);
        };
        // Handles changing camera using the dropdown or mouse clicks
        // When using mouse clicks and right button it will remain in edit mode for the selected track
        const changeCamera = (camera, event) => {
            if (selectedCamera.value === camera) {
                return;
            }
            if (event) {
                event.preventDefault();
            }
            // Left click should kick out of editing mode automatically
            if ((event === null || event === void 0 ? void 0 : event.button) === 0) {
                editingTrack.value = false;
            }
            selectCamera(camera, (event === null || event === void 0 ? void 0 : event.button) === 2);
            ctx.emit('change-camera', camera);
        };
        /** Trigger data load */
        const loadData = async () => {
            var _a, _b, _c;
            try {
                // Close and reset sideBar
                context.resetActive();
                const meta = await loadMetadata(datasetId.value);
                const defaultCameraMeta = (_a = meta.multiCamMedia) === null || _a === void 0 ? void 0 : _a.cameras[meta.multiCamMedia.defaultDisplay];
                baseMulticamDatasetId.value = datasetId.value;
                if (defaultCameraMeta !== undefined && meta.multiCamMedia) {
                    /* We're loading a multicamera dataset */
                    const { cameras } = meta.multiCamMedia;
                    multiCamList.value = Object.keys(cameras);
                    defaultCamera.value = meta.multiCamMedia.defaultDisplay;
                    changeCamera(defaultCamera.value);
                    baseMulticamDatasetId.value = datasetId.value;
                    if (!selectedCamera.value) {
                        throw new Error('Multicamera dataset without default camera specified.');
                    }
                }
                /* Otherwise, complete loading of the dataset */
                trackStyleManager.populateTypeStyles(meta.customTypeStyling);
                groupStyleManager.populateTypeStyles(meta.customGroupStyling);
                if (meta.customTypeStyling) {
                    trackFilters.importTypes(Object.keys(meta.customTypeStyling), false);
                }
                if (meta.customGroupStyling) {
                    groupFilters.importTypes(Object.keys(meta.customGroupStyling), false);
                }
                if (meta.attributes) {
                    loadAttributes(meta.attributes);
                }
                trackFilters.setConfidenceFilters(meta.confidenceFilters);
                datasetName.value = meta.name;
                initTime({
                    frameRate: meta.fps,
                    originalFps: meta.originalFps || null,
                });
                for (let i = 0; i < multiCamList.value.length; i += 1) {
                    const camera = multiCamList.value[i];
                    let cameraId = baseMulticamDatasetId.value;
                    if (multiCamList.value.length > 1) {
                        cameraId = `${baseMulticamDatasetId.value}/${camera}`;
                    }
                    // eslint-disable-next-line no-await-in-loop
                    const subCameraMeta = await loadMetadata(cameraId);
                    datasetType.value = subCameraMeta.type;
                    imageData.value[camera] = cloneDeep(subCameraMeta.imageData);
                    if (subCameraMeta.videoUrl) {
                        videoUrl.value[camera] = subCameraMeta.videoUrl;
                    }
                    cameraStore.addCamera(camera);
                    addSaveCamera(camera);
                    // eslint-disable-next-line no-await-in-loop
                    const { tracks, groups, sets: foundSets, } = await loadDetections(cameraId, props.revision, props.currentSet);
                    sets.value = foundSets.filter((item) => item);
                    if (props.currentSet !== '' || sets.value.length > 0) {
                        sets.value.push('default');
                    }
                    selectedSet.value = props.currentSet ? props.currentSet : 'default';
                    progress.total = tracks.length + groups.length;
                    const trackStore = (_b = cameraStore.camMap.value.get(camera)) === null || _b === void 0 ? void 0 : _b.trackStore;
                    const groupStore = (_c = cameraStore.camMap.value.get(camera)) === null || _c === void 0 ? void 0 : _c.groupStore;
                    if (trackStore && groupStore) {
                        // We can start sorting if our total tracks are less than 20000
                        // If greater we do one sort at the end instead to speed loading.
                        if (tracks.length < 20000) {
                            trackStore.setEnableSorting();
                        }
                        let baseSet;
                        if (props.comparisonSets.length) {
                            baseSet = selectedSet.value;
                        }
                        for (let j = 0; j < tracks.length; j += 1) {
                            if (j % 4000 === 0) {
                                /* Every N tracks, yeild some cycles for other scheduled tasks */
                                progress.progress = j;
                                // eslint-disable-next-line no-await-in-loop
                                await new Promise((resolve) => window.setTimeout(resolve, 500));
                            }
                            trackStore.insert(Track.fromJSON(tracks[j], baseSet), { imported: true });
                        }
                        for (let j = 0; j < groups.length; j += 1) {
                            if (j % 4000 === 0) {
                                /* Every N tracks, yeild some cycles for other scheduled tasks */
                                progress.progress = tracks.length + j;
                                // eslint-disable-next-line no-await-in-loop
                                await new Promise((resolve) => window.setTimeout(resolve, 500));
                            }
                            groupStore.insert(Group.fromJSON(groups[j]), { imported: true });
                        }
                    }
                    // Check if we load more data for comparions
                    if (props.comparisonSets.length) {
                        // Only compare one at a time
                        const firstSet = props.comparisonSets.slice(0, 1);
                        for (let setIndex = 0; setIndex < firstSet.length; setIndex += 1) {
                            const loadingSet = firstSet[setIndex] === 'default' ? undefined : firstSet[setIndex];
                            const { tracks: setTracks, groups: setGroups, } = await loadDetections(cameraId, props.revision, loadingSet);
                            progress.total = setTracks.length + setGroups.length;
                            if (trackStore && groupStore) {
                                // We can start sorting if our total tracks are less than 20000
                                // If greater we do one sort at the end instead to speed loading.
                                if (tracks.length < 20000) {
                                    trackStore.setEnableSorting();
                                }
                                for (let j = 0; j < setTracks.length; j += 1) {
                                    if (j % 4000 === 0) {
                                        /* Every N tracks, yeild some cycles for other scheduled tasks */
                                        progress.progress = j;
                                        // eslint-disable-next-line no-await-in-loop
                                        await new Promise((resolve) => window.setTimeout(resolve, 500));
                                    }
                                    // We need to increment the trackIds for the new comparison sets
                                    setTracks[j].id = trackStore.getNewId();
                                    trackStore.insert(Track.fromJSON(setTracks[j], firstSet[setIndex]), { imported: true });
                                }
                            }
                        }
                    }
                }
                cameraStore.camMap.value.forEach((cam, key) => {
                    const { trackStore } = cam;
                    // Enable Sorting after loading is complete if it isn't enabled already
                    if (trackStore) {
                        trackStore.setEnableSorting();
                    }
                    if (!multiCamList.value.includes(key)) {
                        cameraStore.removeCamera(key);
                        removeSaveCamera(key);
                    }
                });
                // Needs to be done after the cameraMap is created
                if (meta.attributeTrackFilters) {
                    trackFilters.loadTrackAttributesFilter(Object.values(meta.attributeTrackFilters));
                }
                progress.loaded = true;
                // If multiCam add Tools and remove group Tools
                if (cameraStore.camMap.value.size > 1) {
                    context.unregister({
                        description: 'Group Manager',
                        component: GroupSidebarVue,
                    });
                    context.register({
                        component: MultiCamToolsVue,
                        description: 'Multi Camera Tools',
                    });
                }
                else {
                    context.unregister({
                        component: MultiCamToolsVue,
                        description: 'Multi Camera Tools',
                    });
                    context.register({
                        description: 'Group Manager',
                        component: GroupSidebarVue,
                    });
                }
            }
            catch (err) {
                progress.loaded = false;
                console.error(err);
                const errorEl = document.createElement('div');
                errorEl.innerHTML = getResponseError(err);
                loadError.value = errorEl.innerText
                    .concat(". If you don't know how to resolve this, please contact the server administrator.");
                throw err;
            }
        };
        loadData();
        const reloadAnnotations = async () => {
            mediaControllerClear();
            cameraStore.clearAll();
            discardChanges();
            progress.loaded = false;
            await loadData();
            displayComparisons.value = props.comparisonSets.length
                ? props.comparisonSets.slice(0, 1) : props.comparisonSets;
        };
        watch(datasetId, reloadAnnotations);
        watch(readonlyState, () => handler.trackSelect(null, false));
        function handleResize() {
            if (controlsRef.value) {
                controlsHeight.value = controlsRef.value.$el.clientHeight;
                onResize();
            }
        }
        const observer = new ResizeObserver(handleResize);
        /* On a reload this will watch the controls element and add on observer
         * so that once done loading the or if the controlsRef is collapsed it will resize all cameras
        */
        watch(controlsRef, (previous) => {
            if (previous)
                observer.unobserve(previous.$el);
            if (controlsRef.value)
                observer.observe(controlsRef.value.$el);
        });
        watch(controlsCollapsed, async () => {
            await nextTick();
            handleResize();
        });
        onBeforeUnmount(() => {
            if (controlsRef.value)
                observer.unobserve(controlsRef.value.$el);
        });
        const globalHandler = {
            ...handler,
            save,
            trackSplit,
            setAttribute,
            deleteAttribute,
            reloadAnnotations,
            setSVGFilters,
            selectCamera,
            linkCameraTrack,
            unlinkCameraTrack,
            setChange: handleSetChange,
        };
        const useAttributeFilters = {
            attributeFilters,
            addAttributeFilter,
            deleteAttributeFilter,
            modifyAttributeFilter,
            sortAndFilterAttributes,
            setTimelineEnabled,
            setTimelineFilter,
            attributeTimelineData,
            timelineFilter,
            timelineEnabled,
        };
        provideAnnotator({
            annotatorPreferences: toRef(clientSettings, 'annotatorPreferences'),
            attributes,
            cameraStore,
            datasetId,
            editingMode,
            groupFilters,
            groupStyleManager,
            multiSelectList,
            pendingSaveCount,
            progress,
            revisionId: toRef(props, 'revision'),
            annotationSet: toRef(props, 'currentSet'),
            annotationSets: sets,
            comparisonSets: toRef(props, 'comparisonSets'),
            selectedCamera,
            selectedKey,
            selectedTrackId,
            editingGroupId,
            time,
            trackFilters,
            trackStyleManager,
            visibleModes,
            readOnlyMode: readonlyState,
            imageEnhancements,
        }, globalHandler, useAttributeFilters);
        return {
            /* props */
            aggregateController,
            confidenceFilters: trackFilters.confidenceFilters,
            cameraStore,
            controlsRef,
            controlsHeight,
            controlsCollapsed,
            colorBy,
            clientSettings,
            datasetName,
            datasetType,
            editingTrack,
            editingMode,
            editingDetails,
            eventChartData,
            groupChartData,
            imageData,
            lineChartData,
            loadError,
            multiSelectActive,
            pendingSaveCount,
            progress,
            progressValue,
            saveInProgress,
            playbackComponent,
            recipes,
            selectedFeatureHandle,
            selectedTrackId,
            editingGroupId,
            selectedKey,
            trackFilters,
            videoUrl,
            visibleModes,
            frameRate: time.frameRate,
            originalFps: time.originalFps,
            context,
            readonlyState,
            brightness,
            intercept,
            /* large image methods */
            getTiles,
            getTileURL,
            /* methods */
            handler: globalHandler,
            save,
            saveThreshold,
            updateTime,
            // multicam
            multiCamList,
            defaultCamera,
            selectedCamera,
            changeCamera,
            // For Navigation Guarding
            navigateAwayGuard,
            warnBrowserExit,
            reloadAnnotations,
            // Annotation Sets,
            sets,
            selectedSet,
            displayComparisons,
            annotationSetColor: trackStyleManager.typeStyling.value.annotationSetColor,
        };
    },
});
