import { computed, defineComponent, reactive, } from '@vue/composition-api';
import { difference, union } from 'lodash';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { useReadOnlyMode } from '../provides';
import TooltipBtn from './TooltipButton.vue';
import TypeEditor from './TypeEditor.vue';
import TypeItem from './TypeItem.vue';
/* Magic numbers involved in height calculation */
const TypeListHeaderHeight = 80;
export default defineComponent({
    name: 'FilterList',
    props: {
        showEmptyTypes: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 200,
        },
        width: {
            type: Number,
            default: 300,
        },
        filterControls: {
            type: Object,
            required: true,
        },
        styleManager: {
            type: Object,
            required: true,
        },
        group: {
            type: Boolean,
            default: false,
        },
    },
    components: { TypeEditor, TooltipBtn, TypeItem },
    setup(props) {
        const { prompt } = usePrompt();
        const readOnlyMode = useReadOnlyMode();
        // Ordering of these lists should match
        const sortingMethods = ['a-z', 'count'];
        const sortingMethodIcons = ['mdi-sort-alphabetical-ascending', 'mdi-sort-numeric-ascending'];
        const data = reactive({
            showPicker: false,
            selectedColor: '',
            selectedType: '',
            editingType: '',
            editingColor: '',
            editingThickness: 5,
            editingFill: false,
            editingOpacity: 1.0,
            valid: true,
            sortingMethod: 0,
            filterText: '',
        });
        const trackFilters = props.filterControls;
        const checkedTypesRef = trackFilters.checkedTypes;
        const allTypesRef = trackFilters.allTypes;
        const usedTypesRef = trackFilters.usedTypes;
        const typeStylingRef = props.styleManager.typeStyling;
        const filteredTracksRef = trackFilters.filteredAnnotations;
        const confidenceFiltersRef = trackFilters.confidenceFilters;
        function clickEdit(type) {
            data.selectedType = type;
            data.editingType = data.selectedType;
            data.showPicker = true;
            data.editingColor = typeStylingRef.value.color(type);
            data.editingThickness = typeStylingRef.value.strokeWidth(type);
            data.editingFill = typeStylingRef.value.fill(type);
            data.editingOpacity = typeStylingRef.value.opacity(type);
        }
        function clickSortToggle() {
            data.sortingMethod = (data.sortingMethod + 1) % sortingMethods.length;
        }
        async function clickDelete() {
            const typeDisplay = [];
            let text = [];
            if (props.group) {
                text = [
                    'This will remove the group assignment from any visible tracks and delete the group. Do you want to delete all groups of the following types:',
                ];
            }
            else {
                text = [
                    'This will remove the type from any visible track or delete the track if it is the only type. Do you want to delete all tracks of following types:',
                ];
            }
            text.push('-------');
            checkedTypesRef.value.forEach((item) => {
                typeDisplay.push(item);
                text.push(item.toString());
            });
            const result = await prompt({
                title: 'Really delete types?',
                text,
                confirm: true,
            });
            if (result) {
                trackFilters.removeTypeAnnotations([...checkedTypesRef.value]);
            }
        }
        const typeCounts = computed(() => filteredTracksRef.value.reduce((acc, filteredTrack) => {
            const confidencePair = filteredTrack.annotation
                .getType(filteredTrack.context.confidencePairIndex);
            const trackType = confidencePair;
            acc.set(trackType, (acc.get(trackType) || 0) + 1);
            return acc;
        }, new Map()));
        function sortAndFilterTypes(types) {
            const filtered = types.value
                .filter((t) => t.toLowerCase().includes(data.filterText.toLowerCase()));
            switch (sortingMethods[data.sortingMethod]) {
                case 'a-z':
                    return filtered.sort();
                case 'count':
                    return filtered.sort((a, b) => (typeCounts.value.get(b) || 0) - (typeCounts.value.get(a) || 0));
                default:
                    return filtered;
            }
        }
        const visibleTypes = computed(() => {
            if (props.showEmptyTypes) {
                return sortAndFilterTypes(allTypesRef);
            }
            return sortAndFilterTypes(usedTypesRef);
        });
        const virtualTypes = computed(() => {
            const confidenceFiltersDeRef = confidenceFiltersRef.value;
            const typeCountsDeRef = typeCounts.value;
            const typeStylingDeRef = typeStylingRef.value;
            const checkedTypesDeRef = checkedTypesRef.value;
            return visibleTypes.value.map((item) => ({
                type: item,
                confidenceFilterNum: confidenceFiltersDeRef[item] || 0,
                displayText: `${item} (${typeCountsDeRef.get(item) || 0})`,
                color: typeStylingDeRef.color(item),
                checked: checkedTypesDeRef.includes(item),
            }));
        });
        const headCheckState = computed(() => {
            const uncheckedTypes = difference(visibleTypes.value, checkedTypesRef.value);
            if (uncheckedTypes.length === 0) {
                return 1;
            }
            if (uncheckedTypes.length === visibleTypes.value.length) {
                return 0;
            }
            return -1;
        });
        function headCheckClicked() {
            if (headCheckState.value === 0) {
                /* Enable only what is filtered AND don't change what isn't filtered */
                const allVisibleAndCheckedInvisible = union(
                /* What was already checked and is currently not visible */
                difference(checkedTypesRef.value, visibleTypes.value), 
                /* What is visible */
                visibleTypes.value);
                trackFilters.updateCheckedTypes(allVisibleAndCheckedInvisible);
            }
            else {
                /* Disable whatever is both checked and filtered */
                const invisible = difference(checkedTypesRef.value, visibleTypes.value);
                trackFilters.updateCheckedTypes(invisible);
            }
        }
        function updateCheckedType(evt, type) {
            if (evt) {
                trackFilters.updateCheckedTypes(checkedTypesRef.value.concat([type]));
            }
            else {
                trackFilters.updateCheckedTypes(difference(checkedTypesRef.value, [type]));
            }
        }
        const virtualHeight = computed(() => props.height - TypeListHeaderHeight);
        return {
            data,
            headCheckState,
            visibleTypes,
            usedTypesRef,
            checkedTypesRef,
            confidenceFiltersRef,
            typeStylingRef,
            typeCounts,
            sortingMethods,
            sortingMethodIcons,
            virtualHeight,
            virtualTypes,
            readOnlyMode,
            filteredTracksRef,
            /* methods */
            clickDelete,
            clickEdit,
            clickSortToggle,
            headCheckClicked,
            setCheckedTypes: trackFilters.updateCheckedTypes,
            updateCheckedType,
        };
    },
});
