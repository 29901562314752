import girderRest from 'platform/web-girder/plugins/girder';
import { postProcess } from './rpc.service';
function getDataset(folderId) {
    return girderRest.get(`dive_dataset/${folderId}`);
}
async function getDatasetList(limit, offset, sort, sortDir, shared, published) {
    const response = await girderRest.get('dive_dataset', {
        params: {
            limit,
            offset,
            sort,
            sortDir,
            shared,
            published,
        },
    });
    response.data.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element._modelType = 'folder';
    });
    return response;
}
function getDatasetMedia(folderId) {
    return girderRest.get(`dive_dataset/${folderId}/media`);
}
function clone({ folderId, name, parentFolderId, revision, }) {
    return girderRest.post('dive_dataset', null, {
        params: {
            cloneId: folderId, parentFolderId, name, revision,
        },
    });
}
function makeViameFolder({ folderId, name, fps, type, }) {
    return girderRest.post('/folder', `metadata=${JSON.stringify({
        fps,
        type,
    })}`, {
        params: { parentId: folderId, name },
    });
}
async function importAnnotationFile(parentId, path, file, additive = false, additivePrepend = '', set = undefined) {
    if (file === undefined) {
        return false;
    }
    const resp = await girderRest.post('/file', null, {
        params: {
            parentType: 'folder',
            parentId,
            name: file.name,
            size: file.size,
            mimeType: file.type,
        },
    });
    if (resp.status === 200) {
        const uploadResponse = await girderRest.post('file/chunk', file, {
            params: {
                uploadId: resp.data._id,
                offset: 0,
            },
            headers: { 'Content-Type': 'application/octet-stream' },
        });
        if (uploadResponse.status === 200) {
            const final = await postProcess(parentId, true, false, additive, additivePrepend, set);
            return final.status === 200;
        }
    }
    return false;
}
function saveAttributes(folderId, args) {
    return girderRest.patch(`/dive_dataset/${folderId}/attributes`, args);
}
function saveAttributeTrackFilters(folderId, args) {
    return girderRest.patch(`/dive_dataset/${folderId}/attribute_track_filters`, args);
}
function saveMetadata(folderId, metadata) {
    return girderRest.patch(`/dive_dataset/${folderId}`, metadata);
}
function validateUploadGroup(names) {
    return girderRest.post('dive_dataset/validate_files', names);
}
export { clone, getDataset, getDatasetList, getDatasetMedia, importAnnotationFile, makeViameFolder, saveAttributes, saveAttributeTrackFilters, saveMetadata, validateUploadGroup, };
