import { computed, defineComponent, reactive, ref, watch, } from '@vue/composition-api';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { useTrackStyleManager } from 'vue-media-annotator/provides';
import AttributeRendering from './AttributeRendering.vue';
import AttributeValueColors from './AttributeValueColors.vue';
import AttributeNumberValueColors from './AttributeNumberValueColors.vue';
export default defineComponent({
    name: 'AttributeSettings',
    components: {
        AttributeRendering,
        AttributeValueColors,
        AttributeNumberValueColors,
    },
    props: {
        selectedAttribute: {
            type: Object,
            required: true,
        },
        error: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const { prompt } = usePrompt();
        const trackStyleManager = useTrackStyleManager();
        const currentTab = ref('Main');
        const baseSettings = reactive({
            name: props.selectedAttribute.name,
            belongs: props.selectedAttribute.belongs,
            datatype: props.selectedAttribute.datatype,
            areSettingsValid: false,
            editor: props.selectedAttribute.editor,
            values: props.selectedAttribute.values ? props.selectedAttribute.values : [],
        });
        const colorSettings = reactive({
            attributeColors: props.selectedAttribute.valueColors,
            color: props.selectedAttribute.color,
            tempColor: trackStyleManager.typeStyling.value.color(baseSettings.name),
        });
        let values = props.selectedAttribute.values ? props.selectedAttribute.values : [];
        let addNew = !props.selectedAttribute.key.length;
        const form = ref(null);
        const colorEditor = ref(false);
        const textValues = computed({
            get: () => {
                if (values) {
                    return values.join('\n');
                }
                return '';
            },
            set: (newval) => {
                values = newval.split('\n');
            },
        });
        const attributeRendering = ref(!!props.selectedAttribute.render);
        const renderingVals = ref(props.selectedAttribute.render);
        function setDefaultValue() {
            baseSettings.name = '';
            baseSettings.belongs = 'track';
            baseSettings.datatype = 'number';
            values = [];
        }
        function add() {
            setDefaultValue();
            addNew = true;
            if (form.value) {
                form.value.resetValidation();
            }
        }
        async function submit(close = true) {
            if (form.value && !form.value.validate()) {
                return;
            }
            const data = {
                name: baseSettings.name,
                belongs: baseSettings.belongs,
                datatype: baseSettings.datatype,
                values: baseSettings.datatype === 'text' && values ? values : [],
                valueColors: colorSettings.attributeColors,
                key: `${baseSettings.belongs}_${baseSettings.name}`,
                editor: baseSettings.editor,
                color: colorSettings.color,
                render: renderingVals.value,
            };
            if (addNew) {
                emit('save', { data, close });
                addNew = false;
            }
            else {
                emit('save', { data, oldAttribute: props.selectedAttribute, close });
            }
        }
        async function deleteAttribute() {
            const result = await prompt({
                title: 'Confirm',
                text: 'Do you want to delete this attribute?',
                confirm: true,
            });
            if (!result) {
                return;
            }
            emit('delete', props.selectedAttribute);
        }
        const typeChange = (type) => {
            if (type === 'number') {
                baseSettings.editor = {
                    type: 'combo',
                };
            }
            else if (type === 'text') {
                baseSettings.editor = {
                    type: 'freeform',
                };
            }
            baseSettings.datatype = type;
        };
        const numericChange = (type) => {
            if (type === 'combo') {
                baseSettings.editor = {
                    type: 'combo',
                };
            }
            else if (type === 'slider') {
                baseSettings.editor = {
                    type: 'slider',
                    range: [0, 1],
                    steps: 0.1,
                };
            }
        };
        watch(() => baseSettings.name, () => {
            if (!colorSettings.color) {
                colorSettings.tempColor = trackStyleManager.typeStyling.value.color(baseSettings.name);
            }
        });
        const launchColorEditor = () => {
            if (!colorSettings.color) {
                colorSettings.color = colorSettings.tempColor;
            }
            colorEditor.value = true;
        };
        watch(renderingVals, () => {
            submit(false);
        });
        watch(attributeRendering, () => {
            if (renderingVals.value === undefined) {
                renderingVals.value = {
                    typeFilter: ['all'],
                    displayName: props.selectedAttribute.name,
                    displayColor: 'auto',
                    displayTextSize: -1,
                    valueColor: 'auto',
                    valueTextSize: -1,
                    order: 0,
                    location: 'outside',
                    corner: 'SE',
                    layout: 'horizontal',
                    box: false,
                    boxColor: 'auto',
                    boxThickness: 1,
                    displayWidth: {
                        type: '%',
                        val: 10,
                    },
                    displayHeight: {
                        type: 'auto',
                        val: 10,
                    },
                };
            }
            if (!attributeRendering.value) {
                renderingVals.value = undefined;
            }
        });
        return {
            baseSettings,
            colorEditor,
            values,
            addNew,
            colorSettings,
            attributeRendering,
            renderingVals,
            currentTab,
            //computed
            textValues,
            //functions
            add,
            submit,
            deleteAttribute,
            typeChange,
            numericChange,
            launchColorEditor,
        };
    },
});
