import { defineComponent, ref, onMounted, watch, } from '@vue/composition-api';
export default defineComponent({
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Boolean, Number],
            default: null,
        },
        datatype: {
            type: String,
            required: true,
        },
        values: {
            type: Array,
            default: () => [],
        },
        focus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        typeSettings: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const tempVal = ref(props.value);
        const inputBoxRef = ref(undefined);
        const boolOpts = [
            { text: '', value: undefined },
            { text: 'true', value: true },
            { text: 'false', value: false },
        ];
        if (props.datatype === 'text') {
            watch(() => props.value, (newVal) => {
                tempVal.value = newVal;
            });
        }
        onMounted(() => {
            if (props.focus && inputBoxRef.value) {
                inputBoxRef.value.focus();
            }
        });
        function blurType(e) {
            const target = e.target;
            // Datalist needs to reset if we blur on no input
            if (props.values && props.values.length && target.value === '') {
                tempVal.value = props.value;
            }
            target.blur();
        }
        function onFocus() {
            if (props.values && props.values.length) {
                tempVal.value = null;
            }
            else if (props.values && !props.values.length && inputBoxRef.value) {
                inputBoxRef.value.select();
            }
        }
        function onInputKeyEvent(e) {
            switch (e.code) {
                case 'Escape':
                case 'Enter':
                    blurType(e);
                    break;
                default:
                    break;
            }
        }
        function change(event) {
            const target = event.target;
            const { name } = props;
            const value = target.value.trim();
            if (value) {
                emit('change', { name, value });
            }
            else {
                emit('change', { name, value: undefined });
            }
            target.blur();
        }
        function sliderChange(num) {
            const { name } = props;
            emit('change', { name, value: num });
        }
        return {
            inputBoxRef,
            tempVal,
            boolOpts,
            blurType,
            onFocus,
            onInputKeyEvent,
            change,
            sliderChange,
        };
    },
});
