var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TrackSettings"},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":"","width":"300","color":"blue-grey darken-3"}},[_c('div',{staticClass:"subheading"},[_vm._v(" New Annotation Settings ")]),_c('v-row',{attrs:{"align":"end","dense":""}},[_c('v-col',{staticClass:"mx-2 px-0",attrs:{"cols":"2"}},[_vm._v(" Mode: ")]),_c('v-col',[_c('v-select',{staticClass:"ml-0 pa-0",attrs:{"x-small":"","items":_vm.modes,"dense":"","hide-details":""},model:{value:(_vm.clientSettings.trackSettings.newTrackSettings.mode),callback:function ($$v) {_vm.$set(_vm.clientSettings.trackSettings.newTrackSettings, "mode", $$v)},expression:"clientSettings.trackSettings.newTrackSettings.mode"}})],1),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.help.mode[_vm.clientSettings.trackSettings.newTrackSettings.mode])+" ")])])],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"end","dense":""}},[_c('v-col',{staticClass:"mx-2",attrs:{"cols":"2"}},[_vm._v(" Type: ")]),_c('v-col',[_c('v-combobox',{staticClass:"ml-0 pa-0",attrs:{"x-small":"","items":_vm.typeList,"dense":"","hide-details":""},model:{value:(_vm.clientSettings.trackSettings.newTrackSettings.type),callback:function ($$v) {_vm.$set(_vm.clientSettings.trackSettings.newTrackSettings, "type", $$v)},expression:"clientSettings.trackSettings.newTrackSettings.type"}})],1),_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.help.type))])])],1)],1),(_vm.clientSettings.trackSettings.newTrackSettings.mode === 'Track')?[_c('v-row',[_c('v-col',{staticClass:"py-1"},[_c('v-switch',{staticClass:"my-0 ml-1 pt-0",attrs:{"dense":"","label":"Advance Frame","hide-details":""},model:{value:(
              _vm.clientSettings.trackSettings.newTrackSettings.modeSettings.Track.autoAdvanceFrame),callback:function ($$v) {_vm.$set(_vm.clientSettings.trackSettings.newTrackSettings.modeSettings.Track, "autoAdvanceFrame", $$v)},expression:"\n              clientSettings.trackSettings.newTrackSettings.modeSettings.Track.autoAdvanceFrame"}})],1),_c('v-col',{staticClass:"py-1 shrink",attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}],null,false,1282254411)},[_c('span',[_vm._v(_vm._s(_vm.help.autoAdvanceFrame))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1"},[_c('v-switch',{staticClass:"my-0 ml-1 pt-0",attrs:{"dense":"","label":"Interpolate","hide-details":""},model:{value:(
              _vm.clientSettings.trackSettings.newTrackSettings.modeSettings.Track.interpolate),callback:function ($$v) {_vm.$set(_vm.clientSettings.trackSettings.newTrackSettings.modeSettings.Track, "interpolate", $$v)},expression:"\n              clientSettings.trackSettings.newTrackSettings.modeSettings.Track.interpolate"}})],1),_c('v-col',{staticClass:"py-1 shrink",attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}],null,false,1282254411)},[_c('span',[_vm._v(_vm._s(_vm.help.interpolate))])])],1)],1)]:_vm._e(),(_vm.clientSettings.trackSettings.newTrackSettings.mode === 'Detection')?_c('v-row',[_c('v-col',{staticClass:"py-1"},[_c('v-switch',{staticClass:"my-0 ml-1 pt-0",attrs:{"dense":"","label":"Continuous","hide-details":""},model:{value:(
            _vm.clientSettings.trackSettings.newTrackSettings.modeSettings.Detection.continuous),callback:function ($$v) {_vm.$set(_vm.clientSettings.trackSettings.newTrackSettings.modeSettings.Detection, "continuous", $$v)},expression:"\n            clientSettings.trackSettings.newTrackSettings.modeSettings.Detection.continuous"}})],1),_c('v-col',{staticClass:"py-1 shrink",attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}],null,false,1282254411)},[_c('span',[_vm._v(_vm._s(_vm.help.continuous))])])],1)],1):_vm._e(),_c('v-divider',{staticClass:"my-2"}),_c('div',{staticClass:"subheading"},[_vm._v(" Deletion Settings ")]),_c('v-row',{attrs:{"align":"end","dense":""}},[_c('v-col',{staticClass:"py-1"},[_c('v-switch',{staticClass:"my-0 ml-1 pt-0",attrs:{"dense":"","label":"Prompt User","hide-details":""},model:{value:(_vm.clientSettings.trackSettings.deletionSettings.promptUser),callback:function ($$v) {_vm.$set(_vm.clientSettings.trackSettings.deletionSettings, "promptUser", $$v)},expression:"clientSettings.trackSettings.deletionSettings.promptUser"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.help.prompt))])])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }