import Install, { watch, reactive } from '@vue/composition-api';
import { cloneDeep, merge } from 'lodash';
import Vue from 'vue';
const defaultSettings = {
    trackSettings: {
        newTrackSettings: {
            mode: 'Track',
            type: 'unknown',
            modeSettings: {
                Track: {
                    autoAdvanceFrame: false,
                    interpolate: false,
                },
                Detection: {
                    continuous: false,
                },
            },
        },
        deletionSettings: {
            promptUser: true,
        },
    },
    groupSettings: {
        newGroupSettings: {
            type: 'unknown',
        },
    },
    typeSettings: {
        showEmptyTypes: false,
        lockTypes: false,
    },
    rowsPerPage: 20,
    annotationFPS: 10,
    annotatorPreferences: {
        trackTails: {
            before: 20,
            after: 10,
        },
    },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hydrate(obj) {
    return merge(cloneDeep(defaultSettings), obj);
}
// TODO remove this: this won't be necessary in Vue 3
Vue.use(Install);
//Load default settings initially
const storedSettings = JSON.parse(localStorage.getItem('Settings') || '{}');
const clientSettings = reactive(hydrate(storedSettings));
function saveSettings() {
    localStorage.setItem('Settings', JSON.stringify(clientSettings));
}
export default function setup(allTypes) {
    // If a type is deleted, reset the default new track type to unknown
    watch(allTypes, (newval) => {
        if (newval.indexOf(clientSettings.trackSettings.newTrackSettings.type) === -1) {
            clientSettings.trackSettings.newTrackSettings.type = 'unknown';
        }
    });
}
watch(clientSettings, saveSettings);
export { clientSettings, };
