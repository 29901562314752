import { defineComponent, ref, } from '@vue/composition-api';
import { cloneDeep } from 'lodash';
import TooltipBtn from '../TooltipButton.vue';
import AttributeNumberFilterSettings from './AttributeNumberFilterSettings.vue';
export default defineComponent({
    name: 'AttributeNumberFilter',
    props: {
        attributeFilter: {
            type: Object,
            required: true,
        },
        filterNames: {
            type: Array,
            required: true,
        },
    },
    components: { TooltipBtn, AttributeNumberFilterSettings },
    setup(props, { emit }) {
        const settingsDialog = ref(false);
        const copiedFilter = ref(null);
        // Ordering of these lists should match
        const setValue = (val) => {
            //update the filter value
            emit('update-value', val);
        };
        const setActive = (val) => {
            //update the filter value
            emit('update-active', val);
        };
        const showSettings = () => {
            copiedFilter.value = cloneDeep(props.attributeFilter);
            settingsDialog.value = true;
        };
        const saveChanges = () => {
            // Adjust value if out of range
            if (copiedFilter.value && copiedFilter.value.range) {
                const val = copiedFilter.value.value;
                const lowRange = copiedFilter.value.range[0];
                const highRange = copiedFilter.value.range[1];
                if (val < lowRange || val > highRange) {
                    copiedFilter.value.value = lowRange;
                }
            }
            emit('save-changes', copiedFilter.value);
            settingsDialog.value = false;
        };
        return {
            settingsDialog,
            copiedFilter,
            /* methods */
            showSettings,
            saveChanges,
            setValue,
            setActive,
        };
    },
});
