import { defineComponent, ref, computed, watch, } from '@vue/composition-api';
import FileNameTimeDisplay from 'vue-media-annotator/components/controls/FileNameTimeDisplay.vue';
import { Controls, EventChart, injectAggregateController, LineChart, Timeline, } from 'vue-media-annotator/components';
import { useAttributesFilters, useCameraStore, useSelectedCamera } from '../../src/provides';
export default defineComponent({
    components: {
        Controls,
        EventChart,
        FileNameTimeDisplay,
        LineChart,
        Timeline,
    },
    props: {
        lineChartData: {
            type: Array,
            required: true,
        },
        eventChartData: {
            type: Object,
            required: true,
        },
        groupChartData: {
            type: Object,
            required: true,
        },
        datasetType: {
            type: String,
            required: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        const currentView = ref('Detections');
        const ticks = ref([0.25, 0.5, 0.75, 1.0, 2.0, 4.0, 8.0]);
        const cameraStore = useCameraStore();
        const multiCam = ref(cameraStore.camMap.value.size > 1);
        const selectedCamera = useSelectedCamera();
        const hasGroups = computed(() => { var _a; return !!((_a = cameraStore.camMap.value.get(selectedCamera.value)) === null || _a === void 0 ? void 0 : _a.groupStore.sorted.value.length); });
        const { timelineEnabled, attributeTimelineData } = useAttributesFilters();
        // Format the Attribute data if it is available
        const attributeData = computed(() => {
            if (timelineEnabled.value) {
                let startFrame = Infinity;
                let endFrame = -Infinity;
                attributeTimelineData.value.forEach((item) => {
                    startFrame = Math.min(startFrame, item.minFrame);
                    endFrame = Math.max(endFrame, item.maxFrame);
                });
                const timelineData = attributeTimelineData.value.map((item) => item.data);
                return {
                    startFrame,
                    endFrame,
                    data: timelineData,
                };
            }
            return null;
        });
        /**
         * Toggles on and off the individual timeline views
         * Resizing is handled by the Annator itself.
         */
        function toggleView(type) {
            currentView.value = type;
            emit('update:collapsed', false);
        }
        watch(timelineEnabled, () => {
            if (!timelineEnabled.value && currentView.value === 'Attributes') {
                toggleView('Events');
            }
        });
        const { maxFrame, frame, seek, volume, setVolume, setSpeed, speed, } = injectAggregateController().value;
        return {
            currentView,
            toggleView,
            maxFrame,
            multiCam,
            frame,
            seek,
            volume,
            setVolume,
            speed,
            setSpeed,
            ticks,
            hasGroups,
            attributeData,
            timelineEnabled,
        };
    },
});
