/* eslint-disable max-len */
import { computed, defineComponent, ref, } from '@vue/composition-api';
import { useTrackFilters } from 'vue-media-annotator/provides';
import AttributeTrackFilter from './AttributeTrackFilter.vue';
export default defineComponent({
    name: 'PrimaryAttributeTrackFilter',
    components: {
        AttributeTrackFilter,
    },
    props: {
        toggle: {
            type: Function,
            required: true,
        },
    },
    setup() {
        const trackFilters = useTrackFilters();
        const expanded = ref(true);
        const primaryFilters = computed(() => {
            const filters = [];
            trackFilters.attributeFilters.value.forEach((item, index) => {
                if (item.primaryDisplay) {
                    filters.push(index);
                }
            });
            return filters;
        });
        const count = computed(() => trackFilters.enabledFilters.value.filter((item) => item).length || 0);
        return {
            primaryFilters,
            expanded,
            count,
        };
    },
});
