import { computed, defineComponent, ref, } from '@vue/composition-api';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import AttributeNumberFilterVue from 'vue-media-annotator/components/AttributeFilter/AttributeNumberFilter.vue';
import AttributeNumberFilterSettings from 'vue-media-annotator/components/AttributeFilter/AttributeNumberFilterSettings.vue';
import AttributeStringFilterVue from 'vue-media-annotator/components/AttributeFilter/AttributeStringFilter.vue';
import AttributeStringFilterSettings from 'vue-media-annotator/components/AttributeFilter/AttributeStringFilterSettings.vue';
import AttributeKeyFilterVue from 'vue-media-annotator/components/AttributeFilter/AttributeKeyFilter.vue';
import AttributeKeyFilterSettings from 'vue-media-annotator/components/AttributeFilter/AttributeKeyFilterSettings.vue';
import { useAttributes, useAttributesFilters, useReadOnlyMode } from '../provides';
import TooltipBtn from './TooltipButton.vue';
/* Magic numbers involved in height calculation */
const TypeListHeaderHeight = 80;
export default defineComponent({
    name: 'AttributeFilters',
    props: {
        height: {
            type: Number,
            default: 200,
        },
        width: {
            type: Number,
            default: 300,
        },
    },
    components: {
        TooltipBtn,
        AttributeNumberFilter: AttributeNumberFilterVue,
        AttributeStringFilter: AttributeStringFilterVue,
        AttributeKeyFilter: AttributeKeyFilterVue,
        AttributeKeyFilterSettings,
        AttributeStringFilterSettings,
        AttributeNumberFilterSettings,
    },
    setup(props) {
        const { prompt } = usePrompt();
        const readOnlyMode = useReadOnlyMode();
        const attributesList = useAttributes();
        const currentTab = ref('track');
        const tabMap = ref(['track', 'detection']);
        const filterTypes = ref([
            { type: 'number', description: 'Filter Number values by their range or display Top X number values' },
            { type: 'text', description: 'Filter Text values by their value.  Starting with, containing or equaling a list of values' },
            { type: 'key', description: 'Filter based on Key Names to only show a subset of attributes' },
        ]);
        const addFilterDialog = ref(false);
        const selectedAddFilterType = ref('number');
        const { attributeFilters, addAttributeFilter, deleteAttributeFilter, modifyAttributeFilter, } = useAttributesFilters();
        const addFilterPosition = ref(1);
        const addFilterFilter = ref(null);
        // Ordering of these lists should match
        const virtualHeight = computed(() => props.height - TypeListHeaderHeight);
        const filterNames = computed(() => {
            const data = ['all'];
            return data.concat(attributesList.value.map((item) => item.name));
        });
        const getComponentType = (type) => {
            if (type === 'number') {
                return 'attribute-number-filter';
            }
            if (type === 'boolean') {
                return 'attribute-bool-filter';
            }
            if (type === 'text') {
                return 'attribute-string-filter';
            }
            if (type === 'key') {
                return 'attribute-key-filter';
            }
            return '';
        };
        const deleteFilter = async (index, tab) => {
            // Delete the filter
            const result = await prompt({
                title: 'Delete Filter?',
                text: 'Are you sure you want to delete this fitler?',
                confirm: true,
            });
            if (result) {
                //Delete the filter
                deleteAttributeFilter(index, tab);
            }
        };
        const addFilter = (filterType) => {
            // add Filter
            let newFilter = {
                dataType: 'number',
                filterData: {
                    type: 'range',
                    range: [0, 1.0],
                    value: 0.10,
                    comp: '>',
                    active: false,
                    appliedTo: ['all'],
                },
            };
            if (filterType === 'text') {
                newFilter = {
                    dataType: 'text',
                    filterData: {
                        value: ['test'],
                        comp: 'contains',
                        active: false,
                        appliedTo: ['all'],
                    },
                };
            }
            if (filterType === 'key') {
                newFilter = {
                    dataType: 'key',
                    filterData: {
                        type: 'key',
                        value: true,
                        active: false,
                        appliedTo: ['all'],
                    },
                };
            }
            addFilterFilter.value = newFilter;
            addFilterPosition.value = 2;
        };
        const finalizeFilter = () => {
            if (addFilterFilter.value !== null) {
                addAttributeFilter(0, currentTab.value, addFilterFilter.value);
                addFilterDialog.value = false;
                addFilterPosition.value = 1;
                addFilterFilter.value = null;
            }
        };
        const modifyFilter = (index, tab, filter) => {
            const list = attributeFilters.value[tab];
            if (index < list.length) {
                const item = list[index];
                item.filterData = filter;
                modifyAttributeFilter(index, tab, item);
            }
        };
        const updateNewFilter = (filter) => {
            if (addFilterFilter.value !== null) {
                addFilterFilter.value.filterData = filter;
            }
        };
        const updateValue = (index, tab, value) => {
            const list = attributeFilters.value[tab];
            if (index < list.length) {
                const item = list[index];
                if (typeof (value) === 'number') {
                    item.filterData.value = value;
                }
                else if (Array.isArray(value)) {
                    item.filterData.value = value;
                }
                modifyAttributeFilter(index, tab, item);
            }
        };
        const updateActive = (index, tab, active) => {
            const list = attributeFilters.value[tab];
            if (index < list.length) {
                const item = list[index];
                item.filterData.active = active;
                modifyAttributeFilter(index, tab, item);
            }
        };
        return {
            currentTab,
            virtualHeight,
            readOnlyMode,
            attributeFilters,
            tabMap,
            filterTypes,
            filterNames,
            selectedAddFilterType,
            // Adding Filter
            addFilterPosition,
            addFilterDialog,
            addFilterFilter,
            finalizeFilter,
            addFilter,
            updateNewFilter,
            /* methods */
            getComponentType,
            deleteFilter,
            modifyFilter,
            updateValue,
            updateActive,
        };
    },
});
