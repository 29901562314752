import { defineComponent, reactive, toRefs, onBeforeUnmount, toRef, } from '@vue/composition-api';
import { GirderAuthentication } from '@girder/components/src';
import { useGirderRest } from 'platform/web-girder/plugins/girder';
export default defineComponent({
    name: 'Login',
    components: {
        GirderAuthentication,
    },
    setup(_, { root }) {
        const data = reactive({
            form: 'login',
            userDialog: true,
        });
        const brandData = toRef(root.$store.state.Brand, 'brandData');
        const girderRest = useGirderRest();
        function onLogin() {
            root.$router.push('/');
        }
        girderRest.$on('login', onLogin);
        onBeforeUnmount(() => girderRest.$off('login', onLogin));
        /** Redirect if user already logged in */
        if (girderRest.user) {
            root.$router.replace('/');
            data.userDialog = false;
        }
        return {
            ...toRefs(data),
            brandData,
        };
    },
});
