import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCameraStore, useEditingMode, useHandler, useSelectedCamera, useSelectedTrackId, useTime, useTrackFilters, } from 'vue-media-annotator/provides';
import TooltipBtn from 'vue-media-annotator/components/TooltipButton.vue';
export default defineComponent({
    name: 'MultiCamTools',
    description: 'MultiCam Tools',
    components: { TooltipBtn },
    setup() {
        const selectedCamera = useSelectedCamera();
        const inEditingMode = useEditingMode();
        const enabledTracksRef = useTrackFilters().enabledAnnotations;
        const handler = useHandler();
        const { frame } = useTime();
        const selectedTrackId = useSelectedTrackId();
        const cameraStore = useCameraStore();
        const cameras = computed(() => [...cameraStore.camMap.value.keys()]);
        const canary = ref(false);
        function _depend() {
            return canary.value;
        }
        const tracks = computed(() => {
            const trackKeyPair = {};
            _depend(); // Used for remove detections/tracks from a camera
            /* EnabledTracksRef depedency triggers update when the sortedTracks updates based
            * on track links/unlinks.  It doesn't work on same frame camera track deletions because
            * nothing is updated in the sortedTracks dependencies when that happens
            */
            if (selectedTrackId.value !== null && selectedCamera.value
                && enabledTracksRef.value.length > 0) {
                cameraStore.camMap.value.forEach((camera, key) => {
                    var _a;
                    const trackExists = camera.trackStore.getPossible(selectedTrackId.value);
                    const completeTrackExists = (trackExists !== undefined
                        && trackExists.features.length > 0);
                    trackKeyPair[key] = {
                        trackExists: completeTrackExists,
                        annotationExists: completeTrackExists && ((_a = camera.trackStore.get(selectedTrackId.value)) === null || _a === void 0 ? void 0 : _a.getFeature(frame.value)[0]) !== null,
                    };
                });
            }
            return trackKeyPair;
        });
        const existingCount = computed(() => Object.values(tracks.value).filter((item) => item.trackExists).length);
        // Delete annotation for selected camera/frame
        const deleteAnnotation = async (camera, trackId) => {
            canary.value = !canary.value;
            const track = cameraStore.getTrack(trackId, camera);
            const allTracks = cameraStore.getTrackAll(trackId);
            // If it is the only keyframe we need to remove the track from the camMap
            if (track.length === 1) {
                // Disable prompt for deleting trackk from camMap if there are other tracks on other cameras
                await handler.removeTrack([trackId], allTracks.length > 1, camera);
                if (allTracks.length === 1) {
                    handler.trackSelect(null, false);
                }
            }
            else {
                track.toggleKeyframe(frame.value);
            }
        };
        // Delete entire track, only confirm if it is the only track.
        const deleteTrack = async (camera, trackId) => {
            canary.value = !canary.value;
            const allTracks = cameraStore.getTrackAll(trackId);
            await handler.removeTrack([trackId], allTracks.length > 1, camera);
            if (allTracks.length === 1) {
                handler.trackSelect(null, false);
            }
        };
        // To force it into edit/create mode we can select the camera while in
        // editing mode for the selected Track.
        const editOrCreateAnnotation = (camera) => {
            handler.selectCamera(camera, true);
        };
        /** So for linking cameras we need to kick it out of the selectedTrack and choose a track within
         * the selected camera to merge with it.  We need to make sure that the merged
         * track only exists on the sleected camera
        **/
        const startLinking = (camera) => {
            //We can't join the other track while in editing mode so we need to disable it
            if (inEditingMode.value) {
                handler.trackSelect(selectedTrackId.value, false);
            }
            if (selectedCamera.value !== camera) {
                handler.selectCamera(camera, false);
            }
            handler.startLinking(camera);
        };
        return {
            selectedCamera,
            selectedTrackId,
            existingCount,
            frame,
            cameras,
            tracks,
            editOrCreateAnnotation,
            deleteAnnotation,
            deleteTrack,
            startLinking,
            handler,
        };
    },
});
