import { defineComponent, ref, watch, computed, reactive, } from '@vue/composition-api';
import { useTrackFilters, useTrackStyleManager } from 'vue-media-annotator/provides';
export default defineComponent({
    name: 'AttributeRendering',
    props: {
        value: {
            type: Object,
            required: true,
        },
        attribute: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const typeStylingRef = useTrackStyleManager().typeStyling;
        const trackFilterControls = useTrackFilters();
        const types = computed(() => ['all', ...trackFilterControls.allTypes.value]);
        const mainSettings = reactive({
            selected: props.value.selected || false,
            typeFilter: props.value.typeFilter || ['all'],
            order: props.value.order,
        });
        const deleteChip = (item) => {
            mainSettings.typeFilter.splice(mainSettings.typeFilter.findIndex((data) => data === item));
        };
        const layoutSettings = reactive({
            layout: props.value.layout,
            corner: props.value.corner,
            location: props.value.location,
        });
        const displayNameSettings = reactive({
            displayName: props.value.displayName,
            displayTextSize: props.value.displayTextSize,
            displayColor: props.value.displayColor,
            displayColorAuto: props.value.displayColor === 'auto',
        });
        const valueSettings = reactive({
            valueTextSize: props.value.valueTextSize,
            valueColor: props.value.valueColor,
            valueColorAuto: props.value.valueColor === 'auto',
        });
        const verticalDimensions = reactive({
            displayWidthType: props.value.displayWidth.type,
            displayWidthVal: props.value.displayWidth.val,
            displayHeightType: props.value.displayHeight.type,
            displayHeightVal: props.value.displayHeight.val,
        });
        const boxSettings = reactive({
            box: props.value.box,
            boxColor: props.value.boxColor,
            boxColorAuto: props.value.boxColor === 'auto',
            boxThickness: props.value.boxThickness,
            boxBackground: props.value.boxBackground,
            boxBackgroundSwitch: !!props.value.boxBackground,
            boxOpacity: props.value.boxOpacity,
        });
        const dropdownOptions = reactive({
            locationOptions: ['inside', 'outside'],
            layoutOptions: ['vertical', 'horizontal'],
            cornerOptions: ['SE', 'SW', 'NW'],
            displayDimOptions: ['px', '%', 'auto'],
        });
        const currentEditColor = ref('');
        const currentEditColorType = ref('');
        const editingColor = ref(false);
        const computedDisplayColor = computed(() => {
            if (displayNameSettings.displayColorAuto || displayNameSettings.displayColor === 'auto') {
                return props.attribute.color || 'white';
            }
            return displayNameSettings.displayColor;
        });
        const computedValueColor = computed(() => {
            if (valueSettings.valueColorAuto || valueSettings.valueColor === 'auto') {
                return props.attribute.color || 'white';
            }
            return valueSettings.valueColor;
        });
        const computedBoxColor = computed(() => {
            if (boxSettings.boxColorAuto || boxSettings.boxColor === 'auto') {
                return props.attribute.color || 'white';
            }
            return boxSettings.boxColor;
        });
        const updateSettings = () => {
            emit('input', {
                selected: mainSettings.selected,
                typeFilter: mainSettings.typeFilter,
                order: mainSettings.order,
                displayName: displayNameSettings.displayName,
                displayTextSize: displayNameSettings.displayTextSize,
                displayColor: displayNameSettings.displayColorAuto ? 'auto' : displayNameSettings.displayColor,
                valueTextSize: valueSettings.valueTextSize,
                valueColor: valueSettings.valueColorAuto ? 'auto' : valueSettings.valueColor,
                location: layoutSettings.location,
                layout: layoutSettings.layout,
                corner: layoutSettings.corner,
                box: boxSettings.box,
                boxColor: boxSettings.boxColorAuto ? 'auto' : boxSettings.boxColor,
                boxThickness: boxSettings.boxThickness,
                boxBackground: boxSettings.boxBackground ? boxSettings.boxBackground : undefined,
                boxOpacity: boxSettings.boxOpacity ? boxSettings.boxOpacity : undefined,
                displayWidth: {
                    type: verticalDimensions.displayWidthType,
                    val: verticalDimensions.displayWidthVal,
                },
                displayHeight: {
                    type: verticalDimensions.displayHeightType,
                    val: verticalDimensions.displayHeightVal,
                },
            });
        };
        // Watch doesn't like arrays of reactive objects
        watch(mainSettings, () => updateSettings());
        watch(layoutSettings, () => updateSettings());
        watch(displayNameSettings, () => updateSettings());
        watch(valueSettings, () => updateSettings());
        watch(verticalDimensions, () => updateSettings());
        watch(boxSettings, () => updateSettings());
        const setEditingColor = (key, state) => {
            if (!state) { // skip if auto is set
                return;
            }
            currentEditColorType.value = key;
            if (currentEditColorType.value === 'display') {
                currentEditColor.value = computedDisplayColor.value;
            }
            if (currentEditColorType.value === 'value') {
                currentEditColor.value = computedValueColor.value;
            }
            if (currentEditColorType.value === 'box') {
                currentEditColor.value = computedBoxColor.value;
            }
            if (currentEditColorType.value === 'boxBackground') {
                currentEditColor.value = boxSettings.boxBackground ? boxSettings.boxBackground : 'white';
            }
            editingColor.value = true;
        };
        const saveEditingColor = () => {
            if (currentEditColorType.value === 'display') {
                displayNameSettings.displayColor = currentEditColor.value;
            }
            if (currentEditColorType.value === 'value') {
                valueSettings.valueColor = currentEditColor.value;
            }
            if (currentEditColorType.value === 'box') {
                boxSettings.boxColor = currentEditColor.value;
            }
            if (currentEditColorType.value === 'boxBackground') {
                boxSettings.boxBackground = currentEditColor.value;
            }
            editingColor.value = false;
        };
        watch(() => boxSettings.boxBackgroundSwitch, () => {
            if (boxSettings.boxBackgroundSwitch && boxSettings.boxBackground === undefined) {
                boxSettings.boxBackground = 'white';
                boxSettings.boxOpacity = 0;
            }
            else if (!boxSettings.boxBackgroundSwitch && boxSettings.boxBackground) {
                boxSettings.boxBackground = undefined;
                boxSettings.boxOpacity = undefined;
            }
        });
        return {
            //dropdowns
            dropdownOptions,
            mainSettings,
            displayNameSettings,
            valueSettings,
            layoutSettings,
            boxSettings,
            verticalDimensions,
            // computed
            computedDisplayColor,
            computedValueColor,
            computedBoxColor,
            // color Editing
            editingColor,
            currentEditColor,
            currentEditColorType,
            setEditingColor,
            saveEditingColor,
            // type filter
            typeStylingRef,
            types,
            deleteChip,
        };
    },
});
