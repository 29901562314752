import { ref, watch, nextTick, defineComponent, } from '@vue/composition-api';
export default defineComponent({
    name: 'Prompt',
    props: {},
    setup() {
        const show = ref(false);
        const title = ref('');
        const text = ref('');
        const positiveButton = ref('Confirm');
        const negativeButton = ref('Cancel');
        const selected = ref('positive');
        const confirm = ref(false);
        /**
         * Placeholder resolver function.  Wrapped in object so that
         * its reference isn't changed on reassign.
         */
        const functions = {
            resolve(val) {
                return val;
            },
        };
        const positive = ref(null);
        const negative = ref(null);
        async function clickPositive() {
            show.value = false;
            functions.resolve(true);
        }
        async function clickNegative() {
            show.value = false;
            functions.resolve(false);
        }
        async function select() {
            if (selected.value === 'positive') {
                clickPositive();
            }
            else {
                clickNegative();
            }
        }
        async function focusPositive() {
            if (positive.value) {
                // vuetify 2 hack: need to add extra .$el property, may be removed in vuetify 3
                positive.value.$el.focus();
                selected.value = 'positive';
            }
        }
        async function focusNegative() {
            if (negative.value) {
                // vuetify 2 hack: need to add extra .$el property, may be removed in vuetify 3
                negative.value.$el.focus();
                selected.value = 'negative';
            }
        }
        watch(show, async (value) => {
            if (!value) {
                functions.resolve(false);
            }
            else if (positive.value) {
                selected.value = 'positive';
                // Needs to mount and then dialog transition, single tick doesn't work
                await nextTick();
                await nextTick();
                // vuetify 2 hack: need to add extra .$el property, may be removed in vuetify 3
                positive.value.$el.focus();
            }
        });
        return {
            show,
            title,
            text,
            positiveButton,
            negativeButton,
            selected,
            confirm,
            functions,
            clickPositive,
            clickNegative,
            select,
            positive,
            negative,
            focusPositive,
            focusNegative,
        };
    },
});
