import { computed, defineComponent, ref, shallowRef, toRef, watch, } from '@vue/composition-api';
import { usePendingSaveCount, useHandler, useTrackFilters, useRevisionId, } from 'vue-media-annotator/provides';
import AutosavePrompt from 'dive-common/components/AutosavePrompt.vue';
import { useRequest } from 'dive-common/use';
import { getDataset, getDatasetMedia, getUri, } from 'platform/web-girder/api';
import { ImageSequenceType, LargeImageType, MultiType, VideoType, } from 'dive-common/constants';
export default defineComponent({
    components: { AutosavePrompt },
    props: {
        datasetIds: {
            type: Array,
            default: () => [],
        },
        blockOnUnsaved: {
            type: Boolean,
            default: false,
        },
        buttonOptions: {
            type: Object,
            default: () => ({}),
        },
        menuOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props) {
        const savePrompt = ref(false);
        let currentSaveUrl = '';
        /** State populated from provides if the dialog exists inside a viewer context */
        let save = () => Promise.resolve();
        let pendingSaveCount = ref(0);
        let checkedTypes = ref([]);
        let revisionId = ref(null);
        if (props.blockOnUnsaved) {
            save = useHandler().save;
            pendingSaveCount = usePendingSaveCount();
            checkedTypes = useTrackFilters().checkedTypes;
            revisionId = useRevisionId();
        }
        async function doExport({ forceSave = false, url }) {
            if (pendingSaveCount.value > 0 && forceSave) {
                try {
                    await save();
                }
                finally {
                    savePrompt.value = false;
                }
            }
            else if (pendingSaveCount.value > 0 && url) {
                savePrompt.value = true;
                currentSaveUrl = url;
                return;
            }
            if (url) {
                window.location.assign(url);
            }
            else if (forceSave && currentSaveUrl) {
                window.location.assign(currentSaveUrl);
            }
            else {
                throw new Error('Expected either url OR forceSave and currentSaveUrl');
            }
        }
        const menuOpen = ref(false);
        const excludeBelowThreshold = ref(true);
        const excludeUncheckedTypes = ref(false);
        const singleDataSetId = ref(null);
        const dataset = shallowRef(null);
        const datasetMedia = shallowRef(null);
        const { request, error } = useRequest();
        const loadDatasetMeta = () => request(async () => {
            if (props.datasetIds.length > 1) {
                singleDataSetId.value = null;
                dataset.value = null;
            }
            else {
                [singleDataSetId.value] = props.datasetIds;
            }
            if (menuOpen.value && singleDataSetId.value) {
                dataset.value = (await getDataset(singleDataSetId.value)).data;
                if (dataset.value.type === 'video') {
                    datasetMedia.value = (await getDatasetMedia(singleDataSetId.value)).data;
                }
            }
        });
        watch([toRef(props, 'datasetIds'), menuOpen], loadDatasetMeta);
        const exportUrls = computed(() => {
            var _a, _b, _c;
            const params = {
                excludeBelowThreshold: excludeBelowThreshold.value,
                typeFilter: excludeUncheckedTypes.value ? JSON.stringify(checkedTypes.value) : undefined,
            };
            if (singleDataSetId.value) {
                return {
                    exportAllUrl: getUri({
                        url: 'dive_dataset/export',
                        params: {
                            ...params,
                            folderIds: JSON.stringify([singleDataSetId.value]),
                        },
                    }),
                    exportMediaUrl: ((_a = dataset.value) === null || _a === void 0 ? void 0 : _a.type) === 'video'
                        ? (_c = (_b = datasetMedia.value) === null || _b === void 0 ? void 0 : _b.video) === null || _c === void 0 ? void 0 : _c.url : getUri({
                        url: 'dive_dataset/export',
                        params: {
                            ...params,
                            includeDetections: false,
                            includeMedia: true,
                            folderIds: JSON.stringify([singleDataSetId.value]),
                        },
                    }),
                    exportDetectionsUrl: getUri({
                        url: 'dive_annotation/export',
                        params: {
                            ...params,
                            folderId: singleDataSetId.value,
                            revisionId: revisionId.value,
                        },
                    }),
                    exportDetectionsUrlTrackJSON: getUri({
                        url: 'dive_annotation/export',
                        params: {
                            ...params,
                            folderId: singleDataSetId.value,
                            revisionId: revisionId.value,
                            format: 'dive_json',
                        },
                    }),
                    exportConfigurationUrl: getUri({
                        url: `dive_dataset/${singleDataSetId.value}/configuration`,
                    }),
                };
            }
            return {
                exportAllUrl: getUri({
                    url: 'dive_dataset/export',
                    params: { folderIds: JSON.stringify(props.datasetIds) },
                }),
            };
        });
        const mediaType = computed(() => {
            if (dataset.value === null)
                return null;
            const { type } = dataset.value;
            if (type === MultiType)
                throw new Error('Cannot export multicamera dataset');
            return {
                [ImageSequenceType]: 'Image Sequence',
                [VideoType]: 'Video',
                [LargeImageType]: 'Tiled Images',
            }[type];
        });
        return {
            error,
            dataset,
            mediaType,
            excludeBelowThreshold,
            excludeUncheckedTypes,
            menuOpen,
            exportUrls,
            checkedTypes,
            revisionId,
            savePrompt,
            singleDataSetId,
            doExport,
        };
    },
});
