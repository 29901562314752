import Vue from 'vue';
export default Vue.extend({
    name: 'TooltipButton',
    props: {
        icon: {
            type: String,
            required: true,
        },
        tooltipText: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: undefined,
        },
        delay: {
            type: Number,
            default: 600,
        },
        size: {
            type: String,
            default: 'small',
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        tile: {
            type: Boolean,
            default: false,
        },
    },
});
