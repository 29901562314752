/* eslint-disable max-len */
import { computed, defineComponent, reactive, ref, } from '@vue/composition-api';
import AttributeTrackFilterVue from '../../dive-common/components/AttributeTrackFilter.vue';
import { useAttributes, useTrackFilters, useTrackStyleManager } from '../provides';
export default defineComponent({
    name: 'AttributeTrackFilters',
    props: {},
    components: {
        AttributeTrackFilterVue,
    },
    setup() {
        const trackFilters = useTrackFilters();
        const typeStylingRef = useTrackStyleManager().typeStyling;
        const types = computed(() => trackFilters.allTypes.value);
        const attributes = useAttributes();
        const attributeTypes = computed(() => {
            const typeMap = {};
            attributes.value.forEach((item) => {
                typeMap[item.name] = item.datatype;
            });
            return typeMap;
        });
        const editingFilter = ref(null);
        const addFilterDialog = ref(false);
        const filters = trackFilters.attributeFilters;
        const editing = reactive({
            atrKey: '',
            name: '',
            atrOp: '=',
            atrVal: '',
            typeFilter: [],
            range: [0, 1],
            atrType: 'track',
            filterEnabled: true,
            userDefined: true,
            primaryDisplay: true,
        });
        const dropdowns = reactive({
            atrTypeList: ['track', 'detection'],
            ops: ['=', '≠', '>', '<', '>=', '<=', 'range', 'in', 'rangeFilter'],
        });
        const existingNames = computed(() => trackFilters.attributeFilters.value.map((item) => item.name));
        // editing/adding Attribute Filter
        const editingAttributeType = computed(() => {
            if (editing.atrKey) {
                const filtered = attributes.value.filter((item) => {
                    if (editing.atrKey) {
                        return item.name === editing.atrKey;
                    }
                    return false;
                });
                if (filtered.length > 0) {
                    return filtered[0].datatype;
                }
            }
            return null;
        });
        const editingOps = computed(() => {
            if (editingAttributeType.value) {
                if (editingAttributeType.value === 'number') {
                    return ['=', '≠', '>', '<', '>=', '<=', 'range', 'rangeFilter'];
                }
                if (editingAttributeType.value === 'text') {
                    return ['=', '≠', 'in', 'contains'];
                }
                if (editingAttributeType.value === 'boolean') {
                    return ['=', '≠'];
                }
            }
            return dropdowns.ops;
        });
        const attributeList = computed(() => attributes.value.filter((item) => item.belongs === editing.atrType).map((item) => item.name));
        const changeAttributeType = () => {
            if (editing.atrOp === 'range') {
                editing.atrVal = [0, 1];
            }
            else if (editing.atrOp === 'rangeFilter') {
                editing.atrVal = 0;
            }
            else {
                editing.atrVal = '';
            }
        };
        const addEditTrackFilter = (index) => {
            if (index !== undefined) {
                const filter = filters.value[index];
                editing.atrKey = filter.attribute;
                editing.name = filter.name;
                editing.filterEnabled = filter.enabled;
                editing.atrType = filter.type;
                editing.atrOp = filter.filter.op;
                editing.atrVal = filter.filter.val;
                editing.typeFilter = filter.typeFilter;
                editing.userDefined = filter.filter.userDefined || false;
                editing.primaryDisplay = filter.primaryDisplay || false;
                if (filter.filter.range) {
                    editing.range = filter.filter.range;
                }
                editingFilter.value = index;
            }
            else {
                editing.name = '';
                editing.atrKey = '';
                editing.atrOp = '=';
                editing.typeFilter = [];
                editingFilter.value = filters.value.length;
                editing.filterEnabled = true;
            }
            addFilterDialog.value = true;
        };
        const resetFilterFields = () => {
            editing.atrKey = '';
            editing.atrOp = '=';
            editing.atrVal = '';
            editing.typeFilter = [];
            editing.name = '';
            editing.filterEnabled = true;
            addFilterDialog.value = false;
            editing.userDefined = true;
            editing.primaryDisplay = true;
        };
        const saveFilter = () => {
            const updatedTrackFilter = {
                typeFilter: editing.typeFilter,
                enabled: editing.filterEnabled,
                name: editing.name,
                attribute: editing.atrKey,
                type: editing.atrType,
                primaryDisplay: editing.primaryDisplay,
                filter: {
                    op: editing.atrOp,
                    val: editing.atrVal,
                    userDefined: editing.userDefined,
                },
            };
            if (editing.atrOp === 'rangeFilter') {
                updatedTrackFilter.filter.range = editing.range;
            }
            if (editingFilter.value !== null) {
                trackFilters.updateTrackFilter(editingFilter.value, updatedTrackFilter);
            }
            addFilterDialog.value = false;
            editing.atrKey = '';
            editing.typeFilter = [];
            editing.atrOp = '=';
            editing.atrVal = '';
            editing.name = '';
        };
        const deleteFilter = (index) => {
            trackFilters.deleteTrackFilter(index);
        };
        const areSettingsValid = ref(false);
        const deleteChip = (item) => {
            editing.typeFilter.splice(editing.typeFilter.findIndex((data) => data === item));
        };
        return {
            trackFilters,
            filters,
            editingFilter,
            attributeTypes,
            types,
            //defaults
            dropdowns,
            // editing
            editing,
            editingOps,
            editingAttributeType,
            addFilterDialog,
            attributeList,
            areSettingsValid,
            existingNames,
            changeAttributeType,
            resetFilterFields,
            deleteFilter,
            saveFilter,
            addEditTrackFilter,
            typeStylingRef,
            deleteChip,
        };
    },
});
