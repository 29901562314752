import { computed, defineComponent, ref, } from '@vue/composition-api';
import { getRecentDatasets } from 'platform/web-girder/api/admin.service';
import moment from 'moment';
export default defineComponent({
    name: 'AdminRecents',
    setup() {
        const limit = ref(50);
        const offset = ref(0);
        const table = ref([]);
        const selected = ref([]);
        const headers = ref([
            { text: 'Owner', value: 'owner' },
            { text: 'User Dir', value: 'userDir' },
            { text: 'Name', value: 'name' },
            { text: 'Created', value: 'created' },
            { text: 'Type', value: 'type' },
            { text: 'Dataset', value: 'dataset' },
        ]);
        const getData = async () => {
            table.value = (await getRecentDatasets(limit.value, offset.value)).data;
        };
        const data = computed(() => table.value.map((item) => ({
            owner: item.ownerLogin,
            userDir: item.creatorId,
            name: item.name,
            created: moment(item.created).format('dddd, MMMM D, YYYY @ h:mm a'),
            type: item.meta.type,
            dataset: item._id,
        })));
        getData();
        return {
            selected,
            table,
            headers,
            data,
        };
    },
});
