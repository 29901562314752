import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        cameraName: {
            type: String,
            required: true,
        },
        dataType: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
    },
});
