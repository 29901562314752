import { defineComponent, reactive, computed, } from '@vue/composition-api';
import { useReadOnlyMode, useGroupStyleManager, useEditingGroupId, useGroupFilterControls, useSelectedTrackId, useCameraStore, useSelectedCamera, } from '../provides';
import useVirtualScrollTo from '../use/useVirtualScrollTo';
import GroupItem from './GroupItem.vue';
export default defineComponent({
    name: 'GroupList',
    components: { GroupItem },
    props: {
        height: {
            type: Number,
            default: 420,
        },
        hotkeysDisabled: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const readOnlyMode = useReadOnlyMode();
        const cameraStore = useCameraStore();
        const selectedCamera = useSelectedCamera();
        const typeStylingRef = useGroupStyleManager().typeStyling;
        const selectedId = useEditingGroupId();
        const selectedTrack = useSelectedTrackId();
        const groupFilters = useGroupFilterControls();
        const data = reactive({
            itemHeight: 58,
        });
        const groupStoreRef = computed(() => { var _a; return (_a = cameraStore.camMap.value.get(selectedCamera.value)) === null || _a === void 0 ? void 0 : _a.groupStore; });
        const getAnnotation = (id) => {
            if (!groupStoreRef.value) {
                throw Error(`Could not find groupStore for Camera: ${selectedCamera.value}`);
            }
            const group = groupStoreRef.value.get(id);
            if (!group) {
                throw Error(`Group ID: ${id} did not exist for Camera: ${selectedCamera.value}`);
            }
            return group;
        };
        const virtualScroll = useVirtualScrollTo({
            itemHeight: data.itemHeight,
            getAnnotation,
            filteredListRef: groupFilters.filteredAnnotations,
            selectedIdRef: selectedId,
            multiSelectList: computed(() => {
                var _a, _b, _c;
                if (selectedTrack.value !== null) {
                    return Array.from((_c = (_b = (_a = groupStoreRef.value) === null || _a === void 0 ? void 0 : _a.trackMap.get(selectedTrack.value)) === null || _b === void 0 ? void 0 : _b.values()) !== null && _c !== void 0 ? _c : []);
                }
                return [];
            }),
            selectNext: () => null,
        });
        const virtualListItems = computed(() => {
            const filteredGroups = groupFilters.filteredAnnotations.value;
            const checkedTrackIds = groupFilters.checkedIDs.value;
            return filteredGroups.map((filtered) => ({
                filteredGroup: filtered,
                checkedTrackIds,
            }));
        });
        function getItemProps(item) {
            const confidencePair = item.filteredGroup.annotation.getType();
            const members = cameraStore.getGroupMemebers(item.filteredGroup.annotation.id);
            return {
                group: item.filteredGroup.annotation,
                color: typeStylingRef.value.color(confidencePair[0]),
                selected: item.filteredGroup.annotation.id === selectedId.value,
                selectedTrackId: selectedTrack.value,
                secondarySelected: (selectedTrack.value !== null)
                    ? selectedTrack.value in members
                    : false,
                inputValue: item.checkedTrackIds.includes(item.filteredGroup.annotation.id),
            };
        }
        /**
         * This doesn't actually do anything aside
         * from intercepting up/down keypresses and
         * calling preventDefault so that the list doesn't behave oddly.
         */
        const mouseTrap = computed(() => {
            const disabled = props.hotkeysDisabled;
            return [
                {
                    bind: 'up',
                    handler: (el, event) => {
                        virtualScroll.scrollPreventDefault(el, event, 'up');
                    },
                    disabled,
                },
                {
                    bind: 'down',
                    handler: (el, event) => {
                        virtualScroll.scrollPreventDefault(el, event, 'down');
                    },
                    disabled,
                },
            ];
        });
        return {
            data,
            mouseTrap,
            getItemProps,
            groupFilters,
            readOnlyMode,
            virtualListItems,
            virtualList: virtualScroll.virtualList,
        };
    },
});
