import { computed, defineComponent } from '@vue/composition-api';
import { useSelectedCamera } from '../../provides';
import { injectAggregateController } from '../annotators/useMediaController';
export default defineComponent({
    name: 'FileNameTimeDisplay',
    props: {
        displayType: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const mediaController = injectAggregateController();
        const { currentTime, frame } = mediaController.value;
        const selectedCamera = useSelectedCamera();
        const selectedCameraController = computed(() => {
            try {
                return mediaController.value.getController(selectedCamera.value);
            }
            catch (_a) {
                return undefined;
            }
        });
        const filename = computed(() => { var _a; return ((_a = selectedCameraController.value) === null || _a === void 0 ? void 0 : _a.filename.value); });
        const duration = computed(() => { var _a; return ((_a = selectedCameraController.value) === null || _a === void 0 ? void 0 : _a.duration.value); });
        const display = computed(() => {
            let value = 'unsupported display';
            if (props.displayType === 'filename') {
                value = filename.value || 'uninitialized';
            }
            if (props.displayType === 'time') {
                value = `${new Date(currentTime.value * 1000).toISOString().substr(11, 8)} / ${new Date((duration.value || 0) * 1000).toISOString().substr(11, 8)}`;
            }
            return value;
        });
        return {
            display,
            frame,
            currentTime,
            selectedCamera,
        };
    },
});
