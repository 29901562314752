import { defineComponent, ref, computed, } from '@vue/composition-api';
import { useSelectedTrackId, useCameraStore, useTime, useReadOnlyMode, useAttributesFilters, } from 'vue-media-annotator/provides';
import AttributeInput from 'dive-common/components/Attributes/AttributeInput.vue';
import PanelSubsection from 'dive-common/components/PanelSubsection.vue';
import TooltipBtn from 'vue-media-annotator/components/TooltipButton.vue';
import context from 'dive-common/store/context';
export default defineComponent({
    components: {
        AttributeInput,
        PanelSubsection,
        TooltipBtn,
    },
    props: {
        attributes: {
            type: Array,
            required: true,
        },
        editIndividual: {
            type: Object,
            default: null,
        },
        mode: {
            type: String,
            required: true,
        },
        user: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const readOnlyMode = useReadOnlyMode();
        const { frame: frameRef } = useTime();
        const selectedTrackIdRef = useSelectedTrackId();
        const { attributeFilters, sortAndFilterAttributes, timelineEnabled } = useAttributesFilters();
        const cameraStore = useCameraStore();
        const activeSettings = ref(true);
        const sortingMethods = ['a-z', '1-0'];
        const sortingMethodIcons = ['mdi-sort-alphabetical-ascending', 'mdi-sort-numeric-ascending'];
        const sortingMode = ref(0);
        const selectedTrack = computed(() => {
            if (selectedTrackIdRef.value !== null) {
                return cameraStore.getAnyTrack(selectedTrackIdRef.value);
            }
            return null;
        });
        // Using Revision to nudge the attributes after updating them
        const selectedAttributes = computed(() => {
            if (selectedTrack.value && selectedTrack.value.revision.value) {
                const t = selectedTrack.value;
                if (t !== undefined && t !== null) {
                    if (props.mode === 'Track') {
                        return t;
                    }
                    if (props.mode === 'Detection') {
                        const [real] = t.getFeature(frameRef.value);
                        return real;
                    }
                }
            }
            return null;
        });
        const filteredFullAttributes = computed(() => {
            let additionFilters = [];
            let mode = 'track';
            if (props.mode === 'Track') {
                additionFilters = attributeFilters.value.track;
            }
            else {
                additionFilters = attributeFilters.value.detection;
                mode = 'detection';
            }
            let attributeVals = {};
            if (selectedAttributes.value && selectedAttributes.value.attributes) {
                attributeVals = selectedAttributes.value.attributes;
            }
            return sortAndFilterAttributes(props.attributes, mode, attributeVals, sortingMode.value, additionFilters);
        });
        const activeAttributesCount = computed(() => props.attributes.filter((a) => selectedAttributes.value
            && selectedAttributes.value.attributes
            && selectedAttributes.value.attributes[a.name] !== undefined).length);
        function toggleActiveSettings() {
            activeSettings.value = !activeSettings.value;
        }
        function updateAttribute({ name, value }, attribute) {
            if (selectedTrackIdRef.value !== null) {
                // Tracks across all cameras get the same attributes set if they are linked
                const tracks = cameraStore.getTrackAll(selectedTrackIdRef.value);
                let user = null;
                if (attribute && attribute.user) {
                    user = props.user || null;
                }
                if (tracks.length) {
                    let updatedValue = value;
                    if (attribute.datatype === 'number' && value !== undefined) {
                        updatedValue = parseFloat(value);
                    }
                    if (props.mode === 'Track') {
                        tracks.forEach((track) => track.setAttribute(name, updatedValue, user));
                    }
                    else if (props.mode === 'Detection' && frameRef.value !== undefined) {
                        tracks.forEach((track) => track.setFeatureAttribute(frameRef.value, name, updatedValue, user));
                    }
                }
            }
        }
        function editAttribute(attribute) {
            emit('edit-attribute', attribute);
        }
        function setEditIndividual(attribute) {
            emit('set-edit-individual', attribute);
        }
        function addAttribute() {
            emit('add-attribute', props.mode);
        }
        function clickSortToggle() {
            sortingMode.value = (sortingMode.value + 1) % sortingMethods.length;
        }
        const filtersActive = computed(() => {
            let additionFilters = [];
            if (props.mode === 'Track') {
                additionFilters = attributeFilters.value.track;
            }
            else {
                additionFilters = attributeFilters.value.detection;
            }
            return !!additionFilters.find((filter) => filter.filterData.active === true);
        });
        function openFilter() {
            context.openClose('AttributesSideBar', true, 'Filtering');
        }
        function openTimeline() {
            context.openClose('AttributesSideBar', true, 'Timeline');
        }
        function getAttributeValue(attribute) {
            var _a;
            if (selectedAttributes.value && selectedAttributes.value.attributes) {
                if (!attribute.user) {
                    return selectedAttributes.value.attributes[attribute.name];
                }
                const user = props.user || null;
                if (user && ((_a = selectedAttributes.value.attributes) === null || _a === void 0 ? void 0 : _a.userAttributes) !== undefined
                    && selectedAttributes.value.attributes.userAttributes[user]) {
                    if (selectedAttributes.value.attributes
                        .userAttributes[user][attribute.name]) {
                        return (selectedAttributes.value.attributes
                            .userAttributes[user][attribute.name]);
                    }
                }
            }
            return undefined;
        }
        return {
            frameRef,
            activeAttributesCount,
            selectedAttributes,
            filteredFullAttributes,
            activeSettings,
            readOnlyMode,
            //functions
            toggleActiveSettings,
            updateAttribute,
            editAttribute,
            addAttribute,
            setEditIndividual,
            getAttributeValue,
            //Sorting & Filters
            sortingMethodIcons,
            sortingMode,
            clickSortToggle,
            openFilter,
            openTimeline,
            timelineEnabled,
            filtersActive,
        };
    },
});
