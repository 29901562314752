import { defineComponent, computed, ref, onBeforeMount, watch, toRef, } from '@vue/composition-api';
import { useApi } from 'dive-common/apispec';
import JobLaunchDialog from 'dive-common/components/JobLaunchDialog.vue';
import ImportButton from 'dive-common/components/ImportButton.vue';
import { useRequest } from 'dive-common/use';
import { simplifyTrainingName } from 'dive-common/constants';
export default defineComponent({
    name: 'RunTrainingMenu',
    components: { JobLaunchDialog, ImportButton },
    props: {
        selectedDatasetIds: {
            type: Array,
            default: () => [],
        },
        buttonOptions: {
            type: Object,
            default: () => ({}),
        },
        menuOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, { root }) {
        const brandData = toRef(root.$store.state.Brand, 'brandData');
        const { getTrainingConfigurations, runTraining } = useApi();
        const trainingConfigurations = ref(null);
        const selectedTrainingConfig = ref(null);
        const annotatedFramesOnly = ref(false);
        const { request: _runTrainingRequest, reset: dismissJobDialog, state: jobState, } = useRequest();
        const successMessage = computed(() => `Started training on ${props.selectedDatasetIds.length} dataset(s)`);
        onBeforeMount(async () => {
            const resp = await getTrainingConfigurations();
            trainingConfigurations.value = resp;
            selectedTrainingConfig.value = resp.default;
        });
        const trainingDisabled = computed(() => props.selectedDatasetIds.length === 0);
        const trainingOutputName = ref(null);
        const menuOpen = ref(false);
        const labelText = ref('');
        const labelFile = ref();
        async function runTrainingOnFolder() {
            const outputPipelineName = trainingOutputName.value;
            if (trainingDisabled.value || !outputPipelineName) {
                return;
            }
            await _runTrainingRequest(() => {
                if (!trainingConfigurations.value || !selectedTrainingConfig.value) {
                    throw new Error('Training configurations not found.');
                }
                if (labelText) {
                    return runTraining(props.selectedDatasetIds, outputPipelineName, selectedTrainingConfig.value, annotatedFramesOnly.value, labelText.value);
                }
                return runTraining(props.selectedDatasetIds, outputPipelineName, selectedTrainingConfig.value, annotatedFramesOnly.value);
            });
            menuOpen.value = false;
            trainingOutputName.value = null;
        }
        watch(labelFile, () => {
            if (labelFile.value) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    var _a;
                    labelText.value = (_a = evt.target) === null || _a === void 0 ? void 0 : _a.result;
                };
                reader.readAsText(labelFile.value);
            }
        });
        const clearLabelText = () => {
            labelText.value = '';
        };
        return {
            brandData,
            trainingConfigurations,
            selectedTrainingConfig,
            annotatedFramesOnly,
            trainingOutputName,
            menuOpen,
            trainingDisabled,
            jobState,
            successMessage,
            dismissJobDialog,
            runTrainingOnFolder,
            labelFile,
            clearLabelText,
            simplifyTrainingName,
        };
    },
});
