var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload"},[_c('v-dialog',{attrs:{"value":_vm.importMultiCamDialog,"persistent":"","overlay-opacity":"0.95","max-width":"80%"}},[(_vm.importMultiCamDialog)?_c('ImportMultiCamDialog',{attrs:{"stereo":_vm.stereo,"data-type":_vm.multiCamOpenType,"import-media":_vm.multiCamImportCheck},on:{"begin-multicam-import":function($event){return _vm.multiCamImport($event)},"abort":function($event){_vm.importMultiCamDialog = false; _vm.preUploadErrorMessage = null}}}):_vm._e()],1),_c('v-card',{attrs:{"outlined":"","color":"default"}},[_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Upload datasets")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.girderUpload && _vm.girderUpload.totalProgressPercent),expression:"girderUpload && girderUpload.totalProgressPercent"}],attrs:{"value":_vm.girderUpload && _vm.girderUpload.totalProgressPercent,"absolute":"","height":"6px"}}),_c('upload-girder',{ref:"girderUpload",staticClass:"mx-6",attrs:{"pending-uploads":_vm.pendingUploads,"pre-upload-error-message":_vm.preUploadErrorMessage,"location":_vm.location},on:{"remove-upload":_vm.remove,"update:uploading":function($event){return _vm.$emit('update:uploading', $event)},"abort":_vm.abort,"error":_vm.errorHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upload = ref.upload;
return [_vm._l((_vm.pendingUploads),function(pendingUpload,i){return _c('v-card',{key:i,staticClass:"pa-4 my-4",attrs:{"outlined":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{staticClass:"upload-name",attrs:{"value":_vm.getFilenameInputValue(pendingUpload),"rules":[function (val) { return (val || '').length > 0 || 'This field is required'; }],"required":"","label":_vm.getFilenameInputStateLabel(pendingUpload),"disabled":_vm.getFilenameInputStateDisabled(pendingUpload),"hint":_vm.getFilenameInputStateHint(pendingUpload),"persistent-hint":""},on:{"input":function($event){pendingUpload.name = $event}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.FPSOptions,"disabled":pendingUpload.uploading,"type":"number","required":"","label":"FPS","append-icon":pendingUpload.annotationFile
                  ? 'mdi-alert' : 'mdi-chevron-down',"hint":pendingUpload.annotationFile
                  ? 'should match annotation fps' : 'annotation fps',"persistent-hint":""},on:{"change":function($event){_vm.clientSettings.annotationFPS = $event}},model:{value:(pendingUpload.fps),callback:function ($$v) {_vm.$set(pendingUpload, "fps", $$v)},expression:"pendingUpload.fps"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","outlined":"","disabled":pendingUpload.uploading},on:{"click":function($event){return _vm.remove(pendingUpload)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),(!pendingUpload.createSubFolders && pendingUpload.type !== 'zip')?_c('v-row',[_c('v-col',{staticClass:"py-0 mx-2"},[_c('v-row',[_c('v-file-input',{attrs:{"multiple":"","show-size":"","counter":"","disabled":pendingUpload.uploading,"prepend-icon":['image-sequence', 'large-image'].includes(pendingUpload.type)
                      ? 'mdi-image-multiple'
                      : 'mdi-file-video',"label":pendingUpload.type === 'image-sequence'
                      ? 'Image files'
                      : pendingUpload.type === 'video'
                        ? 'Video file'
                        : 'Tiled Image files',"rules":[function (val) { return (val || '').length > 0 || 'Media Files are required'; }],"accept":_vm.filterFileUpload(pendingUpload.type)},model:{value:(pendingUpload.mediaList),callback:function ($$v) {_vm.$set(pendingUpload, "mediaList", $$v)},expression:"pendingUpload.mediaList"}})],1),_c('v-row',[_c('v-file-input',{attrs:{"show-size":"","counter":"","prepend-icon":"mdi-file-table","label":"Annotation File (Optional)","hint":"Optional","disabled":pendingUpload.uploading,"accept":_vm.filterFileUpload('annotation')},model:{value:(pendingUpload.annotationFile),callback:function ($$v) {_vm.$set(pendingUpload, "annotationFile", $$v)},expression:"pendingUpload.annotationFile"}})],1),_c('v-row',[_c('v-file-input',{attrs:{"show-size":"","counter":"","label":"Configuration File (Optional)","hint":"Optional","disabled":pendingUpload.uploading,"accept":_vm.filterFileUpload('meta')},model:{value:(pendingUpload.meta),callback:function ($$v) {_vm.$set(pendingUpload, "meta", $$v)},expression:"pendingUpload.meta"}})],1)],1)],1):_vm._e(),(pendingUpload.type === 'video')?_c('v-row',[_c('v-checkbox',{attrs:{"label":"Skip Transcoding"},model:{value:(pendingUpload.skipTranscoding),callback:function ($$v) {_vm.$set(pendingUpload, "skipTranscoding", $$v)},expression:"pendingUpload.skipTranscoding"}}),_c('v-tooltip',{attrs:{"open-delay":"200","right":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}],null,true)},[_c('span',[_vm._v("Attempt to skip transcoding of video file if it is an '.mp4' and encoded using the h264 codec. If skipping fails it will fallback to transcoding.")])])],1):_vm._e(),(_vm.uploading)?_c('span',[_vm._v(" "+_vm._s(_vm.computeUploadProgress(pendingUpload))+" ")]):_vm._e()],1)}),_c('div',[_c('v-list',[_c('v-list-item',[_c('import-button',{staticClass:"grow my-2",attrs:{"name":("Add " + (_vm.pendingUploads.length ? 'Another ' : '') + "Image Sequence"),"icon":"mdi-folder-open","open-type":"image-sequence","small":!!_vm.pendingUploads.length,"button-attrs":_vm.buttonAttrs},on:{"open":function($event){return _vm.openImport($event)},"multi-cam":_vm.openMultiCamDialog}})],1),_c('v-list-item',[_c('import-button',{staticClass:"grow my-2",attrs:{"name":("Add " + (_vm.pendingUploads.length ? 'Another ' : '') + "Video"),"icon":"mdi-file-video","small":!!_vm.pendingUploads.length,"open-type":"video","button-attrs":_vm.buttonAttrs},on:{"open":function($event){return _vm.openImport($event)},"multi-cam":_vm.openMultiCamDialog}})],1),_c('v-tooltip',{attrs:{"open-delay":"50","top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('import-button',{staticClass:"grow my-2",attrs:{"name":("Add " + (_vm.pendingUploads.length ? 'Another ' : '') + "Tiled Images"),"icon":"mdi-folder-open","open-type":"large-image","small":!!_vm.pendingUploads.length,"button-attrs":_vm.buttonAttrs},on:{"open":function($event){return _vm.openImport($event)},"multi-cam":_vm.openMultiCamDialog}})],1)]}}],null,true)},[_c('b',[_vm._v(" Allows for a single or sequence of geospatial large images for use in a tile server with formats such as: .tiff, .nitf, .ntf, .tif ")])]),_c('v-list-item',[_c('import-button',{staticClass:"grow my-2",attrs:{"name":("Add " + (_vm.pendingUploads.length ? 'Another ' : '') + "Zip File"),"icon":"mdi-zip-box","small":!!_vm.pendingUploads.length,"open-type":"zip","button-attrs":_vm.buttonAttrs},on:{"open":function($event){return _vm.openImport($event)}}})],1),_c('v-list')],1)],1),(_vm.pendingUploads.length && _vm.pendingUploads.some(function (item) { return item.type === 'zip'; }))?_c('div',[_c('h3',{staticClass:"text-center"},[_c('a',{attrs:{"target":"_blank","href":"https://kitware.github.io/dive/Web-Version/#zip-files"}},[_vm._v(" Supported Zip Files ")])])]):_vm._e(),(_vm.pendingUploads.length)?_c('v-btn',{staticClass:"my-6",attrs:{"disabled":_vm.uploading,"block":"","large":"","color":"primary"},on:{"click":upload}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v(" mdi-upload ")]),_vm._v(" Start upload ")],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }