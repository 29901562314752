import { computed, defineComponent, ref, onBeforeUnmount, } from '@vue/composition-api';
import { GirderFileManager, getLocationType, } from '@girder/components/src';
import { itemsPerPageOptions } from 'dive-common/constants';
import { clientSettings } from 'dive-common/store/settings';
import { useStore } from '../store/types';
import Upload from './Upload.vue';
import eventBus from '../eventBus';
export default defineComponent({
    components: {
        GirderFileManager,
        Upload,
    },
    setup() {
        const fileManager = ref();
        const store = useStore();
        const uploading = ref(false);
        const uploaderDialog = ref(false);
        const locationStore = store.state.Location;
        const { getters } = store;
        function setLocation(location) {
            store.dispatch('Location/setRouteFromLocation', location);
        }
        function handleNotification() {
            fileManager.value.$refs.girderBrowser.refresh();
        }
        function updateUploading(newval) {
            uploading.value = newval;
            if (!newval) {
                fileManager.value.$refs.girderBrowser.refresh();
                uploaderDialog.value = false;
            }
        }
        function isAnnotationFolder(item) {
            return item._modelType === 'folder' && item.meta.annotate;
        }
        const shouldShowUpload = computed(() => (locationStore.location
            && !getters['Location/locationIsViameFolder']
            && getLocationType(locationStore.location) === 'folder'
            && !locationStore.selected.length));
        eventBus.$on('refresh-data-browser', handleNotification);
        onBeforeUnmount(() => {
            eventBus.$off('refresh-data-browser', handleNotification);
        });
        return {
            fileManager,
            locationStore,
            getters,
            shouldShowUpload,
            uploaderDialog,
            uploading,
            clientSettings,
            itemsPerPageOptions,
            /* methods */
            isAnnotationFolder,
            handleNotification,
            setLocation,
            updateUploading,
        };
    },
});
