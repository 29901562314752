var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"hover-show-parent",style:(_vm.cssVars),attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex flex-row py-0 align-center"},[_c('v-checkbox',{staticClass:"my-1 pl-2",attrs:{"input-value":_vm.checked,"color":_vm.color,"dense":"","shrink":"","hide-details":""},on:{"change":function($event){return _vm.$emit('setCheckedTypes', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-body-2 grey--text text--lighten-1 d-flex flex-row nowrap"},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"nowrap"},on),[_vm._v(" "+_vm._s(_vm.displayText)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.displayText)+" ")])]),(_vm.confidenceFilterNum)?_c('v-tooltip',{staticClass:"align-self-end",attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"outlined"},'span',attrs,false),on),[_vm._v(" "+_vm._s((">" + _vm.confidenceFilterNum))+" ")])]}}],null,false,219603796)},[_c('span',[_vm._v("Type has threshold set individually")])]):_vm._e()],1)]},proxy:true}])}),_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hover-show-child",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('clickEdit', _vm.type)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v("Edit")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }