import { defineComponent, ref, onUnmounted, toRef, watch, } from '@vue/composition-api';
import geo from 'geojs';
import { injectCameraInitializer } from './useMediaController';
function loadImageFunc(imageDataItem, img) {
    // eslint-disable-next-line no-param-reassign
    img.src = imageDataItem.url;
}
export default defineComponent({
    name: 'LargeImageAnnotator',
    props: {
        imageData: {
            type: Array,
            required: true,
        },
        frameRate: {
            type: Number,
            required: true,
        },
        updateTime: {
            type: Function,
            required: true,
        },
        loadImageFunc: {
            type: Function,
            default: loadImageFunc,
        },
        // Range is [0, inf.)
        brightness: {
            type: Number,
            default: undefined,
        },
        camera: {
            type: String,
            default: 'singleCam',
        },
        intercept: {
            type: Number,
            default: undefined,
        },
        getTiles: {
            type: Function,
            required: true,
        },
        getTileURL: {
            type: Function,
            required: true,
        },
    },
    setup(props) {
        const loadingVideo = ref(false);
        const loadingImage = ref(true);
        const cameraInitializer = injectCameraInitializer();
        // eslint-disable-next-line prefer-const
        let geoSpatial = false;
        const { state: data, geoViewer, cursorHandler, imageCursor, container, initializeViewer, mediaController, } = cameraInitializer(props.camera, {
            // allow hoisting for these functions to pass a reference before defining them.
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            seek, pause, play, setVolume: unimplemented, setSpeed: unimplemented,
        });
        let projection;
        data.maxFrame = props.imageData.length - 1;
        // Below are configuration settings we can set until we decide on good numbers to utilize.
        let local = {
            playCache: 1,
            cacheSeconds: 6,
            frontBackRatio: 0.9,
            imgs: new Array(props.imageData.length),
            pendingImgs: new Set(),
            lastFrame: -1,
            width: 0,
            height: 0,
            levels: 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            metadata: '',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            params: '',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            currentLayer: '',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            nextLayer: '',
            nextLayerFrame: 0,
        };
        function forceUnload(imgInternal) {
            // Removal from list indicates we are no longer attempting to load this image
            local.imgs[imgInternal.frame] = undefined;
            // unset src to cancel outstanding load request
            // eslint-disable-next-line no-param-reassign
            imgInternal.image.src = '';
            local.pendingImgs.delete(imgInternal);
        }
        function _getTileURL(itemId, proj) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const returnFunc = (level, x, y, params) => {
                const updatedParams = { ...params, encoding: 'PNG' };
                if (proj) {
                    updatedParams.projection = proj;
                }
                return props.getTileURL(itemId, level, x, y, updatedParams);
            };
            return returnFunc;
        }
        async function cacheFrame(frame) {
            // eslint-disable-next-line no-unreachable
            const resp2 = await props.getTiles(props.imageData[frame].id, projection);
            const newParams = geo.util.pixelCoordinateParams(container.value, resp2.sizeX, resp2.sizeY, resp2.tileWidth, resp2.tileHeight);
            local.nextLayer._options.maxLevel = newParams.layer.maxLevel;
            local.nextLayer._options.tileWidth = newParams.layer.tileWidth;
            local.nextLayer._options.tileHeight = newParams.layer.tileWidth;
            local.nextLayer._options.tilesAtZoom = newParams.layer.tilesAtZoom;
            local.nextLayer._options.tilesMaxBounds = newParams.layer.tilesMaxBounds;
            local.nextLayer.url(_getTileURL(props.imageData[frame].id));
            local.nextLayerFrame = frame;
        }
        /**
         * When the component is unmounted, cancel all outstanding
         * requests for image load.
         */
        onUnmounted(() => Array.from(local.pendingImgs).forEach(forceUnload));
        async function seek(f) {
            if (!data.ready) {
                return;
            }
            let newFrame = f;
            if (f < 0)
                newFrame = 0;
            if (f > data.maxFrame)
                newFrame = data.maxFrame;
            local.lastFrame = data.frame;
            data.frame = newFrame;
            data.syncedFrame = newFrame;
            data.filename = props.imageData[data.frame].filename;
            if (data.frame !== 0 && local.lastFrame === data.frame) {
                return;
            }
            if (props.imageData.length > 1) {
                loadingImage.value = true;
            }
            props.updateTime(data);
            // For faster swapping between loaded large images we swap two layers.
            if (local.nextLayer) {
                if (local.nextLayerFrame === newFrame) {
                    local.currentLayer.moveDown();
                    local.currentLayer.opacity(0);
                    const ltemp = local.currentLayer;
                    local.currentLayer = local.nextLayer;
                    local.currentLayer.opacity(1.0);
                    loadingImage.value = false;
                    local.nextLayer = ltemp;
                    if (props.imageData[newFrame + 1]) {
                        cacheFrame(newFrame + 1);
                    }
                }
                else {
                    geoViewer.value.onIdle(async () => {
                        loadingImage.value = true;
                        const resp2 = await props.getTiles(props.imageData[newFrame].id, projection);
                        const newParams = geo.util.pixelCoordinateParams(container.value, resp2.sizeX, resp2.sizeY, resp2.tileWidth, resp2.tileHeight);
                        geoViewer.value.onIdle(() => {
                            local.currentLayer._options.maxLevel = newParams.layer.maxLevel;
                            local.currentLayer._options.tileWidth = newParams.layer.tileWidth;
                            local.currentLayer._options.tileHeight = newParams.layer.tileWidth;
                            local.currentLayer._options.tilesAtZoom = newParams.layer.tilesAtZoom;
                            local.currentLayer._options.tilesMaxBounds = newParams.layer.tilesMaxBounds;
                            local.currentLayer.url(_getTileURL(props.imageData[newFrame].id));
                            loadingImage.value = false;
                            // If there is another frame we begin loading it with the current position/zoom level
                            loadingImage.value = false;
                            if (props.imageData[newFrame + 1]) {
                                cacheFrame(newFrame + 1);
                            }
                        });
                    });
                }
            }
        }
        function pause() {
            data.playing = false;
            loadingVideo.value = false;
        }
        async function syncWithVideo(nextFrame) {
            if (data.playing) {
                if (nextFrame > data.maxFrame) {
                    return pause();
                }
                seek(nextFrame);
                setTimeout(() => syncWithVideo(data.frame + 1), 1000 / props.frameRate);
            }
            return undefined;
        }
        async function play() {
            try {
                data.playing = true;
                syncWithVideo(data.frame + 1);
            }
            catch (ex) {
                console.error(ex);
            }
        }
        function unimplemented() {
            throw new Error('Method unimplemented!');
        }
        const setBrightnessFilter = (on) => {
            if (local.currentLayer !== undefined) {
                local.currentLayer.node().css('filter', on ? 'url(#brightness)' : '');
            }
        };
        async function init() {
            data.maxFrame = props.imageData.length - 1;
            // Below are configuration settings we can set until we decide on good numbers to utilize.
            local = {
                playCache: 1,
                cacheSeconds: 6,
                frontBackRatio: 0.9,
                imgs: new Array(props.imageData.length),
                pendingImgs: new Set(),
                lastFrame: -1,
                width: 0,
                height: 0,
                levels: 0,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                metadata: '',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                params: '',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                currentLayer: '',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                nextLayer: false,
                nextLayerFrame: 0,
            };
            // If you uncomment below it will load the geoSpatial coordinates and a OSM layer map
            //This doesn't account for annotations being in image space vs geospatial.
            //const baseData = await props.getTiles(props.imageData[data.frame].id);
            //geoSpatial = !(!baseData.geospatial || !baseData.bounds);
            projection = geoSpatial ? 'EPSG:3857' : undefined;
            const resp = await props.getTiles(props.imageData[data.frame].id, projection);
            local.levels = resp.levels;
            local.width = resp.sizeX;
            local.height = resp.sizeY;
            local.metadata = resp;
            local.params = {
                keepLower: false,
                attribution: null,
                url: _getTileURL(props.imageData[data.frame].id, projection),
                useCredentials: true,
                maxLevel: resp.levels + 3,
                autoshareRenderer: false,
            };
            if (props.imageData.length) {
                if (geoSpatial) {
                    initializeViewer(local.metadata.sourceSizeX, local.metadata.sourceSizeY, local.metadata.tileWidth, local.metadata.tileHeight, true, geoSpatial);
                }
                else {
                    initializeViewer(local.width, local.height, local.metadata.tileWidth, local.metadata.tileHeight, true, geoSpatial);
                    // Need to set up the params using pixelCoorindateParams here instead of in useMediaViewer
                    local.params = geo.util.pixelCoordinateParams(container.value, local.width, local.height, local.metadata.tileWidth, local.metadata.tileHeight);
                    local.params.layer.useCredentials = true;
                    local.params.layer.autoshareRenderer = false;
                    local.params.layer.url = _getTileURL(props.imageData[data.frame].id);
                }
                if (geoSpatial) {
                    geoViewer.value.bounds({
                        left: local.metadata.bounds.xmin,
                        right: local.metadata.bounds.xmax,
                        top: local.metadata.bounds.ymax,
                        bottom: local.metadata.bounds.ymin,
                    }, projection);
                    geoViewer.value.createLayer('osm'); // create background layer
                    geoViewer.value.zoomRange({
                        min: geoViewer.value.origMin,
                        max: geoViewer.value.zoomRange().max + 3,
                    });
                }
                // Set to canvas mode if the tiles are larger than the largest texture
                if (local.metadata.tileWidth > 8192 || local.metadata.tileWidth > 8192) {
                    local.params.renderer = 'canvas';
                }
                // Params are differnt between geoSpatial and non
                const localParams = geoSpatial ? local.params : local.params.layer;
                local.currentLayer = geoViewer.value.createLayer('osm', localParams);
                // Set the next layer to pre load
                if (!local.nextLayer && props.imageData.length > 1) {
                    const resp2 = await props.getTiles(props.imageData[data.frame + 1].id, projection);
                    const newParams = geo.util.pixelCoordinateParams(container.value, resp2.sizeX, resp2.sizeY, resp2.tileWidth, resp2.tileHeight);
                    local.nextLayer = geoViewer.value.createLayer('osm', newParams.layer);
                    local.nextLayer._options.maxLevel = newParams.layer.maxLevel;
                    local.nextLayer._options.tileWidth = newParams.layer.tileWidth;
                    local.nextLayer._options.tileHeight = newParams.layer.tileWidth;
                    local.nextLayer._options.tilesAtZoom = newParams.layer.tilesAtZoom;
                    local.nextLayer._options.tilesMaxBounds = newParams.layer.tilesMaxBounds;
                    local.nextLayer.url(_getTileURL(props.imageData[data.frame + 1].id, projection));
                    local.nextLayer.moveDown();
                    local.nextLayerFrame = data.frame + 1;
                }
                local.currentLayer.url(_getTileURL(props.imageData[data.frame].id, projection));
                // Set quadFeature and conditionally apply brightness filter
                setBrightnessFilter(props.brightness !== undefined);
                data.ready = true;
                loadingVideo.value = false;
                loadingImage.value = false;
                seek(0);
            }
        }
        // Watch imageData for change
        watch(toRef(props, 'imageData'), () => {
            init();
        });
        // Watch brightness for change, only set filter if value
        // is switching from number -> undefined, or vice versa.
        watch(toRef(props, 'brightness'), (brightness, oldBrightness) => {
            if ((brightness === undefined) !== (oldBrightness === undefined)) {
                setBrightnessFilter(brightness !== undefined);
            }
        });
        init();
        return {
            data,
            loadingVideo,
            loadingImage,
            imageCursorRef: imageCursor,
            containerRef: container,
            cursorHandler,
            mediaController,
        };
    },
});
