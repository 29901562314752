import { defineComponent } from '@vue/composition-api';
import { useDatasetId, useTrackFilters, useTrackStyleManager, } from 'vue-media-annotator/provides';
import ConfidenceFilter from 'dive-common/components/ConfidenceFilter.vue';
import { useApi } from 'dive-common/apispec';
import { DefaultConfidence } from 'vue-media-annotator/BaseFilterControls';
export default defineComponent({
    name: 'TypeThreshold',
    components: { ConfidenceFilter },
    setup() {
        const trackFilters = useTrackFilters();
        const datasetIdRef = useDatasetId();
        const { saveMetadata } = useApi();
        function saveThreshold() {
            saveMetadata(datasetIdRef.value, {
                confidenceFilters: trackFilters.confidenceFilters.value,
            });
        }
        function resetThresholds() {
            trackFilters.setConfidenceFilters({ default: DefaultConfidence });
            saveThreshold();
        }
        return {
            checkedTypesRef: trackFilters.checkedTypes,
            confidenceFiltersRef: trackFilters.confidenceFilters,
            typeStylingRef: useTrackStyleManager().typeStyling,
            resetThresholds,
            saveThreshold,
        };
    },
});
