import { computed, defineComponent, ref, } from '@vue/composition-api';
import { getAddons, postAddons } from 'platform/web-girder/api/configuration.service';
export default defineComponent({
    name: 'AddOns',
    setup(props, { emit }) {
        const table = ref([]);
        const forceDownload = ref(false);
        const selected = ref([]);
        const headers = ref([
            { text: 'Name', value: 'Name' },
            { text: 'Description', value: 'Description' },
            { text: 'Installed', value: 'Installed' },
        ]);
        // First we get the Addons from the /dive_configuration/addons endpoint
        const getData = async () => {
            table.value = (await getAddons()).data;
        };
        const data = computed(() => table.value.map((item) => ({ Name: item[0], Description: item[2], Installed: item[3] })));
        getData();
        const downloadAddons = async () => {
            // Lets create a list of URLS to download to add
            const downloadArray = selected.value.map((item) => {
                const found = table.value.find((tabItem) => tabItem[0] === item.Name);
                if (found !== undefined) {
                    return found[1];
                }
                return null;
            });
            const list = downloadArray.filter((item) => item !== null);
            if (list.length) {
                postAddons(list, forceDownload.value);
                emit('addon-job-run');
            }
        };
        return {
            selected,
            table,
            headers,
            data,
            forceDownload,
            downloadAddons,
        };
    },
});
