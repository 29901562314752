import { defineComponent, ref } from '@vue/composition-api';
import { useHandler, useImageEnhancements } from '../provides';
export default defineComponent({
    name: 'ImageEnhancements',
    description: 'Image controls',
    setup() {
        var _a, _b;
        const { setSVGFilters } = useHandler();
        const imageEnhancements = useImageEnhancements();
        const range = ref([
            ((_a = imageEnhancements.value.blackPoint) !== null && _a !== void 0 ? _a : 0) * 255.0,
            ((_b = imageEnhancements.value.whitePoint) !== null && _b !== void 0 ? _b : 1) * 255.0,
        ]);
        const modifyValue = () => {
            setSVGFilters({ blackPoint: range.value[0] / 255.0, whitePoint: range.value[1] / 255.0 });
        };
        return {
            modifyValue,
            range,
        };
    },
});
