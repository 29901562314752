var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-subheader',{staticClass:"flex-grow-1 trackHeader px-2"},[_c('v-container',{staticClass:"py-2"},[_c('v-row',{attrs:{"align":"center"}},[_vm._v(" Tracks ("+_vm._s(_vm.filteredTracks.length)+") "),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-bottom":28},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.data.settingsActive ? 'accent' : 'default'}},[_vm._v(" mdi-cog ")])],1)]}}]),model:{value:(_vm.data.settingsActive),callback:function ($$v) {_vm.$set(_vm.data, "settingsActive", $$v)},expression:"data.settingsActive"}},[(_vm.data.settingsActive)?_vm._t("settings"):_vm._e()],2),_c('v-tooltip',{attrs:{"open-delay":"100","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disabled":_vm.filteredTracks.length === 0 || _vm.readOnlyMode,"icon":"","small":""},on:{"click":function($event){return _vm.multiDelete()}}},on),[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v("Delete visible items")])]),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disabled":_vm.readOnlyMode,"outlined":"","x-small":"","color":_vm.newTrackColor},on:{"click":function($event){return _vm.trackAdd()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.newTrackMode)+" ")],1)]}}])},[_c('span',[_vm._v("Default Type: "+_vm._s(_vm.newTrackType))])])],1)],1)],1),_c('datalist',{attrs:{"id":"allTypesOptions"}},_vm._l((_vm.allTypes),function(type){return _c('option',{key:type,domProps:{"value":type}},[_vm._v(" "+_vm._s(type)+" ")])}),0),_c('v-virtual-scroll',{directives:[{name:"mousetrap",rawName:"v-mousetrap",value:(_vm.mouseTrap),expression:"mouseTrap"}],ref:"virtualList",staticClass:"tracks",attrs:{"items":_vm.virtualListItems,"item-height":_vm.data.itemHeight,"height":_vm.virtualHeight,"bench":"1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('track-item',_vm._b({attrs:{"lock-types":_vm.lockTypes},on:{"seek":function($event){return _vm.$emit('track-seek', $event)}}},'track-item',_vm.getItemProps(item),false))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }