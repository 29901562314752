import { ref } from '@vue/composition-api';
import Vue from 'vue';
import { withinBounds } from 'vue-media-annotator/utils';
export const HeadTailLineKey = 'HeadTails';
export const HeadPointKey = 'head';
export const TailPointKey = 'tail';
const EmptyResponse = { data: {}, union: [], unionWithoutBounds: [] };
/* Standard 10% padding */
const PaddingVector = [
    [-0.10, -0.10],
    [-0.10, 0.10],
    [1.10, -0.10],
    [1.10, 0.10],
    [-0.10, -0.10],
];
/* No padding */
const PaddingVectorZero = [
    [0, 0],
    [0, 0],
    [1, 0],
    [1, 0],
    [0, 0],
];
export default class HeadTail {
    constructor() {
        this.bus = new Vue();
        this.startWithHead = true;
        this.active = ref(false);
        this.name = 'HeadTail';
        this.toggleable = ref(true);
        this.icon = ref('mdi-vector-line');
    }
    /**
     * findBounds computes a padding polygon around the linestring given paddingVector
     * @param ls Linestring
     * @param paddingVector polypoints in terms of C and CPerp
     */
    static findBounds(ls, paddingVector) {
        // Coords = [ Vec A, Vec B ]
        const coords = ls.coordinates;
        if (coords.length === 2) {
            // vec = B - A
            const vec = [
                coords[1][0] - coords[0][0],
                coords[1][1] - coords[0][1],
            ];
            // perpendicular vector
            const vecPerp = [
                -1 * vec[1],
                vec[0],
            ];
            if (paddingVector.length !== 5) {
                throw new Error('Padding vector must have length 5');
            }
            return [{
                    type: 'Polygon',
                    coordinates: [
                        paddingVector.map((p) => ([
                            coords[0][0] + (p[0] * vec[0]) + (p[1] * vecPerp[0]),
                            coords[0][1] + (p[0] * vec[1]) + (p[1] * vecPerp[1]),
                        ])),
                    ],
                }];
        }
        // If only 1 point is available so far
        return [{
                type: 'Polygon',
                coordinates: coords.map((p) => ([
                    p.map((c) => c + 5),
                    p.map((c) => c - 5),
                ])),
            }];
    }
    static coordsInBounds(bounds, coords) {
        const results = [];
        for (let i = 0; i < coords.length; i += 1) {
            const x = coords[i][0];
            const y = coords[i][1];
            results.push(withinBounds([x, y], bounds));
        }
        return (results.filter((item) => item).length === coords.length);
    }
    static makeGeom(ls, startWithHead) {
        const firstFeature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    ls.coordinates[0][0],
                    ls.coordinates[0][1],
                ],
            },
            properties: {},
        };
        const ret = {
            [startWithHead ? HeadPointKey : TailPointKey]: [firstFeature],
        };
        if (ls.coordinates.length === 2) {
            const secondFeature = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [
                        ls.coordinates[1][0],
                        ls.coordinates[1][1],
                    ],
                },
                properties: {},
            };
            if (!startWithHead) {
                ls.coordinates.reverse();
            }
            const headTailLine = {
                type: 'Feature',
                geometry: ls,
                properties: {},
            };
            ret[startWithHead ? TailPointKey : HeadPointKey] = [secondFeature];
            ret[HeadTailLineKey] = [headTailLine];
        }
        return ret;
    }
    update(mode, frameNum, track, data, key) {
        const linestrings = data.filter((d) => d.geometry.type === 'LineString');
        if (linestrings.length) {
            const linestring = linestrings[0];
            if (this.active.value && mode === 'in-progress') {
                /**
                 * IF the recipe is active, we are creating a new headtail
                 */
                let geom = linestring.geometry;
                const head = track.getFeatureGeometry(frameNum, { type: 'Point', key: HeadPointKey });
                const tail = track.getFeatureGeometry(frameNum, { type: 'Point', key: TailPointKey });
                const currentFeature = track.features.find((item) => item.frame === frameNum);
                let bounds = null;
                if (currentFeature && currentFeature.bounds) {
                    bounds = currentFeature.bounds;
                }
                if (head.length !== tail.length) {
                    // If one point exists but not the other
                    if (head.length > 0) {
                        this.startWithHead = true;
                        this.icon.value = 'mdi-vector-line';
                    }
                    else {
                        this.startWithHead = false;
                        this.icon.value = 'mdi-alpha-t-box-outline';
                    }
                    geom = {
                        type: 'LineString',
                        coordinates: [
                            this.startWithHead
                                ? head[0].geometry.coordinates
                                : tail[0].geometry.coordinates,
                            geom.coordinates[geom.coordinates.length - 1],
                        ],
                        properties: {},
                    };
                }
                if (geom.coordinates.length === 2) {
                    let union = HeadTail.findBounds(geom, PaddingVector);
                    if (bounds !== null) {
                        // If both are inside of the bbox don't adjust the union
                        if (HeadTail.coordsInBounds(bounds, geom.coordinates)) {
                            union = [];
                        }
                        else if (tail.length > 0) { // If creating new box add padding
                            union = HeadTail.findBounds(geom, PaddingVectorZero);
                        }
                    }
                    // Both head and tail placed, replace them.
                    return {
                        ...EmptyResponse,
                        data: HeadTail.makeGeom(geom, this.startWithHead),
                        newSelectedKey: HeadTailLineKey,
                        done: true,
                        union,
                    };
                }
                if (geom.coordinates.length === 1) {
                    // Only the head placed so far
                    let union = HeadTail.findBounds(geom, PaddingVector);
                    if (bounds !== null) {
                        if (HeadTail.coordsInBounds(bounds, geom.coordinates)) {
                            union = [];
                        }
                    }
                    return {
                        ...EmptyResponse,
                        data: HeadTail.makeGeom(geom, this.startWithHead),
                        union,
                        done: false,
                    };
                }
            }
            if (key === HeadTailLineKey && mode === 'editing') {
                /**
                 * IF recipe isn't active, but the key matches, we are editing
                 */
                return {
                    ...EmptyResponse,
                    data: HeadTail.makeGeom(linestring.geometry, true),
                    union: HeadTail.findBounds(linestring.geometry, PaddingVectorZero),
                    done: true,
                };
            }
        }
        return EmptyResponse;
    }
    // eslint-disable-next-line class-methods-use-this
    delete(frame, track, key, type) {
        if (key === HeadTailLineKey && type === 'LineString') {
            track.removeFeatureGeometry(frame, { type: 'Point', key: HeadPointKey });
            track.removeFeatureGeometry(frame, { type: 'Point', key: TailPointKey });
            track.removeFeatureGeometry(frame, { type: 'LineString', key: HeadTailLineKey });
        }
    }
    // eslint-disable-next-line class-methods-use-this
    deletePoint(frame, track, idx, key, type) {
        if (key === HeadTailLineKey && type === 'LineString') {
            track.removeFeatureGeometry(frame, { type: 'LineString', key: HeadTailLineKey });
            if (idx === 0) {
                track.removeFeatureGeometry(frame, { type: 'Point', key: HeadPointKey });
            }
            else {
                track.removeFeatureGeometry(frame, { type: 'Point', key: TailPointKey });
            }
        }
    }
    activate() {
        this.active.value = true;
        this.icon.value = 'mdi-vector-line';
        this.startWithHead = true;
        this.bus.$emit('activate', {
            editing: 'LineString',
            key: HeadTailLineKey,
            recipeName: this.name,
        });
    }
    deactivate() {
        this.active.value = false;
    }
    headfirst() {
        this.activate();
        this.startWithHead = true;
        this.icon.value = 'mdi-vector-line';
    }
    tailfirst() {
        this.activate();
        this.startWithHead = false;
        this.icon.value = 'mdi-alpha-t-box-outline';
    }
    mousetrap() {
        return [
            { bind: 'h', handler: () => this.headfirst() },
            { bind: 't', handler: () => this.tailfirst() },
        ];
    }
}
