import { defineComponent, } from '@vue/composition-api';
import { useTrackStyleManager, } from 'vue-media-annotator/provides';
import PanelSubsection from 'dive-common/components/PanelSubsection.vue';
export default defineComponent({
    name: 'ConfidenceSubsection',
    components: {
        PanelSubsection,
    },
    props: {
        confidencePairs: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const typeStylingRef = useTrackStyleManager().typeStyling;
        // TODO:  Adding, Deleting, Editing Confidence Levels in this interface
        return {
            typeStylingRef,
        };
    },
});
