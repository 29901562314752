import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'AutosavePrompt',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
});
