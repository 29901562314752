import { computed } from '@vue/composition-api';
export default function useEventChart({ enabledTracks, selectedTrackIds, typeStyling, getTracksMerged, }) {
    const eventChartData = computed(() => {
        const values = [];
        const mapfunc = typeStyling.value.color;
        const selectedTrackIdsValue = selectedTrackIds.value;
        /* use forEach rather than filter().map() to save an interation */
        enabledTracks.value.forEach((filtered) => {
            const { annotation: track } = filtered;
            const { confidencePairs } = track;
            let markers = [];
            if (selectedTrackIds.value.includes(filtered.annotation.id)) {
                const mergedTrack = getTracksMerged(filtered.annotation.id);
                if ('featureIndex' in mergedTrack) {
                    markers = mergedTrack.featureIndex.map((i) => ([i, mergedTrack.features[i].interpolate || false]));
                }
            }
            if (confidencePairs.length) {
                const trackType = track.getType(filtered.context.confidencePairIndex);
                values.push({
                    id: track.id,
                    name: `Track ${track.id}`,
                    type: trackType,
                    color: mapfunc(trackType),
                    selected: selectedTrackIdsValue.includes(track.id),
                    range: [track.begin, track.end],
                    markers,
                });
            }
        });
        return {
            muted: selectedTrackIdsValue.length > 0,
            values,
        };
    });
    return { eventChartData };
}
