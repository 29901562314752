import { defineComponent } from '@vue/composition-api';
import { throttle, debounce } from 'lodash';
export default defineComponent({
    name: 'ConfidenceFilter',
    props: {
        confidence: {
            type: Number,
            default: 0,
        },
        text: {
            type: String,
            default: 'Confidence Threshold',
        },
        min: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        function _updateConfidence(event) {
            if (event.target) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                emit('update:confidence', Number.parseFloat(event.target.value));
            }
        }
        function _emitEnd() {
            emit('end');
        }
        const updateConfidence = throttle(_updateConfidence, 100);
        const emitEnd = debounce(_emitEnd, 200);
        return { updateConfidence, emitEnd };
    },
});
