import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'GroupEditor',
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return {};
    },
});
