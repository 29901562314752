import { defineComponent, reactive, ref, toRef, watch, } from '@vue/composition-api';
export default defineComponent({
    name: 'TypePicker',
    props: {
        value: {
            type: String,
            required: true,
        },
        allTypes: {
            type: Array,
            default: () => [],
        },
        readOnlyMode: {
            type: Boolean,
            default: false,
        },
        lockTypes: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        dataListSource: {
            type: String,
            default: 'allTypesOptions',
        },
    },
    setup(props, { emit }) {
        const data = reactive({
            trackTypeValue: props.value,
            skipOnFocus: false,
        });
        const typeInputBoxRef = ref(undefined);
        function input(event) {
            const element = event.currentTarget;
            emit('input', element.value);
        }
        /* Update internal model if external prop changes */
        watch(toRef(props, 'value'), (val) => { data.trackTypeValue = val; });
        function focusType() {
            if (props.selected && typeInputBoxRef.value !== undefined) {
                data.skipOnFocus = true;
                typeInputBoxRef.value.focus();
                if (!props.lockTypes) {
                    typeInputBoxRef.value.select();
                }
            }
        }
        function blurType(e) {
            e.target.blur();
        }
        function onBlur(e) {
            if (data.trackTypeValue.trim() === '') {
                data.trackTypeValue = props.value;
            }
            else if (data.trackTypeValue !== props.value) {
                /* horrendous hack to prevent race. https://github.com/Kitware/dive/issues/475 */
                window.setTimeout(() => {
                    emit('input', data.trackTypeValue);
                }, 100);
            }
            if (props.lockTypes) {
                blurType(e);
            }
        }
        function onFocus() {
            if (!data.skipOnFocus) {
                data.trackTypeValue = '';
            }
            data.skipOnFocus = false;
        }
        function onInputKeyEvent(e) {
            switch (e.code) {
                case 'Escape':
                case 'Enter':
                    blurType(e);
                    break;
                case 'ArrowDown':
                    data.trackTypeValue = '';
                    break;
                default:
                    break;
            }
        }
        return {
            data,
            typeInputBoxRef,
            input,
            focusType,
            onFocus,
            onBlur,
            onInputKeyEvent,
        };
    },
});
