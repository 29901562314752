import { defineComponent, toRef, watch } from '@vue/composition-api';
import { useAnnotationSet, useDatasetId, useHandler, usePendingSaveCount, useProgress, useRevisionId, } from 'vue-media-annotator/provides';
import { loadRevisions } from 'platform/web-girder/api';
import { usePaginatedRequest } from 'dive-common/use/useRequest';
export default defineComponent({
    name: 'RevisionHistory',
    description: 'Revision History',
    setup(_, ctx) {
        const saveCount = usePendingSaveCount();
        const datasetId = useDatasetId();
        const currentSet = useAnnotationSet();
        const revisionId = useRevisionId();
        const progress = useProgress();
        const { reloadAnnotations } = useHandler();
        const { loading, count, allPages: revisions, totalCount, loadNextPage, reset, } = usePaginatedRequest();
        async function loadNext() {
            await loadNextPage((l, o) => loadRevisions(datasetId.value, l, o, undefined, currentSet.value));
        }
        function checkout(id) {
            ctx.emit('update:revision', id, currentSet.value);
            reloadAnnotations();
        }
        watch(saveCount, (newval) => {
            // Reload revision list when save happens
            if (newval === 0) {
                reset();
                loadNext();
            }
        });
        watch(toRef(progress, 'loaded'), (newval) => {
            // Reload revision list when refresh happens.
            if (!newval) {
                reset();
                loadNext();
            }
        });
        loadNext();
        return {
            loading,
            progress,
            count,
            revisions,
            revisionId,
            datasetId,
            saveCount,
            totalCount,
            checkout,
            loadNext,
        };
    },
});
