import { defineComponent, watch, ref, computed, toRef, } from '@vue/composition-api';
import { injectAggregateController } from './annotators/useMediaController';
import RectangleLayer from '../layers/AnnotationLayers/RectangleLayer';
import PolygonLayer from '../layers/AnnotationLayers/PolygonLayer';
import PointLayer from '../layers/AnnotationLayers/PointLayer';
import LineLayer from '../layers/AnnotationLayers/LineLayer';
import TailLayer from '../layers/AnnotationLayers/TailLayer';
import OverlapLayer from '../layers/AnnotationLayers/OverlapLayer';
import EditAnnotationLayer from '../layers/EditAnnotationLayer';
import TextLayer from '../layers/AnnotationLayers/TextLayer';
import AttributeLayer from '../layers/AnnotationLayers/AttributeLayer';
import AttributeBoxLayer from '../layers/AnnotationLayers/AttributeBoxLayer';
import { geojsonToBound } from '../utils';
import UILayer from '../layers/UILayers/UILayer';
import ToolTipWidget from '../layers/UILayers/ToolTipWidget.vue';
import { useHandler, useSelectedTrackId, useTrackFilters, useTrackStyleManager, useEditingMode, useVisibleModes, useSelectedKey, useMultiSelectList, useAnnotatorPreferences, useGroupStyleManager, useCameraStore, useSelectedCamera, useAttributes, useComparisonSets, } from '../provides';
/** LayerManager is a component intended to be used as a child of an Annotator.
 *  It provides logic for switching which layers are visible, but more importantly
 *  it maps Track objects into their respective layer representations.
 *  LayerManager emits high-level events when track features get selected or updated.
 */
export default defineComponent({
    props: {
        formatTextRow: {
            type: Function,
            default: undefined,
        },
        colorBy: {
            type: String,
            default: 'track',
        },
        camera: {
            type: String,
            default: 'singleCam',
        },
    },
    setup(props) {
        var _a, _b;
        const handler = useHandler();
        const cameraStore = useCameraStore();
        const selectedCamera = useSelectedCamera();
        const comparison = useComparisonSets();
        const trackStore = (_a = cameraStore.camMap.value.get(props.camera)) === null || _a === void 0 ? void 0 : _a.trackStore;
        const groupStore = (_b = cameraStore.camMap.value.get(props.camera)) === null || _b === void 0 ? void 0 : _b.groupStore;
        const attributes = useAttributes();
        if (!trackStore || !groupStore) {
            throw Error(`TrackStore: ${trackStore} or GroupStore: ${groupStore} are undefined for camera ${props.camera}`);
        }
        const enabledTracksRef = useTrackFilters().enabledAnnotations;
        const selectedTrackIdRef = useSelectedTrackId();
        const multiSeletListRef = useMultiSelectList();
        const editingModeRef = useEditingMode();
        const visibleModesRef = useVisibleModes();
        const selectedKeyRef = useSelectedKey();
        const trackStyleManager = useTrackStyleManager();
        const groupStyleManager = useGroupStyleManager();
        const annotatorPrefs = useAnnotatorPreferences();
        const typeStylingRef = computed(() => {
            if (props.colorBy === 'group') {
                return groupStyleManager.typeStyling.value;
            }
            return trackStyleManager.typeStyling.value;
        });
        const annotator = injectAggregateController().value.getController(props.camera);
        const frameNumberRef = annotator.frame;
        const flickNumberRef = annotator.flick;
        const rectAnnotationLayer = new RectangleLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const overlapLayer = new OverlapLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const polyAnnotationLayer = new PolygonLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const lineLayer = new LineLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const pointLayer = new PointLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const tailLayer = new TailLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        }, trackStore);
        const textLayer = new TextLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
            formatter: props.formatTextRow,
        });
        const attributeBoxLayer = new AttributeBoxLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const attributeLayer = new AttributeLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
        });
        const editAnnotationLayer = new EditAnnotationLayer({
            annotator,
            stateStyling: trackStyleManager.stateStyles,
            typeStyling: typeStylingRef,
            type: 'rectangle',
        });
        const updateAttributes = () => {
            const newList = attributes.value.filter((item) => item.render).sort((a, b) => {
                if (a.render && b.render) {
                    return (a.render.order - b.render.order);
                }
                return 0;
            });
            const user = ''; // TODO: Make a global setting for Web/Desktop
            attributeLayer.updateRenderAttributes(newList, user);
            attributeBoxLayer.updateRenderAttributes(newList);
        };
        updateAttributes();
        const uiLayer = new UILayer(annotator);
        const hoverOvered = ref([]);
        const toolTipWidgetProps = {
            color: typeStylingRef.value.color,
            dataList: hoverOvered,
            selected: selectedTrackIdRef,
            stateStyling: trackStyleManager.stateStyles,
        };
        uiLayer.addDOMWidget('customToolTip', ToolTipWidget, toolTipWidgetProps, { x: 10, y: 10 });
        function updateLayers(frame, editingTrack, selectedTrackId, multiSelectList, enabledTracks, visibleModes, selectedKey, colorBy) {
            const currentFrameIds = trackStore === null || trackStore === void 0 ? void 0 : trackStore.intervalTree.search([frame, frame]).map((str) => parseInt(str, 10));
            const inlcudesTooltip = visibleModes.includes('tooltip');
            rectAnnotationLayer.setHoverAnnotations(inlcudesTooltip);
            polyAnnotationLayer.setHoverAnnotations(inlcudesTooltip);
            if (!inlcudesTooltip) {
                hoverOvered.value = [];
            }
            const frameData = [];
            const editingTracks = [];
            if (currentFrameIds === undefined) {
                return;
            }
            currentFrameIds.forEach((trackId) => {
                var _a, _b, _c;
                const track = trackStore === null || trackStore === void 0 ? void 0 : trackStore.get(trackId);
                if (track === undefined) {
                    // Track may be located in another Camera
                    // TODO: Find a better way to represent tracks outside of cameras
                    return;
                }
                const enabledIndex = enabledTracks.findIndex((trackWithContext) => trackWithContext.annotation.id === trackId);
                if (enabledIndex !== -1) {
                    const [features] = track.getFeature(frame);
                    const groups = cameraStore.lookupGroups(track.id);
                    const trackStyleType = track.getType(enabledTracks[enabledIndex].context.confidencePairIndex);
                    const groupStyleType = (_b = (_a = groups === null || groups === void 0 ? void 0 : groups[0]) === null || _a === void 0 ? void 0 : _a.getType()) !== null && _b !== void 0 ? _b : cameraStore.defaultGroup;
                    const trackFrame = {
                        selected: ((selectedTrackId === track.trackId)
                            || (multiSelectList.includes(track.trackId))),
                        editing: editingTrack,
                        track,
                        groups,
                        features,
                        styleType: colorBy === 'group' ? groupStyleType : trackStyleType,
                        set: track.set,
                    };
                    frameData.push(trackFrame);
                    if (trackFrame.selected) {
                        if (editingTrack && props.camera === selectedCamera.value) {
                            editingTracks.push(trackFrame);
                        }
                        if (annotator.lockedCamera.value) {
                            if ((_c = trackFrame.features) === null || _c === void 0 ? void 0 : _c.bounds) {
                                const coords = {
                                    x: (trackFrame.features.bounds[0] + trackFrame.features.bounds[2]) / 2.0,
                                    y: (trackFrame.features.bounds[1] + trackFrame.features.bounds[3]) / 2.0,
                                    z: 0,
                                };
                                annotator.centerOn(coords);
                            }
                        }
                    }
                }
            });
            if (visibleModes.includes('rectangle')) {
                //We modify rects opacity/thickness if polygons are visible or not
                rectAnnotationLayer.setDrawingOther(visibleModes.includes('Polygon'));
                rectAnnotationLayer.changeData(frameData, comparison.value);
                if (comparison.value.length) {
                    overlapLayer.changeData(frameData);
                }
            }
            else {
                rectAnnotationLayer.disable();
            }
            if (visibleModes.includes('Polygon')) {
                polyAnnotationLayer.setDrawingOther(visibleModes.includes('rectangle'));
                polyAnnotationLayer.changeData(frameData);
            }
            else {
                polyAnnotationLayer.disable();
            }
            if (visibleModes.includes('LineString')) {
                lineLayer.changeData(frameData);
            }
            else {
                lineLayer.disable();
            }
            if (visibleModes.includes('TrackTail')) {
                tailLayer.updateSettings(frame, annotatorPrefs.value.trackTails.before, annotatorPrefs.value.trackTails.after);
                tailLayer.changeData(frameData);
            }
            else {
                tailLayer.disable();
            }
            pointLayer.changeData(frameData);
            if (visibleModes.includes('text')) {
                textLayer.changeData(frameData);
                attributeBoxLayer.changeData(frameData);
                attributeLayer.changeData(frameData);
            }
            else {
                textLayer.disable();
                attributeLayer.disable();
                attributeBoxLayer.disable();
            }
            if (selectedTrackId !== null) {
                if ((editingTrack) && !currentFrameIds.includes(selectedTrackId)
                    && props.camera === selectedCamera.value) {
                    const editTrack = trackStore === null || trackStore === void 0 ? void 0 : trackStore.getPossible(selectedTrackId);
                    if (editTrack === undefined) {
                        throw new Error(`trackMap missing trackid ${selectedTrackId}`);
                    }
                    const [real, lower, upper] = editTrack.getFeature(frame);
                    const features = real || lower || upper;
                    const trackFrame = {
                        selected: true,
                        editing: true,
                        track: editTrack,
                        groups: cameraStore.lookupGroups(editTrack.id),
                        features: (features && features.interpolate) ? features : null,
                        styleType: cameraStore.defaultGroup,
                    };
                    editingTracks.push(trackFrame);
                }
                if (editingTracks.length) {
                    if (editingTrack) {
                        editAnnotationLayer.setType(editingTrack);
                        editAnnotationLayer.setKey(selectedKey);
                        editAnnotationLayer.changeData(editingTracks);
                    }
                }
                else {
                    editAnnotationLayer.disable();
                }
            }
            else {
                editAnnotationLayer.disable();
            }
        }
        /**
         * TODO: for some reason, GeoJS requires us to initialize
         * by calling the render function twice.  This is a bug.
         * https://github.com/Kitware/dive/issues/365
         */
        [1, 2].forEach(() => {
            updateLayers(frameNumberRef.value, editingModeRef.value, selectedTrackIdRef.value, multiSeletListRef.value, enabledTracksRef.value, visibleModesRef.value, selectedKeyRef.value, props.colorBy);
        });
        /** Shallow watch */
        watch([
            frameNumberRef,
            editingModeRef,
            enabledTracksRef,
            selectedTrackIdRef,
            multiSeletListRef,
            visibleModesRef,
            typeStylingRef,
            toRef(props, 'colorBy'),
            selectedCamera,
        ], () => {
            updateLayers(frameNumberRef.value, editingModeRef.value, selectedTrackIdRef.value, multiSeletListRef.value, enabledTracksRef.value, visibleModesRef.value, selectedKeyRef.value, props.colorBy);
        });
        /** Deep watch */
        watch(annotatorPrefs, () => {
            updateLayers(frameNumberRef.value, editingModeRef.value, selectedTrackIdRef.value, multiSeletListRef.value, enabledTracksRef.value, visibleModesRef.value, selectedKeyRef.value, props.colorBy);
        }, { deep: true });
        watch(attributes, () => {
            updateAttributes();
            updateLayers(frameNumberRef.value, editingModeRef.value, selectedTrackIdRef.value, multiSeletListRef.value, enabledTracksRef.value, visibleModesRef.value, selectedKeyRef.value, props.colorBy);
        });
        const Clicked = (trackId, editing) => {
            // If the camera isn't selected yet we ignore the click
            if (selectedCamera.value !== props.camera) {
                return;
            }
            //So we only want to pass the click whjen not in creation mode or editing mode for features
            if (editAnnotationLayer.getMode() !== 'creation') {
                editAnnotationLayer.disable();
                handler.trackSelect(trackId, editing);
            }
        };
        //Sync of internal geoJS state with the application
        editAnnotationLayer.bus.$on('editing-annotation-sync', (editing) => {
            handler.trackSelect(selectedTrackIdRef.value, editing);
        });
        rectAnnotationLayer.bus.$on('annotation-clicked', Clicked);
        rectAnnotationLayer.bus.$on('annotation-right-clicked', Clicked);
        polyAnnotationLayer.bus.$on('annotation-clicked', Clicked);
        polyAnnotationLayer.bus.$on('annotation-right-clicked', Clicked);
        editAnnotationLayer.bus.$on('update:geojson', (mode, geometryCompleteEvent, data, type, key = '', cb) => {
            if (type === 'rectangle') {
                const bounds = geojsonToBound(data);
                cb();
                handler.updateRectBounds(frameNumberRef.value, flickNumberRef.value, bounds);
            }
            else {
                handler.updateGeoJSON(mode, frameNumberRef.value, flickNumberRef.value, data, key, cb);
            }
            // Jump into edit mode if we completed a new shape
            if (geometryCompleteEvent) {
                updateLayers(frameNumberRef.value, editingModeRef.value, selectedTrackIdRef.value, multiSeletListRef.value, enabledTracksRef.value, visibleModesRef.value, selectedKeyRef.value, props.colorBy);
            }
        });
        editAnnotationLayer.bus.$on('update:selectedIndex', (index, _type, key = '') => handler.selectFeatureHandle(index, key));
        const annotationHoverTooltip = (found) => {
            const hoveredVals = [];
            found.forEach((item) => {
                // get Max of X and Min of y for ordering
                if (item.polygon.coordinates.length) {
                    let maxX = -Infinity;
                    let minY = Infinity;
                    item.polygon.coordinates[0].forEach((coord) => {
                        if (coord.length === 2) {
                            maxX = Math.max(coord[0], maxX);
                            minY = Math.min(coord[1], minY);
                        }
                    });
                    hoveredVals.push({
                        type: item.styleType[0],
                        confidence: item.styleType[1],
                        trackId: item.trackId,
                        maxX,
                    });
                }
            });
            hoverOvered.value = hoveredVals.sort((a, b) => a.maxX - b.maxX);
            uiLayer.setToolTipWidget('customToolTip', (hoverOvered.value.length > 0));
        };
        rectAnnotationLayer.bus.$on('annotation-hover', annotationHoverTooltip);
        polyAnnotationLayer.bus.$on('annotation-hover', annotationHoverTooltip);
    },
});
