import { defineComponent, ref, } from '@vue/composition-api';
import { cloneDeep } from 'lodash';
import { useAttributes } from 'vue-media-annotator/provides';
import TooltipBtn from '../TooltipButton.vue';
import AttributeKeyFilterSettings from './AttributeKeyFilterSettings.vue';
export default defineComponent({
    name: 'AttributeKeyFilter',
    props: {
        attributeFilter: {
            type: Object,
            required: true,
        },
        filterNames: {
            type: Array,
            required: true,
        },
        timeline: {
            type: Boolean,
            default: false,
        },
    },
    components: { TooltipBtn, AttributeKeyFilterSettings },
    setup(props, { emit }) {
        const settingsDialog = ref(false);
        const attributesList = useAttributes();
        const copiedFilter = ref(null);
        // Ordering of these lists should match
        const setValue = (val) => {
            //update the filter value
            emit('update-value', val);
        };
        const setActive = (val) => {
            //update the filter value
            emit('update-active', val);
        };
        const showSettings = () => {
            copiedFilter.value = cloneDeep(props.attributeFilter);
            settingsDialog.value = true;
        };
        const saveChanges = () => {
            emit('save-changes', copiedFilter.value);
            settingsDialog.value = false;
        };
        const removeChip = (item) => {
            if (copiedFilter.value) {
                copiedFilter.value.appliedTo.splice(copiedFilter.value.appliedTo.indexOf(item), 1);
            }
        };
        const getColor = (item) => {
            const found = attributesList.value.find((atr) => atr.key === item || atr.key === `detection_${item}`);
            return (found === null || found === void 0 ? void 0 : found.color) || 'white';
        };
        return {
            settingsDialog,
            copiedFilter,
            /* methods */
            showSettings,
            saveChanges,
            setValue,
            setActive,
            removeChip,
            getColor,
        };
    },
});
