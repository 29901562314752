import { computed, defineComponent, reactive, toRef, } from '@vue/composition-api';
import { FilterList, TrackList } from 'vue-media-annotator/components';
import { useCameraStore, useHandler, useReadOnlyMode, useTrackFilters, useTrackStyleManager, } from 'vue-media-annotator/provides';
import { clientSettings } from 'dive-common/store/settings';
import TrackDetailsPanel from 'dive-common/components/TrackDetailsPanel.vue';
import TrackSettingsPanel from 'dive-common/components/TrackSettingsPanel.vue';
import TypeSettingsPanel from 'dive-common/components/TypeSettingsPanel.vue';
import StackedVirtualSidebarContainer from 'dive-common/components/StackedVirtualSidebarContainer.vue';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
export default defineComponent({
    props: {
        width: {
            type: Number,
            default: 300,
        },
        enableSlot: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        StackedVirtualSidebarContainer,
        TrackDetailsPanel,
        TrackSettingsPanel,
        FilterList,
        TrackList,
        TypeSettingsPanel,
    },
    setup() {
        const allTypesRef = useTrackFilters().allTypes;
        const readOnlyMode = useReadOnlyMode();
        const cameraStore = useCameraStore();
        const multiCam = cameraStore.camMap.value.size > 1;
        const { toggleMerge, commitMerge, groupAdd } = useHandler();
        const { visible } = usePrompt();
        const trackSettings = toRef(clientSettings, 'trackSettings');
        const typeSettings = toRef(clientSettings, 'typeSettings');
        const trackFilterControls = useTrackFilters();
        const styleManager = useTrackStyleManager();
        const data = reactive({
            currentTab: 'tracks',
        });
        function swapTabs() {
            if (data.currentTab === 'tracks') {
                data.currentTab = 'attributes';
            }
            else {
                data.currentTab = 'tracks';
            }
        }
        function doToggleMerge() {
            if (toggleMerge().length) {
                data.currentTab = 'attributes';
            }
        }
        const mouseTrap = computed(() => {
            const trap = [
                { bind: 'a', handler: swapTabs },
            ];
            if (!readOnlyMode.value && !multiCam) {
                trap.push({ bind: 'm', handler: doToggleMerge }, { bind: 'g', handler: () => { groupAdd(); data.currentTab = 'attributes'; } }, { bind: 'shift+m', handler: commitMerge });
            }
            return trap;
        });
        return {
            /* data */
            data,
            allTypesRef,
            commitMerge,
            groupAdd,
            mouseTrap,
            trackFilterControls,
            trackSettings,
            typeSettings,
            readOnlyMode,
            styleManager,
            visible,
            /* methods */
            doToggleMerge,
            swapTabs,
        };
    },
});
