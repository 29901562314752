import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
    },
});
