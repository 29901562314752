import { computed, defineComponent, ref, } from '@vue/composition-api';
import { getRecentUsers } from 'platform/web-girder/api/admin.service';
import moment from 'moment';
export default defineComponent({
    name: 'UserRecents',
    setup() {
        const limit = ref(50);
        const offset = ref(0);
        const table = ref([]);
        const selected = ref([]);
        const headers = ref([
            { text: 'Login', value: 'login' },
            { text: 'First', value: 'first' },
            { text: 'Last', value: 'last' },
            { text: 'Email', value: 'email' },
            { text: 'Created', value: 'created' },
            { text: 'Dir', value: 'dir' },
        ]);
        const getData = async () => {
            table.value = (await getRecentUsers(limit.value, offset.value)).data;
        };
        const data = computed(() => table.value.map((item) => ({
            login: item.login,
            first: item.firstName,
            last: item.lastName,
            email: item.email,
            created: moment(item.created).format('dddd, MMMM D, YYYY @ h:mm a'),
            dir: item._id,
        })));
        getData();
        return {
            selected,
            table,
            headers,
            data,
        };
    },
});
