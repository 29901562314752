import { defineComponent, ref, toRef, } from '@vue/composition-api';
import { putBrandData, getBrandData } from 'platform/web-girder/api/configuration.service';
export default defineComponent({
    name: 'AdminBranding',
    setup(props, { root }) {
        const brandData = ref({});
        const rootBrandData = toRef(root.$store.state.Brand, 'brandData');
        const getData = async () => {
            const resp = await getBrandData();
            brandData.value = { ...rootBrandData.value, ...resp.data };
        };
        getData();
        const uploadBranding = async () => {
            await putBrandData(brandData.value);
            await getData();
        };
        return {
            brandData,
            uploadBranding,
        };
    },
});
