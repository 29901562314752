import { ref, computed, set as VueSet, } from '@vue/composition-api';
import * as d3 from 'd3';
import { noop, merge } from 'lodash';
/**
   * Generates a color pallette as a list of hex colors.
   * It generates colors from a rainbow spectrum and then takes a dark and lighter version
   * of each color.
   * @param {int} numColors - number of colors to attempt to generate the higher
   * the number the more similar the colors will be.  Cyan like colors will be filtered out,
   * so numColors isn't a guarantee of x*3 (normal, dark, light) colors.
   */
export function generateColors(numColors) {
    var _a;
    const colorList = [];
    for (let i = 0; i < numColors; i += 1) {
        //We are using a rainbow but we want to skip the cyan area so number will be reduced
        const pos = (i * (1 / numColors));
        if (pos > 0.58 && pos < 0.63) {
            break;
        }
        const baseColor = (_a = d3.color(d3.interpolateRainbow(pos))) === null || _a === void 0 ? void 0 : _a.hex();
        if (baseColor) {
            const hueColor = d3.hsl(baseColor);
            hueColor.s = 1.0;
            hueColor.l = 0.5;
            colorList.push(hueColor.hex());
            hueColor.s = 0.5;
            hueColor.l = 0.35;
            colorList.push(hueColor.hex());
            hueColor.s = 1.0;
            hueColor.l = 0.75;
            colorList.push(hueColor.hex());
        }
    }
    //Mix up colors in a uniform way so reloads have the same types associated with the same colors
    let seed = 0.28;
    colorList.sort(() => {
        seed += seed;
        return Math.cos(seed);
    });
    return colorList;
}
const defaultStaticStyles = {
    'no-group': {
        color: '#ffffff',
    },
};
const defaultSetStaticStyles = {};
export default class StyleManager {
    constructor({ markChangesPending, vuetify }) {
        this.revisionCounter = ref(1);
        this.customStyles = ref({});
        this.annotationSetStyles = ref({});
        // Annotation State Colors
        const standard = {
            strokeWidth: 3,
            opacity: 1.0,
            color: 'type',
            fill: false,
            showLabel: true,
            showConfidence: true,
        };
        const selected = {
            ...standard,
            color: (vuetify === null || vuetify === void 0 ? void 0 : vuetify.preset.theme.themes.dark.accent) || 'cyan',
            strokeWidth: 5,
            opacity: 1.0,
            fill: false,
        };
        const disabled = {
            ...standard,
            color: 'type',
            strokeWidth: 1,
            opacity: 0.45,
            fill: false,
        };
        this.stateStyles = { standard, selected, disabled };
        this.typeColors = d3.scaleOrdinal().range(generateColors(10));
        this.markChangesPending = markChangesPending;
        this.typeStyling = computed(() => {
            // establish dependency on revision counter
            if (this.revisionCounter.value)
                noop();
            const _customStyles = this.customStyles.value;
            const _annotationSetStyles = this.annotationSetStyles.value;
            return {
                color: (type, set) => {
                    if (set && _annotationSetStyles[type] && _annotationSetStyles[type].color) {
                        return _annotationSetStyles[type].color;
                    }
                    if (_customStyles[type] && _customStyles[type].color) {
                        return _customStyles[type].color;
                    }
                    if (type === '') {
                        return this.typeColors.range()[0];
                    }
                    return this.typeColors(type);
                },
                annotationSetColor: (set) => {
                    if (!set) {
                        return 'white';
                    }
                    if (set === 'default') {
                        return 'yellow';
                    }
                    if (['groundTruth', 'GT', 'ground_truth', 'Groundtruth', 'GroundTruth', 'gt'].includes(set)) {
                        return 'green';
                    }
                    return this.typeColors(set);
                },
                strokeWidth: (type, set) => {
                    if (set && _annotationSetStyles[type] && _annotationSetStyles[type].strokeWidth) {
                        return _annotationSetStyles[type].strokeWidth;
                    }
                    if (_customStyles[type] && _customStyles[type].strokeWidth) {
                        return _customStyles[type].strokeWidth;
                    }
                    return this.stateStyles.standard.strokeWidth;
                },
                fill: (type, set) => {
                    if (set && _annotationSetStyles[type] && _annotationSetStyles[type].fill !== undefined) {
                        return _annotationSetStyles[type].fill;
                    }
                    if (_customStyles[type] && _customStyles[type].fill !== undefined) {
                        return _customStyles[type].fill;
                    }
                    return this.stateStyles.standard.fill;
                },
                opacity: (type, set) => {
                    if (set && _annotationSetStyles[type] && _annotationSetStyles[type].opacity) {
                        return _annotationSetStyles[type].opacity;
                    }
                    if (_customStyles[type] && _customStyles[type].opacity) {
                        return _customStyles[type].opacity;
                    }
                    return this.stateStyles.standard.opacity;
                },
                labelSettings: (type, set) => {
                    let { showLabel, showConfidence } = this.stateStyles.standard;
                    if (_customStyles[type]) {
                        if (typeof (_customStyles[type].showLabel) === 'boolean') {
                            showLabel = _customStyles[type].showLabel;
                        }
                        if (typeof (_customStyles[type].showConfidence) === 'boolean') {
                            showConfidence = _customStyles[type].showConfidence;
                        }
                    }
                    if (set && _annotationSetStyles[type]) {
                        if (typeof (_annotationSetStyles[type].showLabel) === 'boolean') {
                            showLabel = _annotationSetStyles[type].showLabel;
                        }
                        if (typeof (_annotationSetStyles[type].showConfidence) === 'boolean') {
                            showConfidence = _annotationSetStyles[type].showConfidence;
                        }
                    }
                    return { showLabel, showConfidence };
                },
            };
        });
    }
    populateTypeStyles(styles) {
        if (styles) {
            this.customStyles.value = {
                ...defaultStaticStyles,
                ...styles,
            };
        }
        else {
            this.customStyles.value = defaultStaticStyles;
        }
        this.annotationSetStyles.value = defaultSetStaticStyles;
    }
    updateTypeStyle(args) {
        const { type, value } = args;
        const oldValue = this.customStyles.value[type] || {};
        VueSet(this.customStyles.value, type, merge(oldValue, value));
        this.revisionCounter.value += 1;
        this.markChangesPending();
    }
    getTypeStyles(allTypes) {
        //We need to remove any unused types in the colors, either deleted or changed
        //Also want to save default colors for reloading
        const savedTypeStyles = {};
        allTypes.value.forEach((name) => {
            if (!savedTypeStyles[name] && this.customStyles.value[name]) {
                savedTypeStyles[name] = this.customStyles.value[name];
            }
            else if (!savedTypeStyles[name]) { // Also save ordinal Colors as well
                savedTypeStyles[name] = { color: this.typeStyling.value.color(name) };
            }
        });
        return savedTypeStyles;
    }
}
