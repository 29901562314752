var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Recent Datasets ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"Name","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.userDir",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","depressed":"","to":("/user/" + (item.userDir)),"color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account ")])],1)]}}],null,true)},[_c('span',[_vm._v("Launch User Directory")])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(item.type === 'video' ? 'mdi-file-video' : 'mdi-camera-burst'))])]}},{key:"item.dataset",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","to":("/viewer/" + (item.dataset))}},[_vm._v(" Open Dataset ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }