import { computed, defineComponent, onBeforeUnmount, onMounted, ref, toRef, watch, } from '@vue/composition-api';
import Viewer from 'dive-common/components/Viewer.vue';
import NavigationTitle from 'dive-common/components/NavigationTitle.vue';
import RunPipelineMenu from 'dive-common/components/RunPipelineMenu.vue';
import ImportAnnotations from 'dive-common/components/ImportAnnotations.vue';
import SidebarContext from 'dive-common/components/SidebarContext.vue';
import context from 'dive-common/store/context';
import { useStore } from 'platform/web-girder/store/types';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { useApi } from 'dive-common/apispec';
import { convertLargeImage } from 'platform/web-girder/api/rpc.service';
import JobsTab from './JobsTab.vue';
import Export from './Export.vue';
import Clone from './Clone.vue';
import ViewerAlert from './ViewerAlert.vue';
import RevisionHistory from './RevisionHistory.vue';
import AnnotationSets from './AnnotationSets.vue';
const buttonOptions = {
    text: true,
    color: 'grey lighten-1',
    outlined: true,
    depressed: true,
    class: ['mx-1'],
};
const menuOptions = {
    offsetY: true,
    bottom: true,
};
context.register({
    component: RevisionHistory,
    description: 'Revision History',
});
context.register({
    component: AnnotationSets,
    description: 'Annotation Sets',
});
/**
 * ViewerLoader is responsible for loading
 * data from girder.
 */
export default defineComponent({
    components: {
        Clone,
        Export,
        JobsTab,
        RunPipelineMenu,
        NavigationTitle,
        Viewer,
        ImportAnnotations,
        RevisionHistory,
        SidebarContext,
        ViewerAlert,
        AnnotationSets,
        ...context.getComponents(),
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        revision: {
            type: String,
            default: undefined,
        },
        set: {
            type: String,
            default: undefined,
        },
        comparisonSets: {
            type: Array,
            default: () => [],
        },
    },
    // TODO: This will require an import from vue-router for Vue3 compatibility
    async beforeRouteLeave(to, from, next) {
        if (await this.viewerRef.navigateAwayGuard()) {
            next();
        }
    },
    setup(props, ctx) {
        const { loadMetadata } = useApi();
        const { prompt } = usePrompt();
        const viewerRef = ref();
        const store = useStore();
        const brandData = toRef(store.state.Brand, 'brandData');
        const revisionNum = computed(() => {
            const parsed = Number.parseInt(props.revision, 10);
            if (Number.isNaN(parsed))
                return undefined;
            return parsed;
        });
        const { getters } = store;
        const currentJob = computed(() => getters['Jobs/datasetCompleteJobs'](props.id));
        const typeList = ref([]);
        const findType = async () => {
            const meta = await loadMetadata(props.id);
            typeList.value = [meta.type];
        };
        findType();
        const runningPipelines = computed(() => {
            const results = [];
            if (getters['Jobs/datasetRunningState'](props.id)) {
                results.push(props.id);
            }
            return results;
        });
        if (props.revision) {
            /* When a revision is loaded, toggle the revision history on */
            context.state.active = 'RevisionHistory';
        }
        watch(currentJob, async () => {
            if (currentJob.value !== false && currentJob.value !== undefined) {
                if (currentJob.value.success) {
                    const result = await prompt({
                        title: 'Pipeline Finished',
                        text: [`Pipeline: ${currentJob.value.title}`,
                            'finished running on the current dataset.  Click reload to load the annotations.  The current annotations will be replaced with the pipeline output.',
                        ],
                        confirm: true,
                        positiveButton: 'Reload',
                        negativeButton: '',
                    });
                    store.dispatch('Jobs/removeCompleteJob', { datasetId: props.id });
                    if (result) {
                        viewerRef.value.reloadAnnotations();
                    }
                }
                else {
                    await prompt({
                        title: 'Pipeline Incomplete',
                        text: [`Pipeline: ${currentJob.value.title}`,
                            'either failed or was cancelled by the user',
                        ],
                    });
                    store.dispatch('Jobs/removeCompleteJob', { datasetId: props.id });
                }
            }
        });
        onMounted(() => {
            window.addEventListener('beforeunload', viewerRef.value.warnBrowserExit);
        });
        onBeforeUnmount(() => {
            window.removeEventListener('beforeunload', viewerRef.value.warnBrowserExit);
        });
        function routeRevision(revisionId, set) {
            if (set && set !== 'default') {
                ctx.root.$router.replace({
                    name: 'revision set viewer',
                    params: { id: props.id, revision: revisionId.toString(), set },
                });
            }
            else {
                ctx.root.$router.replace({
                    name: 'revision viewer',
                    params: { id: props.id, revision: revisionId.toString() },
                });
            }
        }
        function routeSet(set) {
            if (set === 'default') {
                ctx.root.$router.replace({
                    name: 'viewer',
                    params: { id: props.id },
                });
            }
            else {
                ctx.root.$router.replace({
                    name: 'set viewer',
                    params: { id: props.id, set },
                });
            }
            viewerRef.value.reloadAnnotations();
        }
        async function largeImageWarning() {
            const result = await prompt({
                title: 'Large Image Warning',
                text: ['The current Image Sequence dataset has a large resolution',
                    'This may prevent the image from being shown on certain hardware/browsers',
                    'This can be automatically converted to a tiled Large Image for proper viewing',
                ],
                confirm: true,
                positiveButton: 'Convert',
                negativeButton: 'Cancel',
            });
            if (result) {
                convertLargeImage(props.id);
            }
        }
        return {
            buttonOptions,
            brandData,
            context,
            menuOptions,
            revisionNum,
            viewerRef,
            getters,
            currentJob,
            runningPipelines,
            routeRevision,
            routeSet,
            largeImageWarning,
            typeList,
        };
    },
});
