import { computed, shallowRef, triggerRef, } from '@vue/composition-api';
import { cloneDeep, uniq } from 'lodash';
import GroupStore from './GroupStore';
import TrackStore from './TrackStore';
/**
 * CameraStore is a warapper for holding and collating tracks from multiple cameras.
 * If a singleCamera is in operation it uses the root 'singleCam' with a single store.
 * There are helper functions for getting tracks if they exist in any camera, specific
 * cameras as well as merging tracks together to perform operations on all of them.
 */
export default class CameraStore {
    constructor({ markChangesPending }) {
        this.markChangesPending = markChangesPending;
        const cameraName = 'singleCam';
        this.defaultGroup = ['no-group', 1.0];
        this.camMap = shallowRef(new Map([[cameraName, {
                    trackStore: new TrackStore({ markChangesPending, cameraName }),
                    groupStore: new GroupStore({ markChangesPending, cameraName }),
                }]]));
        this.sortedTracks = computed(() => {
            let idList = [];
            this.camMap.value.forEach((camera) => {
                idList = idList.concat(camera.trackStore.sorted.value.map((item) => item.id));
            });
            /**
             * The tracks need to be merged because this is used for Event/Detection viewing
             * This allows the full range begin/end for the track across multiple cameras to
             * be displayed.
             */
            return uniq(idList).map((id) => this.getTracksMergedForSorted(id));
        });
        this.sortedGroups = computed(() => {
            let list = [];
            this.camMap.value.forEach((camera) => {
                list = list.concat(camera.groupStore.sorted.value);
            });
            return list;
        });
    }
    getTrack(trackId, cameraName = 'singleCam') {
        var _a;
        const currentMap = (_a = this.camMap.value.get(cameraName)) === null || _a === void 0 ? void 0 : _a.trackStore;
        if (!currentMap) {
            throw new Error(`No camera Map with the camera name: ${cameraName}`);
        }
        const tempTrack = currentMap === null || currentMap === void 0 ? void 0 : currentMap.get(trackId);
        if (!tempTrack) {
            throw new Error(`TrackId ${trackId} not found in trackMap with cameraName ${cameraName}`);
        }
        return tempTrack;
    }
    getPossibleTrack(trackId, cameraName = 'singleCam') {
        try {
            return this.getTrack(trackId, cameraName);
        }
        catch (err) {
            return undefined;
        }
    }
    getAnyPossibleTrack(trackId) {
        let track;
        this.camMap.value.forEach((camera) => {
            const tempTrack = camera.trackStore.getPossible(trackId);
            if (tempTrack) {
                track = tempTrack;
            }
        });
        if (track) {
            return track;
        }
        return undefined;
    }
    getAnyTrack(trackId) {
        let track;
        this.camMap.value.forEach((camera) => {
            const tempTrack = camera.trackStore.getPossible(trackId);
            if (tempTrack) {
                track = tempTrack;
            }
        });
        if (track) {
            return track;
        }
        throw new Error(`TrackId ${trackId} not found in any camera`);
    }
    getTrackAll(trackId) {
        const trackList = [];
        this.camMap.value.forEach((camera) => {
            const tempTrack = camera.trackStore.getPossible(trackId);
            if (tempTrack) {
                trackList.push(tempTrack);
            }
        });
        return trackList;
    }
    getTracksMerged(trackId) {
        if (this.camMap.value.size === 1) {
            return this.getTrack(trackId);
        }
        let track;
        this.camMap.value.forEach((camera) => {
            const tempTrack = camera.trackStore.getPossible(trackId);
            if (!track && tempTrack) {
                track = cloneDeep(tempTrack);
            }
            else if (track && tempTrack) {
                // Merge track bounds and data together
                // We don't care about feature data just that features are at X frame
                track.merge([tempTrack], true);
            }
        });
        if (!track) {
            throw Error(`TrackId: ${trackId} is not found in any camera`);
        }
        return track;
    }
    getTracksMergedForSorted(trackId) {
        const track = this.getTracksMerged(trackId);
        return {
            id: track.id,
            confidencePairs: track.confidencePairs,
            begin: track.begin,
            end: track.end,
            getType: (index) => (track.confidencePairs[index || 0][0] || 'unknown'),
        };
    }
    addCamera(cameraName) {
        if (this.camMap.value.get(cameraName) === undefined) {
            this.camMap.value.set(cameraName, {
                trackStore: new TrackStore({ markChangesPending: this.markChangesPending, cameraName }),
                groupStore: new GroupStore({ markChangesPending: this.markChangesPending, cameraName }),
            });
            // Bump the shallowRef
            triggerRef(this.camMap);
        }
    }
    removeCamera(cameraName) {
        if (this.camMap.value.get(cameraName) !== undefined) {
            this.camMap.value.delete(cameraName);
            // Bump the shallowRef
            triggerRef(this.camMap);
        }
    }
    lookupGroups(trackId) {
        let groups = [];
        if (this.camMap) {
            this.camMap.value.forEach((camera) => {
                const groupIds = camera.groupStore.trackMap.get(trackId);
                if (groupIds) {
                    groups = groups.concat(Array.from(groupIds).map((v) => camera.groupStore.get(v)));
                }
            });
        }
        return groups;
    }
    remove(trackId, cameraName = '') {
        this.camMap.value.forEach((camera) => {
            if (camera.trackStore.getPossible(trackId)) {
                if (cameraName === '' || camera.trackStore.cameraName === cameraName) {
                    camera.trackStore.remove(trackId);
                }
                if (cameraName === '' || camera.groupStore.cameraName === cameraName) {
                    camera.groupStore.trackRemove(trackId);
                }
            }
        });
    }
    getNewTrackId() {
        let trackIds = [];
        this.camMap.value.forEach((camera) => {
            trackIds = trackIds.concat(camera.trackStore.annotationIds.value);
        });
        if (!trackIds.length) {
            return 0;
        }
        return trackIds.reduce((prev, current) => Math.max(prev, current)) + 1;
    }
    clearAll() {
        this.camMap.value.forEach((camera) => {
            camera.trackStore.clearAll();
            camera.groupStore.clearAll();
        });
    }
    removeTracks(id, cameraName = '') {
        this.camMap.value.forEach((camera) => {
            if (camera.trackStore.getPossible(id)) {
                if (cameraName === '' || camera.trackStore.cameraName === cameraName) {
                    camera.trackStore.remove(id);
                }
            }
        });
    }
    removeGroups(id, cameraName = '') {
        this.camMap.value.forEach((camera) => {
            if (camera.groupStore.getPossible(id)) {
                if (cameraName === '' || camera.groupStore.cameraName === cameraName) {
                    camera.groupStore.remove(id);
                }
            }
        });
    }
    // Update all cameras to have the same track type
    setTrackType(id, newType, confidenceVal, currentType) {
        this.camMap.value.forEach((camera) => {
            const track = camera.trackStore.getPossible(id);
            if (track !== undefined) {
                track.setType(newType, confidenceVal, currentType);
            }
        });
    }
    changeTrackTypes({ currentType, newType }) {
        this.camMap.value.forEach((camera) => {
            camera.trackStore.sorted.value.forEach((annotation) => {
                for (let i = 0; i < annotation.confidencePairs.length; i += 1) {
                    const [name, confidenceVal] = annotation.confidencePairs[i];
                    if (name === currentType) {
                        const track = camera.trackStore.get(annotation.id);
                        if (track) {
                            track.setType(newType, confidenceVal, currentType);
                        }
                        break;
                    }
                }
            });
        });
    }
    removeTypes(id, types) {
        let resultingTypes = [];
        this.camMap.value.forEach((camera) => {
            const track = camera.trackStore.getPossible(id);
            if (track !== undefined) {
                resultingTypes = track.removeTypes(types);
            }
        });
        return resultingTypes;
    }
    getGroupMemebers(id) {
        let members = {};
        this.camMap.value.forEach((camera) => {
            const group = camera.groupStore.get(id);
            if (group !== undefined) {
                members = group.members;
            }
        });
        return members;
    }
}
