import { computed, defineComponent } from '@vue/composition-api';
import { useGroupFilterControls, useHandler, useReadOnlyMode } from '../provides';
import TypePicker from './TypePicker.vue';
export default defineComponent({
    name: 'GroupItem',
    components: { TypePicker },
    props: {
        group: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        secondarySelected: {
            type: Boolean,
            required: true,
        },
        selectedTrackId: {
            type: [Number, null],
            default: null,
        },
        inputValue: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { root }) {
        const vuetify = root.$vuetify;
        const groupFilters = useGroupFilterControls();
        const readOnlyMode = useReadOnlyMode();
        const handler = useHandler();
        const style = computed(() => {
            if (props.selected) {
                return {
                    'background-color': `${vuetify.theme.themes.dark.accentBackground}`,
                };
            }
            if (props.secondarySelected) {
                return {
                    'background-color': '#3a3a3a',
                };
            }
            return {};
        });
        return {
            style,
            groupFilters,
            readOnlyMode,
            handler,
        };
    },
});
