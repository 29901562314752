import { defineComponent, ref, computed, } from '@vue/composition-api';
import { ImageSequenceType, VideoType, DefaultVideoFPS, FPSOptions, inputAnnotationFileTypes, websafeVideoTypes, otherVideoTypes, websafeImageTypes, otherImageTypes, JsonMetaRegEx, largeImageTypes, LargeImageType, } from 'dive-common/constants';
import { fileSuffixRegex, } from 'platform/web-girder/constants';
import ImportButton from 'dive-common/components/ImportButton.vue';
import ImportMultiCamDialog from 'dive-common/components/ImportMultiCamDialog.vue';
import { validateUploadGroup } from 'platform/web-girder/api';
import { openFromDisk } from 'platform/web-girder/utils';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { getResponseError } from 'vue-media-annotator/utils';
import { clientSettings } from 'dive-common/store/settings';
import UploadGirder from './UploadGirder.vue';
export default defineComponent({
    components: { ImportButton, ImportMultiCamDialog, UploadGirder },
    props: {
        location: {
            type: Object,
            required: true,
        },
    },
    setup(_, { emit }) {
        const preUploadErrorMessage = ref(null);
        const pendingUploads = ref([]);
        const stereo = ref(false);
        const multiCamOpenType = ref('image-sequence');
        const importMultiCamDialog = ref(false);
        const girderUpload = ref(null);
        const { prompt } = usePrompt();
        const addPendingZipUpload = (name, allFiles) => {
            const fps = clientSettings.annotationFPS || DefaultVideoFPS;
            const defaultFilename = allFiles.length ? allFiles[0].name.replace(/\..*/, '') : 'Zip Upload';
            pendingUploads.value.push({
                createSubFolders: false,
                name: defaultFilename,
                files: [],
                meta: null,
                annotationFile: null,
                mediaList: allFiles,
                type: 'zip',
                fps,
                uploading: false,
            });
        };
        const addPendingUpload = async (name, allFiles, meta, annotationFile, mediaList, suggestedFps) => {
            const resp = (await validateUploadGroup(allFiles.map((f) => f.name))).data;
            if (!resp.ok) {
                if (resp.message) {
                    preUploadErrorMessage.value = resp.message;
                }
                throw new Error(resp.message);
            }
            const fps = suggestedFps || clientSettings.annotationFPS || DefaultVideoFPS;
            const defaultFilename = resp.media[0];
            const validFiles = resp.media.concat(resp.annotations);
            // mapping needs to be done for the mixin upload functions
            const internalFiles = allFiles
                .filter((f) => validFiles.includes(f.name));
            let createSubFolders = false;
            if (resp.type === 'video') {
                if (resp.media.length > 1) {
                    createSubFolders = true;
                }
            }
            pendingUploads.value.push({
                createSubFolders,
                name: internalFiles.length > 1
                    ? defaultFilename.replace(fileSuffixRegex, '')
                    : defaultFilename,
                files: [],
                meta,
                annotationFile,
                mediaList,
                type: resp.type,
                fps,
                uploading: false,
            });
        };
        /**
         * Processes the imported media files to distinguish between
         * Media Files - Default files that aren't the Annotation or Meta
         * Annotation File - CSV or JSON file, or more in the future
         * Meta File - Right now a json file which has 'meta' or 'config in the name
         */
        const processImport = (files) => {
            //Check for auto files for meta and annotations
            const output = {
                annotationFile: null,
                metaFile: null,
                mediaList: [],
                fullList: [],
            };
            const jsonFiles = [];
            const csvFiles = [];
            if (files.fileList) {
                files.filePaths.forEach((item, index) => {
                    if (item.indexOf('.json') !== -1) {
                        jsonFiles.push([item, index]);
                    }
                    else if (item.indexOf('.csv') !== -1) {
                        csvFiles.push([item, index]);
                    }
                });
                output.mediaList = files.fileList.filter((item) => (item.name.indexOf('.json') === -1 && item.name.indexOf('.csv') === -1));
                const metaIndex = jsonFiles.findIndex((item) => (JsonMetaRegEx.test(item[0])));
                if (metaIndex !== -1) {
                    output.metaFile = files.fileList[jsonFiles[metaIndex][1]];
                    jsonFiles.splice(metaIndex, 1); //remove chosen meta from list
                }
                if (jsonFiles.length === 1 && csvFiles.length === 0) { // only remaining json file
                    output.annotationFile = files.fileList[jsonFiles[0][1]];
                }
                else if (csvFiles.length) { // Prefer First CSV if both found
                    output.annotationFile = files.fileList[csvFiles[0][1]];
                }
                else if (jsonFiles.length > 1) { //multiple jsons, filter out additional meta/configs
                    const filtered = jsonFiles.filter((item) => (!JsonMetaRegEx.test(item[0]) && (item[0].indexOf('.json') !== -1)));
                    if (filtered.length) { // take first filtered JSON file
                        output.annotationFile = files.fileList[filtered[0][1]];
                    }
                }
                output.fullList = [...output.mediaList];
                if (output.annotationFile) {
                    output.fullList.push(output.annotationFile);
                }
                if (output.metaFile) {
                    output.fullList.push(output.metaFile);
                }
            }
            return output;
        };
        /**
         * Initial opening of file dialog
         */
        const openImport = async (dstype) => {
            var _a, _b, _c;
            const ret = await openFromDisk(dstype);
            if (!ret.canceled && ret.fileList) {
                const processed = processImport(ret);
                if (((_a = processed === null || processed === void 0 ? void 0 : processed.fullList) === null || _a === void 0 ? void 0 : _a.length) === 0)
                    return;
                if (processed && processed.fullList) {
                    const name = processed.fullList.length === 1 ? processed.fullList[0].name : '';
                    preUploadErrorMessage.value = null;
                    try {
                        if (dstype !== 'zip') {
                            const suggestedFps = dstype === 'image-sequence' || dstype === 'large-image' ? 1 : undefined;
                            await addPendingUpload(name, processed.fullList, processed.metaFile, processed.annotationFile, processed.mediaList, suggestedFps);
                        }
                        else {
                            addPendingZipUpload(name, processed.fullList);
                        }
                    }
                    catch (err) {
                        preUploadErrorMessage.value = ((_c = (_b = err.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || err;
                    }
                }
            }
        };
        const openMultiCamDialog = (args) => {
            stereo.value = args.stereo;
            multiCamOpenType.value = args.openType;
            importMultiCamDialog.value = true;
        };
        const filterFileUpload = (type) => {
            if (type === 'meta') {
                return '.json';
            }
            if (type === 'annotation') {
                return inputAnnotationFileTypes.map((item) => `.${item}`).join(',');
            }
            if (type === 'video') {
                return websafeVideoTypes.concat(otherVideoTypes);
            }
            if (type === 'large-image') {
                return largeImageTypes;
            }
            return websafeImageTypes.concat(otherImageTypes);
        };
        // TODO:  Implementation of initialization organization of multiCam files into a return
        // of the MediaImportResponse defined in ImportMultiCamDialog.vue
        const multiCamImportCheck = (files) => ({
            jsonMeta: {
                originalImageFiles: files,
            },
            globPattern: '',
            mediaConvertList: [],
        });
        //TODO:  Implementation of the finalization of the Import.  Requires
        // Creation of an endpoint in the server which supports MultiCamImportArgs
        const multiCamImport = (args) => {
            // eslint-disable-next-line no-console
            console.log(args);
        };
        // Filter to show how many files are left to upload
        const filesNotUploaded = (item) => item.files.filter((file) => file.status !== 'done' && file.status !== 'error').length;
        // Processes the pending upload from the GirderUpload system to determine the progress
        const computeUploadProgress = (pendingUpload) => {
            // use methods and properties from mixins
            if (girderUpload.value) {
                //Need to use the girderUpload ref to get these values out of the mixin
                const { formatSize, totalProgress, totalSize } = girderUpload.value;
                if (pendingUpload.files.length === 1 && !pendingUpload.uploading) {
                    return formatSize(pendingUpload.files[0].progress.size);
                }
                if ([ImageSequenceType, LargeImageType].includes(pendingUpload.type)) {
                    return `${filesNotUploaded(pendingUpload)} files remaining`;
                }
                if (pendingUpload.type === VideoType && !pendingUpload.uploading) {
                    return `${filesNotUploaded(pendingUpload)} videos remaining`;
                }
                if ((pendingUpload.type === VideoType || pendingUpload.type === 'zip') && pendingUpload.uploading) {
                    // For videos we display the total progress when uploading because
                    // single videos can be large
                    return `${formatSize(totalProgress)} of ${formatSize(totalSize)}`;
                }
            }
            throw new Error(`could not determine adequate formatting for ${pendingUpload}`);
        };
        const getFilenameInputStateLabel = (pendingUpload) => {
            const plural = pendingUpload.createSubFolders
                ? 's'
                : '';
            return `Folder Name${plural}`;
        };
        const getFilenameInputStateDisabled = (pendingUpload) => (pendingUpload.uploading || (pendingUpload.createSubFolders && pendingUpload.type !== 'zip'));
        const getFilenameInputStateHint = (pendingUpload) => ((pendingUpload.createSubFolders && pendingUpload.type !== 'zip') ? 'default folder names are used when "Create Subfolders" is selected' : '');
        const getFilenameInputValue = (pendingUpload) => (pendingUpload.createSubFolders && pendingUpload.type !== 'zip' ? 'default' : pendingUpload.name);
        const remove = (pendingUpload) => {
            const index = pendingUploads.value.indexOf(pendingUpload);
            pendingUploads.value.splice(index, 1);
        };
        function close() {
            emit('close');
        }
        function abort() {
            if (pendingUploads.value.length === 0) {
                close();
            }
        }
        const uploading = computed(() => pendingUploads.value.some((v) => v.uploading));
        const buttonAttrs = computed(() => {
            if (pendingUploads.value.length === 0) {
                return {
                    block: true,
                    color: 'primary',
                    disabled: uploading.value,
                };
            }
            return {
                block: true,
                color: 'grey darken-3',
                depressed: true,
                disabled: uploading.value,
            };
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorHandler = async ({ err, name }) => {
            const text = getResponseError(err);
            await prompt({
                title: `${name}: Import Error`,
                text,
                positiveButton: 'OK',
            });
        };
        return {
            buttonAttrs,
            FPSOptions,
            preUploadErrorMessage,
            pendingUploads,
            stereo,
            multiCamOpenType,
            importMultiCamDialog,
            girderUpload,
            uploading,
            clientSettings,
            //methods
            close,
            openImport,
            processImport,
            openMultiCamDialog,
            filterFileUpload,
            multiCamImportCheck,
            multiCamImport,
            computeUploadProgress,
            getFilenameInputStateLabel,
            getFilenameInputValue,
            getFilenameInputStateDisabled,
            getFilenameInputStateHint,
            addPendingUpload,
            remove,
            abort,
            errorHandler,
        };
    },
});
