import { createApp } from '@vue/composition-api';
import geo from 'geojs';
/**
 * UILayer provides a way to add Reactive VUE DOM Widgets to GeoJS
 * These widgets are created under their own Vue App and can't rely on parent elements
 * for reactivity.
 * Reactive properties for these components will need to be passed in as Refs and
 * dereferenced inside of the Vue component to properly update.
 * This will probably change once Vue 3 is adopted and <teleport> can be used
 */
export default class UILayer {
    constructor(annotator) {
        this.zoomToolTipPosition = () => {
            Object.keys(this.widgets).forEach((name) => {
                if (this.widgets[name].toolTip) {
                    const mousePos = this.widgets[name].lastMousePos;
                    this.updateWidgetToolTipPosition(mousePos, this.widgets[name]);
                }
            });
        };
        this.updateToolTipPositions = (evt) => {
            const mousePos = evt.geo;
            Object.keys(this.widgets).forEach((name) => {
                if (this.widgets[name].toolTip) {
                    this.updateWidgetToolTipPosition(mousePos, this.widgets[name]);
                    this.widgets[name].lastMousePos = mousePos;
                }
            });
        };
        this.annotator = annotator;
        this.widgets = {};
        this.uiLayer = this.annotator.geoViewerRef.value.createLayer('ui');
        this.uiLayer.geoOn(geo.event.mousemove, this.updateToolTipPositions);
        this.uiLayer.geoOn(geo.event.zoom, this.zoomToolTipPosition);
    }
    updateWidgetToolTipPosition(mousePos, widget) {
        const tipOffset = widget.toolTipOffset;
        const newOffset = this.uiLayer.map().gcsToDisplay(mousePos);
        const finalOffset = this.uiLayer.map().displayToGcs({
            x: newOffset.x + tipOffset.x, y: newOffset.y + tipOffset.y,
        });
        widget.position(finalOffset);
    }
    addDOMWidget(name, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component, props, position = { x: 0, y: 0 }) {
        const widget = this.uiLayer.createWidget('dom', { position });
        widget.canvas().setAttribute('id', name);
        const parent = widget.canvas();
        const div = document.createElement('div');
        const element = parent.appendChild(div);
        createApp(component, props).mount(element);
        widget.toolTipOffset = position;
        widget.toolTip = false;
        widget.lastMousePos = position;
        this.widgets[name] = widget;
        return widget;
    }
    // Toggle named widget toolTip On or Off
    setToolTipWidget(name, on) {
        if (this.widgets[name]) {
            this.widgets[name].toolTip = on;
        }
    }
}
