import { computed, ref, set as VueSet, } from '@vue/composition-api';
export default function useImageEnhancements() {
    const imageEnhancements = ref({});
    const setSVGFilters = ({ blackPoint, whitePoint }) => {
        VueSet(imageEnhancements.value, 'blackPoint', blackPoint);
        VueSet(imageEnhancements.value, 'whitePoint', whitePoint);
    };
    const brightness = computed(() => {
        if (imageEnhancements.value.blackPoint !== undefined
            && imageEnhancements.value.whitePoint !== undefined) {
            return (1 / (imageEnhancements.value.whitePoint - imageEnhancements.value.blackPoint));
        }
        return undefined;
    });
    const intercept = computed(() => {
        if (imageEnhancements.value.blackPoint !== undefined
            && imageEnhancements.value.whitePoint !== undefined) {
            return (-imageEnhancements.value.blackPoint
                / (imageEnhancements.value.whitePoint - imageEnhancements.value.blackPoint));
        }
        return undefined;
    });
    return {
        imageEnhancements,
        brightness,
        intercept,
        setSVGFilters,
    };
}
