/* eslint-disable max-len */
import { computed, defineComponent, ref } from '@vue/composition-api';
import { throttle } from 'lodash';
import { useAttributes, useTrackFilters, useTrackStyleManager } from 'vue-media-annotator/provides';
export default defineComponent({
    name: 'AttributeTrackFilter',
    props: {
        filterIndex: {
            type: Number,
            default: 0,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const typeStylingRef = useTrackStyleManager().typeStyling;
        const trackFilters = useTrackFilters();
        const attributes = useAttributes();
        const baseFilter = computed(() => {
            if (trackFilters.attributeFilters.value.length > 0
                && trackFilters.attributeFilters.value[props.filterIndex]) {
                return trackFilters.attributeFilters.value[props.filterIndex];
            }
            return null;
        });
        const enabled = computed(() => (baseFilter.value ? trackFilters.enabledFilters.value[props.filterIndex] : false));
        const range = computed(() => { var _a, _b; return ((_b = (_a = baseFilter.value) === null || _a === void 0 ? void 0 : _a.filter) === null || _b === void 0 ? void 0 : _b.range) || [0, 1.0]; });
        const attrType = computed(() => {
            if (baseFilter.value) {
                const filtered = attributes.value.filter((item) => {
                    if (baseFilter.value) {
                        return item.name === baseFilter.value.attribute;
                    }
                    return false;
                });
                if (filtered.length > 0) {
                    return filtered[0].datatype;
                }
            }
            return null;
        });
        const userDefinedValue = computed(() => {
            if (baseFilter.value) {
                const val = trackFilters.userDefinedValues.value[props.filterIndex];
                if (attrType.value === 'number' && val !== null) {
                    if (typeof val === 'string') {
                        return parseFloat(val);
                    }
                    if (typeof val === 'number') {
                        return val;
                    }
                }
                else {
                    return val;
                }
            }
            return null;
        });
        const typeConversion = ref({ text: 'string', number: 'number', boolean: 'boolean' });
        const inputFilter = ref(['=', '≠', '>', '<', '>=', '<=', 'contains']);
        function setEnabled(val) {
            trackFilters.setEnabled(props.filterIndex, val);
            if (val) {
                trackFilters.setUserDefinedValue(props.filterIndex, userDefinedValue.value);
            }
        }
        function _updateValue(event) {
            const target = event.target;
            if (target) {
                if (attrType.value === 'number') {
                    const val = Number.parseFloat(target.value);
                    trackFilters.setUserDefinedValue(props.filterIndex, val);
                }
                else if (attrType.value === 'text') {
                    trackFilters.setUserDefinedValue(props.filterIndex, target.value);
                }
            }
        }
        function updateCombo(event) {
            trackFilters.setUserDefinedValue(props.filterIndex, event);
        }
        const updateValue = throttle(_updateValue, 100);
        return {
            updateValue,
            baseFilter,
            userDefinedValue,
            range,
            trackFilters,
            enabled,
            setEnabled,
            attrType,
            inputFilter,
            typeConversion,
            updateCombo,
            typeStylingRef,
        };
    },
});
