var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('panel-subsection',[_c('template',{slot:"header"},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('b',[_vm._v("Confidence Pairs:")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"my-1",attrs:{"disabled":"","outlined":"","x-small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Pair ")],1)]}}])},[_c('span',[_vm._v("Add a new Confidence Pair")])])],1)],1),_c('template',{slot:"scroll-section"},[_c('v-col',{staticClass:"pa-0"},_vm._l((_vm.confidencePairs),function(pair,index){return _c('span',{key:index,staticStyle:{"font-size":"0.8em"}},[_c('v-row',{staticClass:"ma-0",attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"type-color-box",style:({
                backgroundColor: _vm.typeStylingRef.color(pair[0]),
              })})]),_c('v-col',{attrs:{"cols":pair[1] !== 1 && !_vm.disabled ? '7' : '8'}},[_vm._v(" "+_vm._s(pair[0])+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"type-score shrink mr-1",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(pair[1].toFixed(4))+" ")]),(pair[1] !== 1 && !_vm.disabled)?_c('v-col',{staticClass:"shrink"},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var bind = ref.bind;
              var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.$emit('set-type', pair[0])}}},'v-btn',bind,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept "+_vm._s(pair[0])+" as correct type")])])],1):_vm._e()],1)],1)}),0)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }