import { provide, inject, ref, reactive, } from '@vue/composition-api';
import StyleManager from './StyleManager';
import TrackFilterControls from './TrackFilterControls';
import GroupFilterControls from './GroupFilterControls';
import CameraStore from './CameraStore';
/**
 * Type definitions are read only because injectors may mutate internal state,
 * but should never overwrite or delete the injected object.
 */
const AnnotatorPreferencesSymbol = Symbol('annotatorPreferences');
const AttributesSymbol = Symbol('attributes');
const AttributesFilterSymbol = Symbol('attributesFilter');
const DatasetIdSymbol = Symbol('datasetID');
const EditingModeSymbol = Symbol('editingMode');
const MultiSelectSymbol = Symbol('multiSelect');
const PendingSaveCountSymbol = Symbol('pendingSaveCount');
const ProgressSymbol = Symbol('progress');
const RevisionIdSymbol = Symbol('revisionId');
const AnnotationSetSymbol = Symbol('annotationSet');
const AnnotationSetsSymbol = Symbol('annotationSets');
const ComparisonSetsSymbol = Symbol('comparisonSets');
const SelectedCameraSymbol = Symbol('selectedCamera');
const SelectedKeySymbol = Symbol('selectedKey');
const SelectedTrackIdSymbol = Symbol('selectedTrackId');
const EditingGroupIdSymbol = Symbol('editingGroupId');
const TimeSymbol = Symbol('time');
const VisibleModesSymbol = Symbol('visibleModes');
const ReadOnlyModeSymbol = Symbol('readOnlyMode');
const ImageEnhancementsSymbol = Symbol('imageEnhancements');
/** Class-based symbols */
const CameraStoreSymbol = Symbol('cameraStore');
const TrackStyleManagerSymbol = Symbol('trackTypeStyling');
const GroupStyleManagerSymbol = Symbol('groupTypeStyling');
const TrackFilterControlsSymbol = Symbol('trackFilters');
const GroupFilterControlsSymbol = Symbol('groupFilters');
const HandlerSymbol = Symbol('handler');
/**
 * Make a trivial noop handler. Useful if you only intend to
 * override some small number of values.
 * @param handle callbacl for all handler methods
 */
function dummyHandler(handle) {
    return {
        save(...args) { handle('save', args); return Promise.resolve(); },
        trackSeek(...args) { handle('trackSeek', args); },
        trackEdit(...args) { handle('trackEdit', args); },
        trackSelect(...args) { handle('trackSelect', args); },
        trackSelectNext(...args) { handle('trackSelectNext', args); },
        trackSplit(...args) { handle('trackSplit', args); },
        trackAdd(...args) { handle('trackAdd', args); return 0; },
        updateRectBounds(...args) { handle('updateRectBounds', args); },
        updateGeoJSON(...args) { handle('updateGeoJSON', args); },
        removeTrack(...args) { handle('removeTrack', args); },
        removeGroup(...args) { handle('removeGroup', args); },
        removePoint(...args) { handle('removePoint', args); },
        removeAnnotation(...args) { handle('removeAnnotation', args); },
        selectCamera(...args) { handle('selectCamera', args); },
        selectFeatureHandle(...args) { handle('selectFeatureHandle', args); },
        setAttribute(...args) { handle('setAttribute', args); },
        deleteAttribute(...args) { handle('deleteAttribute', args); },
        toggleMerge(...args) { handle('toggleMerge', args); return []; },
        commitMerge(...args) { handle('commitMerge', args); },
        groupAdd(...args) { handle('groupAdd', args); },
        groupEdit(...args) { handle('groupEdit', args); },
        unstageFromMerge(...args) { handle('unstageFromMerge', args); },
        reloadAnnotations(...args) { handle('reloadTracks', args); return Promise.resolve(); },
        setSVGFilters(...args) { handle('setSVGFilter', args); },
        unlinkCameraTrack(...args) { handle('unlinkCameraTrack', args); },
        linkCameraTrack(...args) { handle('linkCameraTrack', args); },
        startLinking(...args) { handle('startLinking', args); },
        stopLinking(...args) { handle('stopLinking', args); },
        setChange(...args) { handle('setChange', args); },
    };
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const markChangesPending = () => { };
/**
 * make a trivial state store. Useful if you only
 * intend to override some small number of values.
 */
function dummyState() {
    const cameraStore = new CameraStore({ markChangesPending });
    const setTrackType = (id, newType, confidenceVal, currentType) => {
        cameraStore.setTrackType(id, newType, confidenceVal, currentType);
    };
    const removeTypes = (id, types) => cameraStore.removeTypes(id, types);
    const groupFilterControls = new GroupFilterControls({
        sorted: cameraStore.sortedGroups,
        remove: cameraStore.removeGroups,
        markChangesPending,
        setType: setTrackType,
        removeTypes,
    });
    const trackFilterControls = new TrackFilterControls({
        sorted: cameraStore.sortedTracks,
        remove: cameraStore.removeTracks,
        markChangesPending,
        groupFilterControls,
        lookupGroups: cameraStore.lookupGroups,
        getTrack: (track, camera = 'singleCam') => (cameraStore.getTrack(track, camera)),
        setType: setTrackType,
        removeTypes,
    });
    return {
        annotatorPreferences: ref({ trackTails: { before: 20, after: 10 } }),
        attributes: ref([]),
        cameraStore,
        datasetId: ref(''),
        editingMode: ref(false),
        multiSelectList: ref([]),
        pendingSaveCount: ref(0),
        progress: reactive({ loaded: true }),
        revisionId: ref(0),
        annotationSet: ref(''),
        annotationSets: ref([]),
        comparisonSets: ref([]),
        groupFilters: groupFilterControls,
        groupStyleManager: new StyleManager({ markChangesPending }),
        selectedCamera: ref('singleCam'),
        selectedKey: ref(''),
        selectedTrackId: ref(null),
        editingGroupId: ref(null),
        time: {
            frame: ref(0),
            flick: ref(0),
            frameRate: ref(0),
            originalFps: ref(null),
        },
        trackFilters: trackFilterControls,
        trackStyleManager: new StyleManager({ markChangesPending }),
        visibleModes: ref(['rectangle', 'text']),
        readOnlyMode: ref(false),
        imageEnhancements: ref({}),
    };
}
/**
 * Provide global state and handler for a single instance
 * of vue-media-annotator.  Multiple annotator windows
 * are currently not supported.
 *
 * @param {State} state
 * @param {Handler} handler
 * @param {}
 */
function provideAnnotator(state, handler, attributesFilters) {
    provide(AnnotatorPreferencesSymbol, state.annotatorPreferences);
    provide(AttributesSymbol, state.attributes);
    provide(CameraStoreSymbol, state.cameraStore);
    provide(DatasetIdSymbol, state.datasetId);
    provide(EditingModeSymbol, state.editingMode);
    provide(GroupFilterControlsSymbol, state.groupFilters);
    provide(GroupStyleManagerSymbol, state.groupStyleManager);
    provide(MultiSelectSymbol, state.multiSelectList);
    provide(PendingSaveCountSymbol, state.pendingSaveCount);
    provide(ProgressSymbol, state.progress);
    provide(RevisionIdSymbol, state.revisionId);
    provide(AnnotationSetSymbol, state.annotationSet);
    provide(AnnotationSetsSymbol, state.annotationSets);
    provide(ComparisonSetsSymbol, state.comparisonSets);
    provide(TrackFilterControlsSymbol, state.trackFilters);
    provide(TrackStyleManagerSymbol, state.trackStyleManager);
    provide(SelectedCameraSymbol, state.selectedCamera);
    provide(SelectedKeySymbol, state.selectedKey);
    provide(SelectedTrackIdSymbol, state.selectedTrackId);
    provide(EditingGroupIdSymbol, state.editingGroupId);
    provide(TimeSymbol, state.time);
    provide(VisibleModesSymbol, state.visibleModes);
    provide(ReadOnlyModeSymbol, state.readOnlyMode);
    provide(ImageEnhancementsSymbol, state.imageEnhancements);
    provide(HandlerSymbol, handler);
    provide(AttributesFilterSymbol, attributesFilters);
}
function _handleMissing(s) {
    return new Error(`Missing provided object for symbol ${s.toString()}: must provideAnnotator()`);
}
function use(s) {
    const v = inject(s);
    if (v === undefined) {
        throw _handleMissing(s);
    }
    return v;
}
function useAnnotatorPreferences() {
    return use(AnnotatorPreferencesSymbol);
}
function useAttributes() {
    return use(AttributesSymbol);
}
function useAttributesFilters() {
    return use(AttributesFilterSymbol);
}
function useCameraStore() {
    return use(CameraStoreSymbol);
}
function useDatasetId() {
    return use(DatasetIdSymbol);
}
function useEditingMode() {
    return use(EditingModeSymbol);
}
function useGroupFilterControls() {
    return use(GroupFilterControlsSymbol);
}
function useGroupStyleManager() {
    return use(GroupStyleManagerSymbol);
}
function useHandler() {
    return use(HandlerSymbol);
}
function useMultiSelectList() {
    return use(MultiSelectSymbol);
}
function usePendingSaveCount() {
    return use(PendingSaveCountSymbol);
}
function useProgress() {
    return use(ProgressSymbol);
}
function useRevisionId() {
    return use(RevisionIdSymbol);
}
function useAnnotationSet() {
    return use(AnnotationSetSymbol);
}
function useAnnotationSets() {
    return use(AnnotationSetsSymbol);
}
function useComparisonSets() {
    return use(ComparisonSetsSymbol);
}
function useTrackStyleManager() {
    return use(TrackStyleManagerSymbol);
}
function useSelectedCamera() {
    return use(SelectedCameraSymbol);
}
function useSelectedKey() {
    return use(SelectedKeySymbol);
}
function useSelectedTrackId() {
    return use(SelectedTrackIdSymbol);
}
function useEditingGroupId() {
    return use(EditingGroupIdSymbol);
}
function useTime() {
    return use(TimeSymbol);
}
function useTrackFilters() {
    return use(TrackFilterControlsSymbol);
}
function useVisibleModes() {
    return use(VisibleModesSymbol);
}
function useReadOnlyMode() {
    return use(ReadOnlyModeSymbol);
}
function useImageEnhancements() {
    return use(ImageEnhancementsSymbol);
}
export { dummyHandler, dummyState, provideAnnotator, use, useAnnotatorPreferences, useAttributes, useCameraStore, useDatasetId, useEditingMode, useHandler, useGroupFilterControls, useGroupStyleManager, useMultiSelectList, usePendingSaveCount, useProgress, useRevisionId, useAnnotationSet, useAnnotationSets, useComparisonSets, useTrackFilters, useTrackStyleManager, useSelectedCamera, useSelectedKey, useSelectedTrackId, useEditingGroupId, useTime, useVisibleModes, useReadOnlyMode, useImageEnhancements, useAttributesFilters, };
