import { defineComponent, } from '@vue/composition-api';
import TooltipBtn from '../TooltipButton.vue';
export default defineComponent({
    name: 'AttributeNumberFilterSettings',
    props: {
        value: {
            type: Object,
            required: true,
        },
        filterNames: {
            type: Array,
            required: true,
        },
    },
    components: { TooltipBtn },
    setup(props, { emit }) {
        const updateField = (key, val) => {
            emit('input', { ...props.value, [key]: val });
        };
        const typeChange = () => {
            if (props.value) {
                if (props.value.type === 'top') {
                    emit('input', { ...props.value, value: 10, range: [1, 50] });
                }
            }
        };
        return {
            /* methods */
            updateField,
            typeChange,
        };
    },
});
