import { defineComponent } from '@vue/composition-api';
import { provideApi } from 'dive-common/apispec';
import { getPipelineList, runPipeline, getTrainingConfigurations, runTraining, saveMetadata, saveAttributes, saveAttributeTrackFilters, importAnnotationFile, loadDetections, saveDetections, unwrap, getTiles, getTileURL, } from './api';
import { openFromDisk } from './utils';
export default defineComponent({
    name: 'App',
    components: {},
    setup(_, { root }) {
        async function loadMetadata(datasetId) {
            return root.$store.dispatch('Dataset/load', datasetId);
        }
        root.$store.dispatch('Location/setLocationFromRoute', root.$route);
        provideApi({
            getPipelineList: unwrap(getPipelineList),
            runPipeline: unwrap(runPipeline),
            getTrainingConfigurations: unwrap(getTrainingConfigurations),
            runTraining: unwrap(runTraining),
            loadDetections,
            saveDetections: unwrap(saveDetections),
            saveMetadata: unwrap(saveMetadata),
            saveAttributes: unwrap(saveAttributes),
            saveAttributeTrackFilters: unwrap(saveAttributeTrackFilters),
            loadMetadata,
            openFromDisk,
            importAnnotationFile,
            getTiles,
            getTileURL,
        });
    },
});
