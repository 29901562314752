var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"nudge-bottom":28,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.active ? 'accent' : 'default'}},[_vm._v(" mdi-cog ")])],1)]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{staticClass:"pa-2 pr-4",attrs:{"outlined":"","color":"blue-grey darken-3"}},[_vm._v(" Type Settings "),_c('v-row',[_c('v-col',{staticClass:"py-1"},[_c('v-btn',{attrs:{"dense":"","small":"","outlined":"","hide-details":""},on:{"click":function($event){_vm.importDialog = true}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Types ")],1)],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.help.import))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1"},[_c('v-switch',{staticClass:"my-0 ml-1 pt-0",attrs:{"dense":"","label":"Show Empty","hide-details":""},model:{value:(_vm.clientSettings.typeSettings.showEmptyTypes),callback:function ($$v) {_vm.$set(_vm.clientSettings.typeSettings, "showEmptyTypes", $$v)},expression:"clientSettings.typeSettings.showEmptyTypes"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.help.showEmptyTypes))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1"},[_c('v-switch',{staticClass:"my-0 ml-1 pt-0",attrs:{"label":"Lock Types","dense":"","hide-details":""},model:{value:(_vm.clientSettings.typeSettings.lockTypes),callback:function ($$v) {_vm.$set(_vm.clientSettings.typeSettings, "lockTypes", $$v)},expression:"clientSettings.typeSettings.lockTypes"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"2","align":"right"}},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.help.lockTypes))])])],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}},[_c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Types "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.importDialog = false}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.importInstructions)+" "),_c('v-form',[_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","no-resize":"","rows":"10"},model:{value:(_vm.importTypes),callback:function ($$v) {_vm.importTypes=$$v},expression:"importTypes"}})],1)],1)],1),_c('v-alert',{attrs:{"text":"","color":"error"}},[_vm._v(" Note: You will have to check 'View Unused' in the settings to see new empty types ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","text":""},on:{"click":function($event){_vm.importDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.confirmImport}},[_vm._v(" Add ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }