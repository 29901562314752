import { defineComponent, reactive, ref, computed, } from '@vue/composition-api';
import { clientSettings } from 'dive-common/store/settings';
export default defineComponent({
    name: 'TrackSettingsPanel',
    props: {
        allTypes: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const itemHeight = 45; // in pixels
        const help = reactive({
            mode: {
                Track: 'Track Mode - advance a frame while drawing',
                Detection: 'Detection Mode - Used to create multiple detections on a single frame.',
            },
            type: 'Choose a default type for the new Track/Detection to be or type in a new type to add it',
            autoAdvanceFrame: 'After creating a track advance to the next frame.  Hit Esc to exit.',
            interpolate: 'Whether new tracks should have interpolation enabled by default',
            continuous: 'Immediately stay in detection creation mode after creating a new track.  Hit Esc to exit.',
            prompt: 'Prompt user before deleting a track?',
        });
        const modes = ref(['Track', 'Detection']);
        // Add unknown as the default type to the typeList
        const typeList = computed(() => ['unknown'].concat(props.allTypes));
        return {
            clientSettings,
            itemHeight,
            help,
            modes,
            typeList,
        };
    },
});
