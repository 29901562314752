import { ref, computed } from '@vue/composition-api';
import IntervalTree from '@flatten-js/interval-tree';
function isTrack(value) {
    return value.features !== undefined;
}
/**
 * BaseAnnotationStore performs operations on a collection of annotations, such as
 * add and remove.  Operations on individual annotations, such as setting
 * and deleting detections, should be performed directly on the annotation
 * object.  BaseAnnotationStore will observe these changes and react if necessary.
 */
export default class BaseAnnotationStore {
    constructor({ markChangesPending, cameraName }) {
        this.markChangesPending = markChangesPending;
        this.cameraName = cameraName;
        this.annotationMap = new Map();
        this.annotationIds = ref([]);
        this.intervalTree = new IntervalTree();
        this.canary = ref(0);
        this.enableSorting = ref(false);
        this.sorted = computed(() => {
            this.depend();
            // Prevent sorting when loading data
            if (!this.enableSorting.value) {
                return [];
            }
            return this.annotationIds.value
                .map((trackId) => {
                const track = this.get(trackId);
                return {
                    begin: track.begin,
                    end: track.end,
                    id: track.id,
                    confidencePairs: track.confidencePairs,
                    getType: (index) => ((track.confidencePairs[index || 0][0]) || 'unknown'),
                };
            })
                .sort((a, b) => a.begin - b.begin);
        });
    }
    /**
     * By accessing the canary.value, depend sets up a dependency
     * on the notifier, allowing dependants to re-compute.
     *
     * Using Vue reactivity hooks naturally debounces updates to the canary.
     */
    depend() {
        return this.canary.value;
    }
    setEnableSorting() {
        this.enableSorting.value = true;
    }
    get(annotationId) {
        const value = this.annotationMap.get(annotationId);
        if (value === undefined) {
            throw new Error(`Annotation ID ${annotationId} not found in annotationMap.`);
        }
        return value;
    }
    /**
     * Some instances require returning the undefined value for checking purposes
     * That requires setting the error value to false
     */
    getPossible(annotationId) {
        return this.annotationMap.get(annotationId);
    }
    getNewId() {
        if (this.annotationIds.value.length) {
            return this.annotationIds.value.reduce((prev, current) => Math.max(prev, current)) + 1;
        }
        return 0;
    }
    notify({ item, event, oldValue }) {
        if (event === 'bounds') {
            const oldInterval = oldValue;
            this.intervalTree.remove(oldInterval, item.id.toString());
            this.intervalTree.insert([item.begin, item.end], item.id.toString());
        }
        this.canary.value += 1;
        if (isTrack(item)) {
            this.markChangesPending({ action: 'upsert', track: item, cameraName: this.cameraName });
        }
        else {
            this.markChangesPending({ action: 'upsert', group: item, cameraName: this.cameraName });
        }
    }
    insert(value, args) {
        value.setNotifier((params) => {
            this.notify(params);
        });
        this.annotationMap.set(value.id, value);
        this.intervalTree.insert([value.begin, value.end], value.id.toString());
        if (args && args.afterId) {
            /* Insert specifically after another annotationId */
            const insertIndex = this.annotationIds.value.indexOf(args.afterId) + 1;
            this.annotationIds.value.splice(insertIndex, 0, value.id);
        }
        else {
            this.annotationIds.value.push(value.id);
        }
        if (!(args === null || args === void 0 ? void 0 : args.imported)) {
            if (isTrack(value)) {
                this.markChangesPending({ action: 'upsert', track: value, cameraName: this.cameraName });
            }
            else {
                this.markChangesPending({ action: 'upsert', group: value, cameraName: this.cameraName });
            }
        }
    }
    remove(annotationId, disableNotifications = false) {
        const value = this.get(annotationId);
        const range = [value.begin, value.end];
        if (!this.intervalTree.remove(range, annotationId.toString())) {
            throw new Error(`AnnotationId ${annotationId} with range ${range} not found in tree.`);
        }
        value.setNotifier(undefined);
        this.annotationMap.delete(annotationId);
        const listIndex = this.annotationIds.value.findIndex((v) => v === annotationId);
        if (listIndex === -1) {
            throw new Error(`AnnotationId ${annotationId} not found in annotationIds.`);
        }
        this.annotationIds.value.splice(listIndex, 1);
        if (!disableNotifications) {
            if (isTrack(value)) {
                this.markChangesPending({ action: 'delete', track: value, cameraName: this.cameraName });
            }
            else {
                this.markChangesPending({ action: 'delete', group: value, cameraName: this.cameraName });
            }
        }
    }
    clearAll() {
        this.annotationMap.clear();
        this.intervalTree.items.forEach((item) => {
            this.intervalTree.remove(item.key);
        });
        this.annotationIds.value = [];
    }
}
