import { defineComponent, reactive, computed, } from '@vue/composition-api';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { useEditingMode, useHandler, useSelectedTrackId, useTrackFilters, useTime, useReadOnlyMode, useTrackStyleManager, useMultiSelectList, useCameraStore, } from '../provides';
import useVirtualScrollTo from '../use/useVirtualScrollTo';
import TrackItem from './TrackItem.vue';
/* Magic numbers involved in height calculation */
const TrackListHeaderHeight = 52;
export default defineComponent({
    name: 'TrackList',
    props: {
        newTrackMode: {
            type: String,
            required: true,
        },
        newTrackType: {
            type: String,
            required: true,
        },
        lockTypes: {
            type: Boolean,
            default: false,
        },
        hotkeysDisabled: {
            type: Boolean,
            required: true,
        },
        height: {
            type: Number,
            default: 420,
        },
    },
    components: { TrackItem },
    setup(props) {
        const { prompt } = usePrompt();
        const readOnlyMode = useReadOnlyMode();
        const trackFilters = useTrackFilters();
        const allTypesRef = trackFilters.allTypes;
        const checkedTrackIdsRef = trackFilters.checkedIDs;
        const editingModeRef = useEditingMode();
        const selectedTrackIdRef = useSelectedTrackId();
        const cameraStore = useCameraStore();
        const filteredTracksRef = trackFilters.filteredAnnotations;
        const typeStylingRef = useTrackStyleManager().typeStyling;
        const { frame: frameRef } = useTime();
        const multiSelectList = useMultiSelectList();
        const { trackSelectNext, trackSplit, removeTrack, trackAdd, } = useHandler();
        const data = reactive({
            itemHeight: 70,
            settingsActive: false,
        });
        const virtualListItems = computed(() => {
            const selectedTrackId = selectedTrackIdRef.value;
            const multiSelect = multiSelectList.value;
            const checkedTrackIds = checkedTrackIdsRef.value;
            const editingMode = editingModeRef.value;
            const allTypes = allTypesRef.value;
            return filteredTracksRef.value.map((filtered) => ({
                filteredTrack: filtered,
                selectedTrackId,
                checkedTrackIds,
                multiSelect,
                editingTrack: !!editingMode,
                allTypes,
            }));
        });
        const newTrackColor = computed(() => {
            if (props.newTrackType !== 'unknown') {
                return typeStylingRef.value.color(props.newTrackType);
            }
            // Return default color
            return '';
        });
        const getAnnotation = (id) => cameraStore.getAnyPossibleTrack(id);
        const virtualScroll = useVirtualScrollTo({
            itemHeight: data.itemHeight,
            getAnnotation,
            filteredListRef: filteredTracksRef,
            selectedIdRef: selectedTrackIdRef,
            multiSelectList,
            selectNext: trackSelectNext,
        });
        function getItemProps(item) {
            const confidencePair = item.filteredTrack.annotation.getType(item.filteredTrack.context.confidencePairIndex);
            const trackType = confidencePair;
            const selected = item.selectedTrackId === item.filteredTrack.annotation.id;
            const track = cameraStore.getTracksMerged(item.filteredTrack.annotation.id);
            return {
                trackType,
                track,
                inputValue: item.checkedTrackIds.includes(item.filteredTrack.annotation.id),
                selected,
                secondarySelected: item.multiSelect.includes(item.filteredTrack.annotation.id),
                editing: selected && item.editingTrack,
                color: typeStylingRef.value.color(trackType),
                types: item.allTypes,
            };
        }
        async function multiDelete() {
            const tracksDisplayed = [];
            const text = ['Do you want to delete the following tracks:'];
            let count = 0;
            const limit = 20;
            virtualListItems.value.forEach((item) => {
                if (item.checkedTrackIds.includes(item.filteredTrack.annotation.id)) {
                    if (count < limit) {
                        text.push(item.filteredTrack.annotation.id.toString());
                    }
                    tracksDisplayed.push(item.filteredTrack.annotation.id);
                    count += 1;
                }
            });
            if (count >= limit) {
                text.push(`And ${count - limit} more tracks...`);
            }
            const result = await prompt({
                title: 'Confirm',
                text,
                confirm: true,
            });
            if (result) {
                removeTrack(tracksDisplayed, true);
            }
        }
        const mouseTrap = computed(() => {
            const disabled = props.hotkeysDisabled;
            return [
                {
                    bind: 'up',
                    handler: (el, event) => {
                        virtualScroll.scrollPreventDefault(el, event, 'up');
                    },
                    disabled,
                },
                {
                    bind: 'down',
                    handler: (el, event) => {
                        virtualScroll.scrollPreventDefault(el, event, 'down');
                    },
                    disabled,
                },
                {
                    bind: 'del',
                    handler: () => {
                        if (!readOnlyMode.value && selectedTrackIdRef.value !== null) {
                            removeTrack([selectedTrackIdRef.value]);
                        }
                    },
                    disabled,
                },
                {
                    bind: 'x',
                    handler: () => !readOnlyMode.value
                        && trackSplit(selectedTrackIdRef.value, frameRef.value),
                    disabled,
                },
            ];
        });
        const virtualHeight = computed(() => props.height - TrackListHeaderHeight);
        return {
            allTypes: allTypesRef,
            data,
            getItemProps,
            mouseTrap,
            newTrackColor,
            filteredTracks: filteredTracksRef,
            readOnlyMode,
            trackAdd,
            virtualHeight,
            virtualListItems,
            virtualList: virtualScroll.virtualList,
            multiDelete,
        };
    },
});
