import { defineComponent, computed, ref, onBeforeMount, } from '@vue/composition-api';
import { useApi, } from 'dive-common/apispec';
import JobLaunchDialog from 'dive-common/components/JobLaunchDialog.vue';
import { stereoPipelineMarker, multiCamPipelineMarkers, LargeImageType } from 'dive-common/constants';
import { useRequest } from 'dive-common/use';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
export default defineComponent({
    name: 'RunPipelineMenu',
    components: { JobLaunchDialog },
    props: {
        selectedDatasetIds: {
            type: Array,
            default: () => [],
        },
        buttonOptions: {
            type: Object,
            default: () => ({}),
        },
        menuOptions: {
            type: Object,
            default: () => ({}),
        },
        /* Disable pipelines for large-image type */
        typeList: {
            type: Array,
            default: () => ([]),
        },
        /* Which pipelines to show based on dataset subtypes */
        subTypeList: {
            type: Array,
            default: () => ([]),
        },
        /* Which pipelines to show based on how many cameras they accept */
        cameraNumbers: {
            type: Array,
            default: () => ([1]),
        },
        runningPipelines: {
            type: Array,
            default: () => ([]),
        },
        readOnlyMode: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { prompt } = usePrompt();
        const { runPipeline, getPipelineList } = useApi();
        const unsortedPipelines = ref({});
        const selectedPipe = ref(null);
        const camNumberStringArray = computed(() => props.cameraNumbers.map((v) => v.toString()));
        const { request: _runPipelineRequest, reset: dismissLaunchDialog, state: jobState, } = useRequest();
        const includesLargeImage = computed(() => props.typeList.includes(LargeImageType));
        const successMessage = computed(() => {
            var _a;
            return (`Started ${(_a = selectedPipe.value) === null || _a === void 0 ? void 0 : _a.name} on ${props.selectedDatasetIds.length} dataset(s).`);
        });
        onBeforeMount(async () => {
            unsortedPipelines.value = await getPipelineList();
        });
        const pipelines = computed(() => {
            const sortedPipelines = {};
            Object.entries(unsortedPipelines.value).forEach(([name, category]) => {
                category.pipes.sort((a, b) => {
                    const aName = a.name.toLowerCase();
                    const bName = b.name.toLowerCase();
                    if (aName > bName) {
                        return 1;
                    }
                    if (aName < bName) {
                        return -1;
                    }
                    return 0;
                });
                // Filter out unsupported pipelines based on subTypeList
                // measurement can only be operated on stereo subtypes
                if (props.subTypeList.every((item) => item === 'stereo') && (name === stereoPipelineMarker)) {
                    sortedPipelines[name] = category;
                }
                else if (props.subTypeList.every((item) => item === 'multicam') && (multiCamPipelineMarkers.includes(name))) {
                    const pipelineExpectedCameraCount = name.split('-')[0];
                    if (camNumberStringArray.value.includes(pipelineExpectedCameraCount)) {
                        sortedPipelines[name] = category;
                    }
                }
                if (name !== stereoPipelineMarker && !multiCamPipelineMarkers.includes(name)) {
                    sortedPipelines[name] = category;
                }
            });
            return sortedPipelines;
        });
        const pipelinesNotRunnable = computed(() => (props.selectedDatasetIds.length < 1 || pipelines.value === null));
        const pipelinesCurrentlyRunning = computed(() => props.selectedDatasetIds.reduce((acc, item) => acc || props.runningPipelines.includes(item), false));
        const singlePipelineValue = computed(() => {
            if (props.selectedDatasetIds.length === 1) {
                return props.runningPipelines.includes(props.selectedDatasetIds[0]);
            }
            return false;
        });
        async function runPipelineOnSelectedItem(pipeline) {
            if (props.selectedDatasetIds.length === 0) {
                throw new Error('No selected datasets to run on');
            }
            let datasetIds = props.selectedDatasetIds;
            if (props.cameraNumbers.length === 1 && props.cameraNumbers[0] > 1
                && (!multiCamPipelineMarkers.includes(pipeline.type)
                    && stereoPipelineMarker !== pipeline.type)) {
                const cameraNames = props.selectedDatasetIds.map((item) => item.substring(0, item.lastIndexOf('/')));
                const result = await prompt({
                    title: `Running Single Camera Pipeline on ${cameraNames[0]}`,
                    text: ['Running a single pipeline on multi-camera data can produce conflicting track Ids',
                        'Suggest Cancelling and deleting all existing tracks to ensure proper display of the output',
                    ],
                    confirm: true,
                });
                if (!result) {
                    return;
                }
            }
            if (multiCamPipelineMarkers.includes(pipeline.type)
                || stereoPipelineMarker === pipeline.type) {
                datasetIds = props.selectedDatasetIds.map((item) => item.substring(0, item.lastIndexOf('/')));
            }
            selectedPipe.value = pipeline;
            await _runPipelineRequest(() => Promise.all(datasetIds.map((id) => runPipeline(id, pipeline))));
        }
        function pipeTypeDisplay(pipeType) {
            switch (pipeType) {
                case 'trained':
                    return 'trained';
                case 'utility':
                case 'generate':
                    return 'utilities';
                default:
                    return `${pipeType}s`;
            }
        }
        return {
            jobState,
            pipelines,
            pipelinesNotRunnable,
            includesLargeImage,
            successMessage,
            dismissLaunchDialog,
            pipeTypeDisplay,
            runPipelineOnSelectedItem,
            pipelinesCurrentlyRunning,
            singlePipelineValue,
        };
    },
});
