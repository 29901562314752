import { defineComponent, } from '@vue/composition-api';
import TooltipBtn from '../TooltipButton.vue';
export default defineComponent({
    name: 'AttributeKeyFilterSettings',
    props: {
        value: {
            type: Object,
            required: true,
        },
        filterNames: {
            type: Array,
            required: true,
        },
    },
    components: { TooltipBtn },
    setup(props, { emit }) {
        const updateField = (key, val) => {
            emit('input', { ...props.value, [key]: val });
        };
        return {
            /* methods */
            updateField,
        };
    },
});
