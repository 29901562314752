import { defineComponent } from '@vue/composition-api';
import TooltipBtn from 'vue-media-annotator/components/TooltipButton.vue';
export default defineComponent({
    name: 'RangeEditor',
    components: { TooltipBtn },
    props: {
        begin: {
            type: Number,
            required: true,
        },
        end: {
            type: Number,
            required: true,
        },
        last: {
            type: Boolean,
            default: true,
        },
        frame: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: Infinity,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, { emit }) {
        function updateBegin(input) {
            const num = parseInt(input, 10);
            emit('update:begin', num);
        }
        function updateEnd(input) {
            const num = parseInt(input, 10);
            emit('update:end', num);
        }
        return {
            updateBegin,
            updateEnd,
        };
    },
});
