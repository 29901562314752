import { computed, defineComponent, onMounted, ref, watch, } from '@vue/composition-api';
import { cancelJob, deleteJob, getJobTypesStatus, getRecentJobs, } from 'platform/web-girder/api/admin.service';
import JobProgress from '@girder/components/src/components/Job/JobProgress.vue';
import { all, getByValue } from '@girder/components/src/components/Job/status';
import moment from 'moment';
import { isObject } from 'lodash';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { useStore } from 'platform/web-girder/store/types';
const JobStatus = all();
const JobStatusMap = {
    Cancelled: JobStatus.CANCELED,
    Error: JobStatus.ERROR,
    Success: JobStatus.SUCCESS,
    Inactive: JobStatus.INACTIVE,
    Running: JobStatus.RUNNING,
    Cancelling: JobStatus.WORKER_CANCELING,
};
export default defineComponent({
    name: 'AdminJobs',
    components: {
        JobProgress,
    },
    setup() {
        const limit = ref(50);
        const offset = ref(0);
        const store = useStore();
        const { prompt } = usePrompt();
        const table = ref([]);
        const jobTypes = ref([]);
        const jobStatusList = ref(['Cancelled', 'Error', 'Inactive', 'Running', 'Cancelling', 'Success']);
        const filterStatus = ref(['Running', 'Error', 'Inactive']);
        const filterTypes = ref([]);
        const trainingInputList = ref([]);
        const trainingListDialog = ref(false);
        const headers = ref([
            { text: 'Title', value: 'title' },
            { text: 'Type', value: 'type' },
            { text: 'Login', value: 'login' },
            { text: 'User Dir', value: 'userDir' },
            { text: 'Created', value: 'created' },
            { text: 'Updated', value: 'modified' },
            { text: 'Status', value: 'status' },
            { text: 'Info', value: 'params' },
            { text: 'Actions', value: 'actions' },
        ]);
        const initTypes = async () => {
            const typesAndStatus = (await getJobTypesStatus());
            jobTypes.value = typesAndStatus.data.types;
            filterTypes.value = typesAndStatus.data.types;
        };
        const getData = async () => {
            const statusNums = filterStatus.value.map((status) => JobStatusMap[status].value).filter((item) => item !== undefined);
            table.value = (await getRecentJobs(limit.value, offset.value, statusNums, filterTypes.value)).data;
        };
        const data = computed(() => table.value.map((item) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let params = {};
            if (isObject(item.kwargs)) {
                params = item.kwargs;
            }
            else if (typeof (item.kwargs) === 'string') {
                const temp = JSON.parse(item.kwargs);
                if (temp.params !== undefined) {
                    params = temp.params;
                }
            }
            return {
                title: (item === null || item === void 0 ? void 0 : item.title) || 'Unknown',
                type: item.type,
                login: (params === null || params === void 0 ? void 0 : params.user_login) || 'Unknown',
                userDir: (params === null || params === void 0 ? void 0 : params.user_id) || 'Unknown',
                created: moment(item.created).format('MM/DD/YY @ h:mm a'),
                modified: moment(item.updated).format('MM/DD/YY @ h:mm a'),
                status: item.status,
                params,
                actions: item._id,
            };
        }));
        onMounted(async () => {
            await initTypes();
            await getData();
        });
        watch(() => store.getters['Jobs/runningJobIds'], async (prev, current) => {
            if (prev !== current) {
                await getData();
            }
        });
        const formatStatus = (status, updated) => {
            const statusDef = getByValue(status);
            return {
                statusText: statusDef.text,
                statusColor: statusDef.color,
                statusTextColor: 'white',
                statusIcon: statusDef.icon,
                updateString: moment(updated).format('dddd, MMMM D, YYYY @ h:mm a'),
                progressNumber: 100,
                indeterminate: statusDef.indeterminate || statusDef.value === 2,
                class: statusDef.indeterminate || statusDef.value === 2 ? ['mdi-spin'] : undefined,
            };
        };
        const getJobStatusColor = (status) => { var _a; return ((_a = JobStatusMap[status]) === null || _a === void 0 ? void 0 : _a.color) || 'default'; };
        const removeTypeChip = (item) => {
            filterTypes.value.splice(filterTypes.value.indexOf(item), 1);
            filterTypes.value = [...filterTypes.value];
            getData();
        };
        const removeStatusChip = (item) => {
            filterStatus.value.splice(filterStatus.value.indexOf(item), 1);
            filterStatus.value = [...filterStatus.value];
            getData();
        };
        const modifyJob = async (state, jobId, title) => {
            const result = await prompt({
                title: `${state} Job`,
                text: ['Do you want to delete thie following job?', title],
                confirm: true,
            });
            if (!result) {
                return;
            }
            if (state === 'Delete') {
                await deleteJob(jobId);
            }
            else if (state === 'Cancel') {
                await cancelJob(jobId);
            }
            await getData();
        };
        const viewTrainingList = (inputList) => {
            trainingInputList.value = inputList;
            trainingListDialog.value = true;
        };
        return {
            table,
            headers,
            data,
            getData,
            formatStatus,
            jobTypes,
            jobStatusList,
            filterTypes,
            filterStatus,
            removeTypeChip,
            removeStatusChip,
            getJobStatusColor,
            modifyJob,
            // View Training Datasets
            viewTrainingList,
            trainingInputList,
            trainingListDialog,
        };
    },
});
