import { computed, defineComponent } from '@vue/composition-api';
import context from 'dive-common/store/context';
export default defineComponent({
    props: {
        width: {
            type: Number,
            default: 300,
        },
    },
    setup() {
        const options = computed(() => Object.entries(context.componentMap).map(([value, entry]) => ({
            text: entry.description,
            value,
        })));
        return { context, options };
    },
});
