import { defineComponent, ref, } from '@vue/composition-api';
import { useStore } from 'platform/web-girder/store/types';
import { useCameraStore, useTrackStyleManager } from 'vue-media-annotator/provides';
export default defineComponent({
    name: 'AttributeValueColors',
    props: {
        attribute: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        var _a;
        const typeStylingRef = useTrackStyleManager().typeStyling;
        const cameraStore = useCameraStore();
        const store = useStore();
        const user = (((_a = store.state.User.user) === null || _a === void 0 ? void 0 : _a.login) || '');
        const predeterminedValues = ref(props.attribute.values || []);
        const attributeColors = ref(props.attribute.valueColors || {});
        const editingColor = ref(false);
        const currentEditColor = ref('white');
        const currentEditKey = ref(null);
        const getActualValues = () => {
            // Need to go through all tracks with the attribute and get their values.
            const valueMap = {};
            cameraStore.camMap.value.forEach((camera) => {
                camera.trackStore.annotationMap.forEach((track) => {
                    if (props.attribute.belongs === 'track') {
                        if (!props.attribute.user && track.attributes[props.attribute.name]) {
                            valueMap[track.attributes[props.attribute.name]] = true;
                        }
                        else if (props.attribute.user && track.attributes.userAttributes) {
                            const userAttr = (track.attributes.userAttributes[user]);
                            if (userAttr[props.attribute.name]) {
                                valueMap[userAttr[props.attribute.name]] = true;
                            }
                        }
                    }
                    else if (props.attribute.belongs === 'detection') {
                        track.features.forEach((feature) => {
                            if (feature.attributes) {
                                if (!props.attribute.user && feature.attributes[props.attribute.name]) {
                                    valueMap[feature.attributes[props.attribute.name]] = true;
                                }
                                else if (props.attribute.user && feature.attributes.userAttributes) {
                                    const userAttr = (feature.attributes.userAttributes[user]);
                                    if (userAttr[props.attribute.name]) {
                                        valueMap[userAttr[props.attribute.name]] = true;
                                    }
                                }
                            }
                        });
                    }
                });
            });
            const existingValues = Object.keys(attributeColors.value);
            const finalValues = attributeColors.value;
            predeterminedValues.value.forEach((key) => {
                if (!existingValues.includes(key)) {
                    finalValues[key] = typeStylingRef.value.color(key);
                }
            });
            const finalKeys = Object.keys(finalValues);
            Object.keys(valueMap).forEach((key) => {
                if (!finalKeys.includes(key)) {
                    finalValues[key] = typeStylingRef.value.color(key);
                }
            });
            attributeColors.value = finalValues;
        };
        getActualValues();
        const setEditingColor = (key) => {
            editingColor.value = true;
            currentEditKey.value = key;
            currentEditColor.value = attributeColors.value[key];
        };
        const saveEditingColor = () => {
            if (currentEditKey.value !== null) {
                attributeColors.value[currentEditKey.value] = currentEditColor.value;
                currentEditKey.value = null;
                currentEditColor.value = 'white';
                editingColor.value = false;
                emit('save', attributeColors.value);
            }
        };
        return {
            attributeColors,
            editingColor,
            currentEditColor,
            setEditingColor,
            saveEditingColor,
            getActualValues,
        };
    },
});
