import { computed, defineComponent, ref, } from '@vue/composition-api';
import { flatten } from 'lodash';
import { useSelectedTrackId, useEditingMode, useHandler, useTrackFilters, useAttributes, useMultiSelectList, useTime, useReadOnlyMode, useTrackStyleManager, useEditingGroupId, useGroupFilterControls, useCameraStore, useSelectedCamera, } from 'vue-media-annotator/provides';
import TrackItem from 'vue-media-annotator/components/TrackItem.vue';
import TooltipBtn from 'vue-media-annotator/components/TooltipButton.vue';
import TypePicker from 'vue-media-annotator/components/TypePicker.vue';
import RangeEditor from 'vue-media-annotator/components/RangeEditor.vue';
import AttributeInput from 'dive-common/components/Attributes/AttributeInput.vue';
import AttributeEditor from 'dive-common/components/Attributes/AttributeEditor.vue';
import AttributeSubsection from 'dive-common/components/Attributes/AttributesSubsection.vue';
import ConfidenceSubsection from 'dive-common/components/ConfidenceSubsection.vue';
export default defineComponent({
    components: {
        AttributeInput,
        TrackItem,
        AttributeEditor,
        AttributeSubsection,
        ConfidenceSubsection,
        RangeEditor,
        TooltipBtn,
        TypePicker,
    },
    props: {
        lockTypes: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 300,
        },
        hotkeysDisabled: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const readOnlyMode = useReadOnlyMode();
        const attributes = useAttributes();
        const editingAttribute = ref(null);
        const editingError = ref(null);
        const editingModeRef = useEditingMode();
        const typeStylingRef = useTrackStyleManager().typeStyling;
        const allTypesRef = useTrackFilters().allTypes;
        const cameraStore = useCameraStore();
        const multiCam = ref(cameraStore.camMap.value.size > 1);
        const selectedCamera = useSelectedCamera();
        const { allTypes: allGroupTypesRef } = useGroupFilterControls();
        const multiSelectList = useMultiSelectList();
        const multiSelectInProgress = computed(() => multiSelectList.value.length > 0);
        const { trackSelectNext, trackSplit, removeTrack, unstageFromMerge, setAttribute, deleteAttribute, removeGroup, toggleMerge, } = useHandler();
        //Edit/Set single value by clicking
        const editIndividual = ref(null);
        const { frame: frameRef } = useTime();
        const selectedTrackIdRef = useSelectedTrackId();
        const editingGroupIdRef = useEditingGroupId();
        const groupStoreRef = computed(() => { var _a; return (_a = cameraStore.camMap.value.get(selectedCamera.value)) === null || _a === void 0 ? void 0 : _a.groupStore; });
        const editingGroup = computed(() => {
            const editingGroupId = editingGroupIdRef.value;
            if (editingGroupId !== null) {
                if (groupStoreRef.value) {
                    return groupStoreRef.value.get(editingGroupId);
                }
            }
            return null;
        });
        const selectedTrackList = computed(() => {
            if (multiSelectList.value.length > 0) {
                return multiSelectList.value.map((trackId) => cameraStore.getTrack(trackId, selectedCamera.value));
            }
            if (selectedTrackIdRef.value !== null) {
                return [cameraStore.getAnyTrack(selectedTrackIdRef.value)];
            }
            return [];
        });
        function setEditIndividual(attribute) {
            editIndividual.value = attribute;
        }
        function resetEditIndividual(event) {
            // Only reset if not clicking on a v-input object
            if (editIndividual.value) {
                const path = event.composedPath();
                const inputs = ['INPUT', 'SELECT'];
                if (path.find((item) => (item.classList && item.classList.contains('v-input'))
                    || inputs.includes(item.nodeName))) {
                    return;
                }
                editIndividual.value = null;
            }
        }
        async function closeEditor() {
            editingAttribute.value = null;
            editingError.value = null;
        }
        function addAttribute(type) {
            //TS doesn't understand
            const belongs = type.toLowerCase();
            editingAttribute.value = {
                belongs,
                datatype: 'text',
                name: `New${type}Attribute`,
                key: '',
            };
        }
        function editAttribute(attribute) {
            editingAttribute.value = attribute;
        }
        async function saveAttributeHandler({ data, oldAttribute, close }) {
            editingError.value = null;
            if (!oldAttribute && attributes.value.some((attribute) => (attribute.name === data.name
                && attribute.belongs === data.belongs))) {
                editingError.value = 'Attribute with that name exists';
                return;
            }
            try {
                await setAttribute({ data, oldAttribute });
            }
            catch (err) {
                editingError.value = err.message;
            }
            if (!editingError.value && close) {
                closeEditor();
            }
        }
        async function deleteAttributeHandler(data) {
            editingError.value = null;
            try {
                await deleteAttribute({ data });
            }
            catch (err) {
                editingError.value = err.message;
            }
            if (!editingError.value) {
                closeEditor();
            }
        }
        const mouseTrap = computed(() => {
            const disabled = props.hotkeysDisabled;
            return [
                {
                    bind: 'up',
                    handler: () => {
                        trackSelectNext(-1);
                    },
                    disabled,
                },
                {
                    bind: 'down',
                    handler: () => {
                        trackSelectNext(1);
                    },
                    disabled,
                },
                {
                    bind: 'del',
                    handler: () => {
                        if (!readOnlyMode.value && selectedTrackIdRef.value !== null) {
                            removeTrack([selectedTrackIdRef.value]);
                        }
                    },
                    disabled,
                },
                {
                    bind: 'x',
                    handler: () => !readOnlyMode.value
                        && trackSplit(selectedTrackIdRef.value, frameRef.value),
                    disabled,
                },
            ];
        });
        return {
            selectedTrackIdRef,
            editingGroupIdRef,
            editingGroup,
            readOnlyMode,
            multiCam,
            /* Attributes */
            attributes,
            /* Editing */
            editingAttribute,
            saveAttributeHandler,
            deleteAttributeHandler,
            editingError,
            editIndividual,
            frameRef,
            /* Selected */
            selectedTrackList,
            multiSelectList,
            multiSelectInProgress,
            /* Update functions */
            closeEditor,
            editAttribute,
            addAttribute,
            editingModeRef,
            typeStylingRef,
            allTypesRef,
            allGroupTypesRef,
            setEditIndividual,
            resetEditIndividual,
            mouseTrap,
            flatten,
            removeGroup,
            toggleMerge,
            unstageFromMerge,
        };
    },
});
