var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',_vm._b({attrs:{"close-on-content-click":false,"nudge-width":120,"max-width":"280"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"disabled":!_vm.datasetId || _vm.processing}},'v-btn',_vm.buttonOptions,false),Object.assign({}, tooltipOn, menuOn)),[_c('div',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.processing ? 'mdi-spin mdi-sync' : 'mdi-application-import')+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndDown || _vm.buttonOptions.block),expression:"!$vuetify.breakpoint.mdAndDown || buttonOptions.block"}],staticClass:"pl-1"},[_vm._v(" Import ")])],1)])]}}],null,true)},[_c('span',[_vm._v(" Import Annotation Data ")])])]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},'v-menu',_vm.menuOptions,false),[[(_vm.readOnlyMode)?_c('v-card',[_c('v-card-title',[_vm._v(" Read only Mode")]),_c('v-card-text',[_vm._v(" This Dataset is in ReadOnly Mode. You cannot import annotations for this dataset. ")])],1):_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Import Formats ")]),_c('v-card-text',[_vm._v(" Multiple Data types can be imported: "),_c('ul',[_c('li',[_vm._v(" Viame CSV Files ")]),_c('li',[_vm._v(" DIVE Annotation JSON ")]),_c('li',[_vm._v(" DIVE Configuration JSON")]),_c('li',[_vm._v(" KWCOCO JSON files ")])]),_c('a',{attrs:{"href":"https://kitware.github.io/dive/DataFormats/","target":"_blank"}},[_vm._v("Data Format Documentation")])]),_c('v-container',[_c('v-col',[_c('v-row',[_c('v-btn',{attrs:{"depressed":"","block":"","disabled":!_vm.datasetId || _vm.processing},on:{"click":_vm.openUpload}},[_vm._v(" Import ")])],1),(_vm.currentSet !== '')?_c('v-row',{staticClass:"mt-3",attrs:{"dense":""}},[_c('v-combobox',{attrs:{"items":_vm.sets,"chips":"","label":"Annotation Set","outlined":"","small":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"outlined":""}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}],null,false,688174247),model:{value:(_vm.currentSet),callback:function ($$v) {_vm.currentSet=$$v},expression:"currentSet"}})],1):_vm._e(),_c('v-row',[_c('v-checkbox',{attrs:{"input-value":!_vm.additive,"label":"Overwrite"},on:{"change":function($event){_vm.additive = !$event}}})],1),(_vm.additive)?_c('div',[(_vm.additive)?_c('div',{staticClass:"pa-2"},[_vm._v(" Imported annotations will be added to existing annotations. ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[_vm._v(" The types can be modified to have a prepended value for comparison. ")]),_c('v-text-field',{attrs:{"label":"Prepend to types","clearable":""},model:{value:(_vm.additivePrepend),callback:function ($$v) {_vm.additivePrepend=$$v},expression:"additivePrepend"}})],1):_vm._e()],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }