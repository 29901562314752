import { computed, defineComponent, ref, } from '@vue/composition-api';
import { GirderFileManager } from '@girder/components/src';
import useRequest from 'dive-common/use/useRequest';
import { useGirderRest } from 'platform/web-girder/plugins/girder';
import { clone, getDataset } from 'platform/web-girder/api';
export default defineComponent({
    components: { GirderFileManager },
    props: {
        datasetId: {
            // datasetId shoud only be non-null if the dataset is cloneable
            type: String,
            default: null,
        },
        revision: {
            type: Number,
            default: undefined,
        },
        buttonOptions: {
            type: Object,
            default: () => ({}),
        },
        menuOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, { root }) {
        const girderRest = useGirderRest();
        const source = ref(null);
        const open = ref(false);
        const location = ref({
            _modelType: 'user',
            _id: girderRest.user._id,
        });
        const newName = ref('');
        const locationIsFolder = computed(() => (location.value._modelType === 'folder'));
        async function click() {
            if (props.datasetId) {
                source.value = (await getDataset(props.datasetId)).data;
                newName.value = `Clone of ${source.value.name}`;
                if (props.revision) {
                    newName.value = `${newName.value} revision ${props.revision}`;
                }
                open.value = true;
            }
        }
        function setLocation(newLoc) {
            if (!('meta' in newLoc && newLoc.meta.annotate)) {
                location.value = newLoc;
            }
        }
        const { request: _cloneRequest, error: cloneError, loading: cloneLoading } = useRequest();
        const doClone = () => _cloneRequest(async () => {
            if (!props.datasetId) {
                throw new Error('no source dataset');
            }
            const newDataset = await clone({
                folderId: props.datasetId,
                name: newName.value,
                parentFolderId: location.value._id,
                revision: props.revision,
            });
            root.$router.push({ name: 'viewer', params: { id: newDataset.data._id } });
        });
        return {
            cloneError,
            cloneLoading,
            location,
            locationIsFolder,
            newName,
            open,
            source,
            /* methods */
            click,
            doClone,
            setLocation,
        };
    },
});
