import { defineComponent, reactive, ref, } from '@vue/composition-api';
import { clientSettings } from 'dive-common/store/settings';
export default defineComponent({
    name: 'TypeSettingsPanel',
    props: {
        allTypes: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const itemHeight = 45; // in pixels
        const help = reactive({
            import: 'Import multiple Types',
            showEmptyTypes: 'View types that are not used currently.',
            lockTypes: 'Only allows the use of defined types.',
        });
        const importInstructions = ref('Please provide a list of types (separated by a new line) that you would like to import');
        const importDialog = ref(false);
        const importTypes = ref('');
        const active = ref(false);
        const confirmImport = () => {
            // Go through the importTypes and create types for importing
            const types = importTypes.value.split('\n').filter((item) => item.length);
            emit('import-types', types);
            importDialog.value = false;
            importTypes.value = '';
        };
        return {
            active,
            clientSettings,
            itemHeight,
            help,
            importInstructions,
            importDialog,
            importTypes,
            confirmImport,
        };
    },
});
