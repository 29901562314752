import { defineComponent, ref } from '@vue/composition-api';
import * as d3 from 'd3';
import { generateColors } from 'vue-media-annotator/StyleManager';
import { itemsPerPageOptions } from 'dive-common/constants';
import { clientSettings } from 'dive-common/store/settings';
import { getLabels } from '../api';
export default defineComponent({
    setup() {
        const summaryList = ref();
        const expanded = ref();
        const headers = [
            { text: 'Type', value: '_id' },
            { text: 'Number of Datasets', value: 'datasets.length' },
            { text: 'Number of Tracks', value: 'count' },
            { text: '', value: 'data-table-expand' },
        ];
        const labelColors = generateColors(10);
        const ordinalColorMapper = d3.scaleOrdinal().range(labelColors);
        const color = (label) => ordinalColorMapper(label);
        const updateList = async () => {
            const response = await getLabels();
            summaryList.value = response.data;
            summaryList.value.forEach((item) => {
                Object.keys(item.datasets).forEach((key) => {
                    // eslint-disable-next-line no-param-reassign
                    item.datasets[key].color = color((item.datasets[key].name));
                });
            });
        };
        updateList();
        return {
            expanded,
            summaryList,
            headers,
            clientSettings,
            itemsPerPageOptions,
        };
    },
});
