import { defineComponent } from '@vue/composition-api';
export const DefaultButtonAttrs = {
    block: true,
    color: 'primary',
    class: ['grow'],
};
export default defineComponent({
    name: 'ImportMultiCamAddType',
    props: {
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        openType: {
            type: String,
            required: true,
        },
        multiCamImport: {
            type: Boolean,
            default: false,
        },
        buttonAttrs: {
            type: Object,
            default: () => DefaultButtonAttrs,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {};
    },
});
