var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Recent Jobs ")]),_c('v-card-text',[_c('v-row',[_c('v-combobox',{attrs:{"items":_vm.jobTypes,"chips":"","label":"Job Types","multiple":"","outlined":""},on:{"change":_vm.getData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeTypeChip(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.filterTypes),callback:function ($$v) {_vm.filterTypes=$$v},expression:"filterTypes"}})],1),_c('v-row',[_c('v-combobox',{attrs:{"items":_vm.jobStatusList,"chips":"","label":"Job Statuses","multiple":"","outlined":""},on:{"change":_vm.getData},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":_vm.getJobStatusColor(item),"close":""},on:{"click":select,"click:close":function($event){return _vm.removeStatusChip(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"Title","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.userDir",fn:function(ref){
var item = ref.item;
return [(item.userDir === 'Unknown')?_c('div',[_vm._v(" Unknown ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","depressed":"","to":("/user/" + (item.userDir)),"color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account ")])],1)]}}],null,true)},[_c('span',[_vm._v("Launch User Directory")])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('JobProgress',{attrs:{"formatted-job":_vm.formatStatus(item.status)}})]}},{key:"item.params",fn:function(ref){
var item = ref.item;
return [(item.type === 'pipelines')?_c('div',[(item.params.input_folder)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"x-small":"","depressed":"","to":{ name: 'viewer', params: { id: item.params.input_folder } },"color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Launch dataset viewer")])])],1):_vm._e()]):_vm._e(),(item.type === 'training')?_c('div',[(item.params.dataset_input_list)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0",attrs:{"x-small":"","depressed":"","color":"info"},on:{"click":function($event){return _vm.viewTrainingList(item.params.dataset_input_list)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Training List")])])],1):_vm._e()]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","depressed":"","href":("/girder/#job/" + (item.actions)),"color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-text-box-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("View job logs and manage job")])]),(item.status < 3 )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","depressed":"","color":"warning"},on:{"click":function($event){return _vm.modifyJob('Cancel', item.actions, item.title)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancel Job")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"x-small":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.modifyJob('Delete', item.actions, item.title)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Job")])])]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"250","color":"blue-grey darken-4"},model:{value:(_vm.trainingListDialog),callback:function ($$v) {_vm.trainingListDialog=$$v},expression:"trainingListDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Training Datasets ")]),_c('v-card-text',_vm._l((_vm.trainingInputList),function(item,index){return _c('v-row',{key:item[0],staticClass:"my-2 mx-auto"},[_c('v-col',[_c('span',[_vm._v(" Training Dataset # "+_vm._s(index)+" ")])]),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"depressed":"","small":"","to":{ name: 'viewer', params: { id: item[0] } },"color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Launch dataset viewer")])])],1)],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"default","depressed":""},on:{"click":function($event){_vm.trainingListDialog = false}}},[_vm._v(" Dismiss ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }