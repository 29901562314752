import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
    name: 'ImportMultiCamAddType',
    props: {
        nameList: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const newSetName = ref('');
        const alphaNumeric = /^[a-zA-Z0-9]*$/;
        const enabled = ref(false);
        const addNewSet = () => {
            emit('add-new', newSetName.value);
            newSetName.value = '';
            enabled.value = false;
        };
        return {
            enabled,
            newSetName,
            alphaNumeric,
            /* methods */
            addNewSet,
        };
    },
});
