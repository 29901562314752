var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticStyle:{"position":"absolute","bottom":"0px","padding":"0px","margin":"0px"},attrs:{"dense":""}},[_c('Controls',{scopedSlots:_vm._u([{key:"middle",fn:function(){return [(_vm.datasetType === 'image-sequence' || _vm.datasetType === 'large-image')?_c('file-name-time-display',{staticClass:"text-middle px-3",attrs:{"display-type":"filename"}}):(_vm.datasetType === 'video')?_c('span',[_c('span',{staticClass:"mr-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","offset-y":"","nudge-left":"3","open-on-hover":"","close-delay":"500","open-delay":"250","rounded":"pill"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){(!_vm.volume && _vm.setVolume(1)) || (_vm.volume && _vm.setVolume(0))}}},on),[_vm._v(" "+_vm._s(_vm.volume === 0 ? 'mdi-volume-off' :'mdi-volume-medium')+" ")])]}}])},[_c('v-card',{staticStyle:{"overflow":"hidden","width":"30px"}},[_c('v-slider',{attrs:{"value":_vm.volume,"min":"0","max":"1.0","step":"0.05","vertical":""},on:{"change":_vm.setVolume}})],1)],1)],1),_c('span',{staticClass:"mr-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","offset-y":"","nudge-left":"3","open-on-hover":"","close-delay":"500","open-delay":"250","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"value":_vm.speed != 1.0,"color":"#0277bd88","content":(_vm.speed + "X"),"offset-y":"5px","overlap":""}},[_c('v-icon',_vm._g({on:{"click":function($event){return _vm.setSpeed(1)}}},on),[_vm._v(" mdi-speedometer ")])],1)]}}])},[_c('v-card',{staticStyle:{"overflow":"hidden","width":"90px"}},[_c('v-slider',{staticStyle:{"font-size":"0.75em"},attrs:{"value":_vm.ticks.indexOf(_vm.speed),"min":"0","max":"6","step":"1","tick-labels":_vm.ticks,"ticks":"always","tick-size":4,"vertical":""},on:{"change":function($event){return _vm.setSpeed(_vm.ticks[$event])}}})],1)],1)],1),_c('file-name-time-display',{staticClass:"text-middle pl-2",attrs:{"display-type":"time"}})],1):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"small":""}},on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(" annotation framerate may be downsampled. "),_c('br'),_vm._v(" frame numbers start at zero. ")])])]},proxy:true}])},[_c('template',{slot:"timelineControls"},[_c('div',{staticStyle:{"min-width":"270px"}},[_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.$emit('update:collapsed', !_vm.collapsed)}}},on),[_vm._v(" "+_vm._s(_vm.collapsed?'mdi-chevron-up-box': 'mdi-chevron-down-box')+" ")])]}}])},[_c('span',[_vm._v("Collapse/Expand Timeline")])]),_c('v-btn',{staticClass:"ml-1",class:{'timeline-button':_vm.currentView!=='Detections' || _vm.collapsed},attrs:{"depressed":"","outlined":_vm.currentView==='Detections' && !_vm.collapsed,"x-small":"","tab-index":"-1"},on:{"click":function($event){return _vm.toggleView('Detections')}}},[_vm._v(" Detections ")]),_c('v-btn',{staticClass:"ml-1",class:{'timeline-button':_vm.currentView!=='Events' || _vm.collapsed},attrs:{"depressed":"","outlined":_vm.currentView==='Events' && !_vm.collapsed,"x-small":"","tab-index":"-1"},on:{"click":function($event){return _vm.toggleView('Events')}}},[_vm._v(" Events ")]),(!_vm.multiCam && _vm.hasGroups)?_c('v-btn',{staticClass:"ml-1",class:{'timeline-button':_vm.currentView!=='Groups' || _vm.collapsed},attrs:{"depressed":"","outlined":_vm.currentView==='Groups' && !_vm.collapsed,"x-small":"","tab-index":"-1"},on:{"click":function($event){return _vm.toggleView('Groups')}}},[_vm._v(" Groups ")]):_vm._e(),(!_vm.multiCam && _vm.timelineEnabled)?_c('v-btn',{staticClass:"ml-1",class:{'timeline-button':_vm.currentView!=='Attributes' || _vm.collapsed},attrs:{"depressed":"","outlined":_vm.currentView==='Attributes' && !_vm.collapsed,"x-small":"","tab-index":"-1"},on:{"click":function($event){return _vm.toggleView('Attributes')}}},[_vm._v(" Attributes ")]):_vm._e()],1)])],2),((!_vm.collapsed))?_c('Timeline',{attrs:{"max-frame":_vm.maxFrame,"frame":_vm.frame,"display":!_vm.collapsed},on:{"seek":_vm.seek},scopedSlots:_vm._u([{key:"child",fn:function(ref){
      var startFrame = ref.startFrame;
      var endFrame = ref.endFrame;
      var childMaxFrame = ref.maxFrame;
      var clientWidth = ref.clientWidth;
      var clientHeight = ref.clientHeight;
      var margin = ref.margin;
return [(_vm.currentView==='Detections')?_c('line-chart',{attrs:{"start-frame":startFrame,"end-frame":endFrame,"max-frame":childMaxFrame,"data":_vm.lineChartData,"client-width":clientWidth,"client-height":clientHeight,"margin":margin}}):_vm._e(),(_vm.currentView==='Events')?_c('event-chart',{attrs:{"start-frame":startFrame,"end-frame":endFrame,"max-frame":childMaxFrame,"data":_vm.eventChartData,"client-width":clientWidth,"margin":margin},on:{"select-track":function($event){return _vm.$emit('select-track', $event)}}}):_vm._e(),(_vm.currentView==='Groups')?_c('event-chart',{attrs:{"start-frame":startFrame,"end-frame":endFrame,"max-frame":childMaxFrame,"data":_vm.groupChartData,"client-width":clientWidth,"margin":margin},on:{"select-track":function($event){return _vm.$emit('select-group', $event)}}}):_vm._e(),(_vm.currentView==='Attributes')?_c('line-chart',{attrs:{"start-frame":startFrame,"end-frame":endFrame,"max-frame":endFrame,"data":_vm.attributeData.data,"client-width":clientWidth,"client-height":clientHeight,"margin":margin,"atrributes-chart":true}}):_vm._e()]}}],null,false,2179703025)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }