import { defineComponent, ref, toRef } from '@vue/composition-api';
export default defineComponent({
    name: 'ViewerAlert',
    setup(_, { root }) {
        const dialog = ref(false);
        const brandData = toRef(root.$store.state.Brand, 'brandData');
        return {
            dialog,
            brandData,
        };
    },
});
