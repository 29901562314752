import { computed, defineComponent, ref } from '@vue/composition-api';
import { useApi } from 'dive-common/apispec';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import { cloneDeep } from 'lodash';
import { useAnnotationSets, useAnnotationSet, useHandler } from 'vue-media-annotator/provides';
import { getResponseError } from 'vue-media-annotator/utils';
export default defineComponent({
    name: 'ImportAnnotations',
    props: {
        datasetId: {
            type: String,
            default: null,
        },
        blockOnUnsaved: {
            type: Boolean,
            default: false,
        },
        buttonOptions: {
            type: Object,
            default: () => ({}),
        },
        menuOptions: {
            type: Object,
            default: () => ({}),
        },
        readOnlyMode: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { openFromDisk, importAnnotationFile } = useApi();
        const { reloadAnnotations } = useHandler();
        const sets = computed(() => {
            const data = useAnnotationSets();
            const temp = cloneDeep(data.value);
            temp.push('default');
            return temp;
        });
        const defaultSet = useAnnotationSet();
        const currentSet = ref(defaultSet || 'default');
        const { prompt } = usePrompt();
        const processing = ref(false);
        const menuOpen = ref(false);
        const additive = ref(false);
        const additivePrepend = ref('');
        const openUpload = async () => {
            var _a;
            try {
                const ret = await openFromDisk('annotation');
                if (!ret.canceled) {
                    menuOpen.value = false;
                    const path = ret.filePaths[0];
                    let importFile = false;
                    processing.value = true;
                    const set = currentSet.value === 'default' ? undefined : currentSet.value;
                    if ((_a = ret.fileList) === null || _a === void 0 ? void 0 : _a.length) {
                        importFile = await importAnnotationFile(props.datasetId, path, ret.fileList[0], additive.value, additivePrepend.value, set);
                    }
                    else {
                        importFile = await importAnnotationFile(props.datasetId, path, undefined, additive.value, additivePrepend.value, set);
                    }
                    if (importFile) {
                        processing.value = false;
                        await reloadAnnotations();
                    }
                }
            }
            catch (error) {
                const text = [getResponseError(error)];
                prompt({
                    title: 'Import Failed',
                    text,
                    positiveButton: 'OK',
                });
                processing.value = false;
            }
        };
        return {
            openUpload,
            processing,
            menuOpen,
            additive,
            additivePrepend,
            sets,
            currentSet,
        };
    },
});
