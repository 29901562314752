import { defineComponent, reactive, toRef, watch, } from '@vue/composition-api';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import TrackFilterControls from '../TrackFilterControls';
import { useCameraStore, useReadOnlyMode } from '../provides';
export default defineComponent({
    name: 'TypeEditor',
    props: {
        selectedType: {
            type: String,
            required: true,
        },
        filterControls: {
            type: Object,
            required: true,
        },
        styleManager: {
            type: Object,
            required: true,
        },
        group: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const typeStylingRef = props.styleManager.typeStyling;
        const trackFilters = props.filterControls;
        const usedTypesRef = trackFilters.usedTypes;
        const readOnlyMode = useReadOnlyMode();
        const cameraStore = useCameraStore();
        const { prompt } = usePrompt();
        const data = reactive({
            selectedColor: '',
            selectedType: '',
            editingType: '',
            editingColor: '',
            editingThickness: 5,
            editingFill: false,
            editingOpacity: 1.0,
            editingShowLabel: true,
            editingShowConfidence: true,
            valid: true,
        });
        function acceptChanges() {
            if (data.editingType !== data.selectedType) {
                const updatedTypeObj = {
                    currentType: data.selectedType,
                    newType: data.editingType,
                };
                trackFilters.updateTypeName(updatedTypeObj);
                if (trackFilters instanceof TrackFilterControls) {
                    cameraStore.changeTrackTypes(updatedTypeObj);
                }
            }
            props.styleManager.updateTypeStyle({
                type: data.editingType,
                value: {
                    color: data.editingColor,
                    strokeWidth: data.editingThickness,
                    fill: data.editingFill,
                    opacity: data.editingOpacity,
                    showLabel: data.editingShowLabel,
                    showConfidence: data.editingShowConfidence,
                },
            });
            emit('close');
        }
        async function clickDeleteType(type) {
            const text = `Do you want to delete this empty Type: ${type}`;
            const result = await prompt({
                title: 'Confirm',
                text,
                confirm: true,
            });
            if (result) {
                trackFilters.deleteType(type);
                emit('close');
            }
        }
        function init() {
            data.selectedType = props.selectedType;
            data.editingType = props.selectedType;
            data.editingColor = typeStylingRef.value.color(props.selectedType);
            data.editingThickness = typeStylingRef.value.strokeWidth(props.selectedType);
            data.editingFill = typeStylingRef.value.fill(props.selectedType);
            data.editingOpacity = typeStylingRef.value.opacity(props.selectedType);
            const labelSettings = typeStylingRef.value.labelSettings(props.selectedType);
            data.editingShowConfidence = labelSettings.showConfidence;
            data.editingShowLabel = labelSettings.showLabel;
        }
        watch(toRef(props, 'selectedType'), init);
        init();
        return {
            data,
            usedTypesRef,
            readOnlyMode,
            acceptChanges,
            clickDeleteType,
        };
    },
});
