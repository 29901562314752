import { defineComponent, ref, toRef, watch, } from '@vue/composition-api';
import { GirderJobList } from '@girder/components/src';
import { setUsePrivateQueue } from 'platform/web-girder/api';
import { useGirderRest } from 'platform/web-girder/plugins/girder';
import { useStore } from '../store/types';
export default defineComponent({
    name: 'Jobs',
    components: { GirderJobList },
    setup() {
        const privateQueueEnabled = ref(false);
        const loading = ref(true);
        const restClient = useGirderRest();
        const store = useStore();
        const outstandingJobs = ref(0);
        watch(toRef(store.getters, 'Jobs/runningJobIds'), () => {
            restClient.get('job/queued').then(({ data }) => {
                outstandingJobs.value = data.outstanding;
            });
        });
        async function setPrivateQueueEnabled(value) {
            loading.value = true;
            const resp = await setUsePrivateQueue(restClient.user._id, value);
            privateQueueEnabled.value = resp.data.user_private_queue_enabled;
            loading.value = false;
        }
        restClient.fetchUser()
            .then((user) => {
            privateQueueEnabled.value = user.user_private_queue_enabled;
            loading.value = false;
        });
        restClient.get('job/queued').then(({ data }) => {
            outstandingJobs.value = data.outstanding;
        });
        return {
            privateQueueEnabled,
            loading,
            outstandingJobs,
            /* methods */
            setPrivateQueueEnabled,
        };
    },
});
