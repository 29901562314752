import { defineComponent, } from '@vue/composition-api';
/*
  This Component will be mounted indepedently of the main Vue App
  on a GeoJS canvas element.  To ensure reactivity between the main Vue App
  and this element the props are passed in the initalization function instead of on a template.
  This is why reactivate data in this component is utilizing PropType<Ref<data>>.
  All references to reactive PropType<Ref<data>> need to be derefernced in the template as well.
 */
export default defineComponent({
    name: 'ToolTipWidget',
    props: {
        color: {
            type: Function,
            required: true,
        },
        stateStyling: {
            type: Object,
            required: true,
        },
        textSettings: {
            type: Function,
            default: () => ({ showLabel: true, showConfidence: true }),
        },
        dataList: {
            type: Object,
            default: () => [],
        },
        selected: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const coloring = (data) => {
            if (data.trackId === props.selected.value) {
                return props.stateStyling.selected.color;
            }
            return props.color(data.type);
        };
        return {
            coloring,
        };
    },
});
