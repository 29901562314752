import Vue from 'vue';
export default Vue.extend({
    name: 'DeleteControls',
    props: {
        selectedFeatureHandle: {
            type: Number,
            required: true,
        },
        editingMode: {
            type: [String, Boolean],
            required: true,
        },
    },
    computed: {
        disabled() {
            if (this.selectedFeatureHandle < 0 && this.editingMode === false) {
                return true;
            }
            if (this.editingMode === 'rectangle') {
                return true; // deleting rectangle is unsupported
            }
            return false;
        },
    },
    methods: {
        deleteSelected() {
            if (this.disabled) {
                throw new Error('Cannot delete while disabled!');
            }
            if (this.selectedFeatureHandle >= 0) {
                this.$emit('delete-point');
            }
            else {
                this.$emit('delete-annotation');
            }
        },
    },
});
