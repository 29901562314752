import { computed, defineComponent } from '@vue/composition-api';
import TooltipBtn from './TooltipButton.vue';
/* Horizontal padding is the width of checkbox, scrollbar, and edit button */
const HorizontalPadding = 42 + 14 + 20;
export default defineComponent({
    name: 'TypeItem',
    components: { TooltipBtn },
    props: {
        type: {
            type: String,
            required: true,
        },
        displayText: {
            type: String,
            required: true,
        },
        confidenceFilterNum: {
            type: Number,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            required: true,
        },
        width: {
            type: Number,
            default: 300,
        },
    },
    setup: (props) => ({
        cssVars: computed(() => ({ '--content-width': `${props.width - HorizontalPadding}px` })),
    }),
});
