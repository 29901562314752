import { flatten } from 'lodash';
import Vue from 'vue';
export default Vue.extend({
    name: 'EditorMenu',
    props: {
        editingTrack: {
            type: Boolean,
            required: true,
        },
        visibleModes: {
            type: Array,
            required: true,
        },
        editingMode: {
            type: [String, Boolean],
            required: true,
        },
        editingDetails: {
            type: String,
            required: true,
        },
        recipes: {
            type: Array,
            required: true,
        },
        multiSelectActive: {
            type: Boolean,
            default: false,
        },
        groupEditActive: {
            type: Boolean,
            default: false,
        },
        tailSettings: {
            type: Object,
            default: () => ({ before: 20, after: 10 }),
        },
    },
    data() {
        return {
            toolTipForce: false,
            toolTimeTimeout: 0,
            modeToolTips: {
                Creating: {
                    rectangle: 'Drag to draw rectangle. Press ESC to exit.',
                    Polygon: 'Click to place vertices. Right click to close.',
                    LineString: 'Click to place head/tail points.',
                },
                Editing: {
                    rectangle: 'Drag vertices to resize the rectangle',
                    Polygon: 'Drag midpoints to create new vertices. Click vertices to select for deletion.',
                    LineString: 'Click endpoints to select for deletion.',
                },
            },
        };
    },
    computed: {
        editButtons() {
            const em = this.editingMode;
            return [
                {
                    id: 'rectangle',
                    icon: 'mdi-vector-square',
                    active: this.editingTrack && em === 'rectangle',
                    mousetrap: [{
                            bind: '1',
                            handler: () => {
                                this.$emit('set-annotation-state', { editing: 'rectangle' });
                            },
                        }],
                    click: () => {
                        this.$emit('set-annotation-state', { editing: 'rectangle' });
                    },
                },
                /* Include recipes as editing modes if they're toggleable */
                ...this.recipes.filter((r) => r.toggleable.value).map((r, i) => ({
                    id: r.name,
                    icon: r.icon.value || 'mdi-pencil',
                    active: this.editingTrack && r.active.value,
                    click: () => r.activate(),
                    mousetrap: [
                        {
                            bind: (i + 2).toString(),
                            handler: () => r.activate(),
                        },
                        ...r.mousetrap(),
                    ],
                })),
            ];
        },
        viewButtons() {
            /* Only geometry primitives can be visible types right now */
            return [
                {
                    id: 'rectangle',
                    type: 'rectangle',
                    icon: 'mdi-vector-square',
                    active: this.isVisible('rectangle'),
                    click: () => this.toggleVisible('rectangle'),
                },
                {
                    id: 'Polygon',
                    type: 'Polygon',
                    icon: 'mdi-vector-polygon',
                    active: this.isVisible('Polygon'),
                    click: () => this.toggleVisible('Polygon'),
                },
                {
                    id: 'LineString',
                    type: 'LineString',
                    active: this.isVisible('LineString'),
                    icon: 'mdi-vector-line',
                    click: () => this.toggleVisible('LineString'),
                },
                {
                    id: 'text',
                    type: 'text',
                    active: this.isVisible('text'),
                    icon: 'mdi-format-text',
                    click: () => this.toggleVisible('text'),
                },
                {
                    id: 'tooltip',
                    type: 'tooltip',
                    active: this.isVisible('tooltip'),
                    icon: 'mdi-tooltip-text-outline',
                    click: () => this.toggleVisible('tooltip'),
                },
            ];
        },
        mousetrap() {
            return flatten(this.editButtons.map((b) => b.mousetrap || []));
        },
        editingHeader() {
            if (this.groupEditActive) {
                return { text: 'Group Edit Mode', icon: 'mdi-group', color: 'primary' };
            }
            if (this.multiSelectActive) {
                return { text: 'Multi-select Mode', icon: 'mdi-call-merge', color: 'error' };
            }
            if (this.editingDetails !== 'disabled') {
                return {
                    text: `${this.editingDetails} ${this.editingMode} `,
                    icon: this.editingDetails === 'Creating' ? 'mdi-pencil-plus' : 'mdi-pencil',
                    color: this.editingDetails === 'Creating' ? 'success' : 'primary',
                };
            }
            return { text: 'Not editing', icon: 'mdi-pencil-off-outline', color: '' };
        },
    },
    watch: {
        editingDetails() {
            clearTimeout(this.toolTimeTimeout);
            if (this.editingDetails !== 'disabled') {
                this.toolTipForce = true;
                this.toolTimeTimeout = setTimeout(() => { this.toolTipForce = false; }, 2000);
            }
            else {
                this.toolTipForce = false;
            }
        },
    },
    methods: {
        isVisible(mode) {
            return this.visibleModes.includes(mode);
        },
        toggleVisible(mode) {
            if (this.isVisible(mode)) {
                this.$emit('set-annotation-state', {
                    visible: this.visibleModes.filter((m) => m !== mode),
                });
            }
            else {
                this.$emit('set-annotation-state', {
                    visible: this.visibleModes.concat([mode]),
                });
            }
        },
    },
});
