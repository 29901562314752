import { defineComponent, ref, watch, onMounted, } from '@vue/composition-api';
import * as d3 from 'd3';
export default defineComponent({
    name: 'AttributeNumberValueColors',
    props: {
        attribute: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const attributeColors = ref([]);
        const recalculateGradient = () => {
            const linearGradient = d3.select('linearGradient');
            const domain = attributeColors.value.map((item) => item.key);
            const colorScale = d3.scaleLinear()
                .domain(domain)
                // D3 allows color strings but says it requires numbers for type definitions
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .range(attributeColors.value.map((item) => item.val));
            // Recalculate percentage of width for gradient
            const max = domain[domain.length - 1];
            const percent = domain.map((item) => (max === 0 ? 0 : (item / max)));
            // Append multiple color stops using data/enter step
            linearGradient.selectAll('stop').remove();
            linearGradient.selectAll('stop')
                .data(colorScale.range())
                .enter().append('stop')
                .attr('offset', (d, i) => percent[i])
                .attr('stop-color', (d) => d);
        };
        const updateAttributes = () => {
            const output = [];
            const base = props.attribute.valueColors || {};
            Object.entries(base).forEach(([key, val]) => {
                output.push({ key: parseFloat(key), val });
            });
            output.sort((a, b) => a.key - b.key);
            attributeColors.value = output;
            if (output.length) {
                recalculateGradient();
            }
        };
        onMounted(() => updateAttributes());
        watch(() => props.attribute.valueColors, () => updateAttributes());
        const editingColor = ref(false);
        const currentEditColor = ref('#FF0000');
        const currentEditIndex = ref(0);
        const currentEditKey = ref(0);
        const setEditingColor = (index) => {
            editingColor.value = true;
            currentEditIndex.value = index;
            if (index < attributeColors.value.length) {
                currentEditKey.value = attributeColors.value[index].key;
                currentEditColor.value = attributeColors.value[index].val;
            }
            else {
                currentEditKey.value = !attributeColors.value.length ? 0 : attributeColors.value[attributeColors.value.length - 1].key + 1;
                currentEditColor.value = '#FF0000';
            }
        };
        const saveEditingColor = () => {
            if (currentEditIndex.value !== null) {
                const mapper = {};
                if (!attributeColors.value[currentEditIndex.value]) {
                    attributeColors.value.push({ key: currentEditKey.value, val: currentEditColor.value });
                }
                else {
                    attributeColors.value[currentEditIndex.value] = { key: currentEditKey.value, val: currentEditColor.value };
                }
                attributeColors.value.sort((a, b) => a.key - b.key);
                attributeColors.value.forEach((item) => {
                    mapper[item.key] = item.val;
                });
                currentEditIndex.value = 0;
                currentEditColor.value = '#FF0000';
                editingColor.value = false;
                emit('save', mapper);
                if (attributeColors.value.length) {
                    recalculateGradient();
                }
            }
        };
        const addColor = () => {
            setEditingColor(attributeColors.value.length);
        };
        const gradientSVG = ref(null);
        watch(gradientSVG, () => {
            const svg = d3.select('#gradient-image');
            svg
                .append('defs')
                .append('linearGradient')
                .attr('id', 'color-gradient')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%');
            svg.append('rect')
                .attr('width', 300)
                .attr('height', 30)
                .style('fill', 'url(#color-gradient)');
            if (attributeColors.value.length) {
                recalculateGradient();
            }
        });
        const deleteGradient = (index) => {
            attributeColors.value.splice(index, 1);
            attributeColors.value.sort((a, b) => a.key - b.key);
            const mapper = {};
            attributeColors.value.forEach((item) => {
                mapper[item.key] = item.val;
            });
            currentEditIndex.value = 0;
            currentEditColor.value = 'white';
            editingColor.value = false;
            emit('save', mapper);
            if (attributeColors.value.length) {
                recalculateGradient();
            }
        };
        const validForm = ref(false);
        return {
            attributeColors,
            editingColor,
            currentEditColor,
            currentEditIndex,
            currentEditKey,
            gradientSVG,
            validForm,
            setEditingColor,
            saveEditingColor,
            addColor,
            deleteGradient,
        };
    },
});
