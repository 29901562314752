import { defineComponent, computed, ref, } from '@vue/composition-api';
import context from 'dive-common/store/context';
import TooltipBtn from './TooltipButton.vue';
import TypePicker from './TypePicker.vue';
import { useHandler, useTime, useReadOnlyMode, useTrackFilters, useCameraStore, useTrackStyleManager, } from '../provides';
export default defineComponent({
    name: 'TrackItem',
    components: { TooltipBtn, TypePicker },
    props: {
        solo: {
            type: Boolean,
            default: false,
        },
        trackType: {
            type: String,
            required: true,
        },
        track: {
            type: Object,
            required: true,
        },
        inputValue: {
            type: Boolean,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
        secondarySelected: {
            type: Boolean,
            required: true,
        },
        editing: {
            type: Boolean,
            required: true,
        },
        merging: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            required: true,
        },
        lockTypes: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root, emit }) {
        const vuetify = root.$vuetify;
        const { frame: frameRef } = useTime();
        const handler = useHandler();
        const trackFilters = useTrackFilters();
        const allTypesRef = trackFilters.allTypes;
        const readOnlyMode = useReadOnlyMode();
        const cameraStore = useCameraStore();
        const { typeStyling } = useTrackStyleManager();
        const multiCam = ref(cameraStore.camMap.value.size > 1);
        /**
         * Use of revision is safe because it will only create a
         * dependency when track is selected.  DO NOT use this computed
         * value except inside if (props.selected === true) blocks!
         */
        const feature = computed(() => {
            if (props.track.revision.value) {
                const { features, interpolate } = props.track.canInterpolate(frameRef.value);
                const [real, lower, upper] = features;
                return {
                    real,
                    lower,
                    upper,
                    shouldInterpolate: interpolate,
                    targetKeyframe: (real === null || real === void 0 ? void 0 : real.keyframe) ? real : (lower || upper),
                    isKeyframe: real === null || real === void 0 ? void 0 : real.keyframe,
                };
            }
            return {
                real: null,
                lower: null,
                upper: null,
                targetKeyframe: null,
                shouldInterpolate: false,
                isKeyframe: false,
            };
        });
        /* isTrack distinguishes between track and detection */
        const isTrack = computed(() => props.track.length > 1 || feature.value.shouldInterpolate);
        /* Sets styling for the selected track */
        const style = computed(() => {
            if (props.selected) {
                return {
                    'background-color': `${vuetify.theme.themes.dark.accentBackground}`,
                };
            }
            if (props.secondarySelected) {
                return {
                    'background-color': '#3a3a3a',
                };
            }
            return {};
        });
        const keyframeDisabled = computed(() => (!feature.value.real && !feature.value.shouldInterpolate)
            || (props.track.length === 1 && frameRef.value === props.track.begin));
        function toggleKeyframe() {
            if (!keyframeDisabled.value) {
                props.track.toggleKeyframe(frameRef.value);
            }
        }
        function toggleInterpolation() {
            props.track.toggleInterpolation(frameRef.value);
        }
        function gotoNext() {
            const nextFrame = props.track.getNextKeyframe(frameRef.value + 1);
            if (nextFrame !== undefined) {
                emit('seek', nextFrame);
            }
        }
        function gotoPrevious() {
            const previousFrame = props.track.getPreviousKeyframe(frameRef.value - 1);
            if (previousFrame !== undefined) {
                emit('seek', previousFrame);
            }
        }
        function setTrackType(type) {
            cameraStore.setTrackType(props.track.id, type);
        }
        function openMultiCamTools() {
            if (context.state.active !== 'MultiCamTools') {
                context.toggle('MultiCamTools');
            }
        }
        return {
            /* data */
            feature,
            isTrack,
            style,
            frame: frameRef,
            allTypes: allTypesRef,
            keyframeDisabled,
            trackFilters,
            readOnlyMode,
            multiCam,
            /* methods */
            gotoNext,
            gotoPrevious,
            handler,
            openMultiCamTools,
            toggleInterpolation,
            toggleKeyframe,
            setTrackType,
            typeStyling,
        };
    },
});
