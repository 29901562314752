import Vue from 'vue';
import { computed, defineComponent, ref, } from '@vue/composition-api';
import { filterByGlob } from 'platform/desktop/sharedUtils';
import { useApi, } from 'dive-common/apispec';
import ImportMultiCamCameraGroup from 'dive-common/components/ImportMultiCamCameraGroup.vue';
import ImportMultiCamChooseSource from 'dive-common/components/ImportMultiCamChooseSource.vue';
import ImportMultiCamChooseAnnotation from 'dive-common/components/ImportMultiCamChooseAnnotation.vue';
import ImportMultiCamAddType from 'dive-common/components/ImportMultiCamAddType.vue';
import { ImageSequenceType, VideoType } from 'dive-common/constants';
import { useRequest } from 'dive-common/use';
export default defineComponent({
    components: {
        ImportMultiCamCameraGroup,
        ImportMultiCamChooseAnnotation,
        ImportMultiCamChooseSource,
        ImportMultiCamAddType,
    },
    name: 'ImportMultiCamDialog',
    props: {
        stereo: {
            type: Boolean,
            required: false,
        },
        dataType: {
            type: String,
            default: ImageSequenceType,
        },
        importMedia: {
            type: Function,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { openFromDisk } = useApi();
        const importType = ref('');
        const folderList = ref({});
        const keywordFolder = ref('');
        const pendingImportPayloads = ref({});
        const globList = ref({});
        const calibrationFile = ref('');
        const defaultDisplay = ref('left');
        const importAnnotationFilesCheck = ref(false);
        const { error: importError, request: importRequest } = useRequest();
        const clearCameraSet = () => {
            keywordFolder.value = '';
            if (props.stereo) {
                folderList.value = {
                    left: { sourcePath: '', trackFile: '' },
                    right: { sourcePath: '', trackFile: '' },
                };
                globList.value = {
                    left: { glob: '', trackFile: '' },
                    right: { glob: '', trackFile: '' },
                };
            }
            else {
                folderList.value = {};
                globList.value = {};
            }
            if (importType.value === 'multi') {
                if (props.stereo) {
                    pendingImportPayloads.value = {
                        left: null,
                        right: null,
                    };
                }
                else {
                    pendingImportPayloads.value = {};
                }
            }
            else if (importType.value === 'keyword') {
                pendingImportPayloads.value = { keyword: null };
            }
        };
        clearCameraSet();
        if (props.dataType === VideoType) {
            importType.value = 'multi';
        }
        const displayKeys = computed(() => {
            if (importType.value === 'multi')
                return Object.keys(folderList.value);
            if (importType.value === 'keyword')
                return Object.keys(globList.value);
            return [];
        });
        const globImages = computed(() => {
            const filtered = {};
            Object.entries(globList.value).forEach(([cameraName, val]) => {
                const payload = pendingImportPayloads.value.keyword;
                filtered[cameraName] = filterByGlob(val.glob, payload === null || payload === void 0 ? void 0 : payload.jsonMeta.originalImageFiles);
            });
            return filtered;
        });
        const folderImages = computed(() => {
            const filtered = {};
            Object.entries(folderList.value).forEach(([cameraName]) => {
                const payload = pendingImportPayloads.value[cameraName];
                filtered[cameraName] = (payload === null || payload === void 0 ? void 0 : payload.jsonMeta.originalImageFiles) || [];
            });
            return filtered;
        });
        const filteredImages = computed(() => (importType.value === 'keyword'
            ? globImages.value : folderImages.value));
        const errorMessage = computed(() => {
            if (importError.value) {
                return importError.value;
            }
            if (Object.values(pendingImportPayloads.value).length === 0
                || Object.values(pendingImportPayloads.value).some((v) => v === null)
                || importType.value === '') {
                /* Wait until every image list has been loaded successfully to check more */
                return null;
            }
            if (importType.value === 'keyword' && Object.keys(globList.value).length === 0) {
                return 'Add at least 1 filter pattern';
            }
            if (props.dataType === 'video') {
                return null;
            }
            const entries = Object.entries(filteredImages.value);
            let length = -1;
            let totalList = [];
            for (let i = 0; i < entries.length; i += 1) {
                const [cameraName, images] = entries[i];
                if (!images.length) {
                    return `Requires filtered Images for ${cameraName} `;
                }
                if (length === -1) {
                    length = images.length;
                }
                if (length !== images.length) {
                    return `All cameras should have the same length of ${length}`;
                }
                if (totalList.some((imageName) => images.includes(imageName))) {
                    return 'Overlapping values.  All cameras must consist of mutually exclusive images.';
                }
                totalList = totalList.concat(images);
            }
            return null;
        });
        const nextSteps = computed(() => {
            if (errorMessage.value !== null) {
                return false;
            }
            if (importType.value === 'multi') {
                const entries = Object.entries(folderList.value);
                const filterLength = entries.filter(([, val]) => val.sourcePath !== '').length;
                if (entries.length === filterLength && entries.length) {
                    return true;
                }
                return false;
            }
            if (importType.value === 'keyword' && keywordFolder.value) {
                return true;
            }
            return false;
        });
        async function openAnnotationFile(folder) {
            var _a;
            const ret = await openFromDisk('annotation');
            if (!ret.canceled) {
                if ((_a = ret.filePaths) === null || _a === void 0 ? void 0 : _a.length) {
                    const path = ret.filePaths[0];
                    folderList.value[folder].trackFile = path;
                }
            }
        }
        async function open(dstype, folder) {
            const ret = await openFromDisk(dstype, dstype === 'image-sequence');
            if (!ret.canceled) {
                const path = ret.filePaths[0];
                if (folder === 'calibration') {
                    calibrationFile.value = path;
                }
                else if (importType.value === 'multi') {
                    if (ret.root) {
                        folderList.value[folder].sourcePath = ret.root;
                    }
                    else {
                        folderList.value[folder].sourcePath = path;
                    }
                    folderList.value[folder].trackFile = '';
                    Vue.set(pendingImportPayloads.value, folder, await importRequest(() => props.importMedia(path)));
                }
                else if (importType.value === 'keyword') {
                    [keywordFolder.value] = ret.filePaths;
                    if (ret.root) {
                        keywordFolder.value = ret.root;
                    }
                    pendingImportPayloads.value.keyword = await importRequest(() => props.importMedia(path));
                }
            }
        }
        const deleteSet = (key) => {
            if (importType.value === 'multi') {
                Vue.delete(folderList.value, key);
                Vue.delete(pendingImportPayloads.value, key);
            }
            else if (importType.value === 'keyword') {
                Vue.delete(globList.value, key);
            }
        };
        const addNewSet = (name) => {
            if (importType.value === 'multi') {
                Vue.set(folderList.value, name, { sourcePath: '', trackFile: '' });
                Vue.set(pendingImportPayloads.value, name, null);
            }
            else if (importType.value === 'keyword') {
                Vue.set(globList.value, name, { glob: '', trackFile: '' });
            }
        };
        const prepForImport = () => {
            if (!importAnnotationFilesCheck.value) {
                Object.keys(folderList.value).forEach((key) => {
                    folderList.value[key].trackFile = '';
                });
            }
            if (importType.value === 'multi') {
                const args = {
                    defaultDisplay: defaultDisplay.value,
                    sourceList: folderList.value,
                    calibrationFile: calibrationFile.value,
                    type: props.dataType,
                };
                emit('begin-multicam-import', args);
            }
            else if (importType.value === 'keyword') {
                const args = {
                    defaultDisplay: defaultDisplay.value,
                    sourcePath: keywordFolder.value,
                    globList: globList.value,
                    calibrationFile: calibrationFile.value,
                    type: 'image-sequence',
                };
                emit('begin-multicam-import', args);
            }
        };
        return {
            errorMessage,
            nextSteps,
            importType,
            folderList,
            keywordFolder,
            pendingImportPayloads,
            globList,
            filteredImages,
            calibrationFile,
            defaultDisplay,
            displayKeys,
            importAnnotationFilesCheck,
            //Methods
            open,
            prepForImport,
            addNewSet,
            clearCameraSet,
            deleteSet,
            openAnnotationFile,
        };
    },
});
