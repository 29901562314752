import { computed, defineComponent, ref, } from '@vue/composition-api';
import { useAnnotationSet, useAnnotationSets, useDatasetId, useHandler, useTrackStyleManager, } from 'vue-media-annotator/provides';
export default defineComponent({
    name: 'AnnotationSets',
    description: 'Annotation Sets',
    setup(_, { root }) {
        const currentSet = useAnnotationSet();
        const sets = useAnnotationSets();
        const datasetId = useDatasetId();
        const { typeStyling } = useTrackStyleManager();
        const newSet = ref('');
        const validForm = ref(false);
        const { save, setChange, reloadAnnotations } = useHandler();
        const selectSet = (set) => {
            if ((set !== currentSet.value && set !== 'default') || ((set === 'default') && currentSet.value)) {
                setChange(set);
            }
        };
        const selectedSet = ref(currentSet.value || 'default');
        const compareChecks = ref(sets.value.map((item) => ({ name: item, checked: false })));
        const selectedComparisons = computed(() => compareChecks.value.filter((item) => item.checked).map((item) => item.name));
        const launchComparison = () => {
            const set = currentSet.value ? `/set/${currentSet.value}` : '';
            root.$router.replace({
                path: `/viewer/${datasetId.value}${set}`,
                query: { comparisonSets: selectedComparisons.value },
            });
            reloadAnnotations();
        };
        const selectForComparison = (set) => {
            compareChecks.value = sets.value.map((item) => ({ name: item, checked: set === item }));
        };
        const computedSets = computed(() => {
            if (!sets.value.length) {
                return sets.value.concat(['default']);
            }
            return sets.value;
        });
        const addSet = async () => {
            await save(newSet.value);
            setChange(newSet.value);
        };
        return {
            currentSet,
            selectSet,
            sets,
            newSet,
            addSet,
            validForm,
            computedSets,
            typeStyling,
            selectedSet,
            compareChecks,
            selectedComparisons,
            launchComparison,
            selectForComparison,
        };
    },
});
