import { defineComponent, reactive, watch, } from '@vue/composition-api';
import { usePrompt } from 'dive-common/vue-utilities/prompt-service';
import context from 'dive-common/store/context';
import { injectAggregateController } from '../annotators/useMediaController';
export default defineComponent({
    name: 'Control',
    setup() {
        const data = reactive({
            frame: 0,
            dragging: false,
        });
        const mediaController = injectAggregateController().value;
        const { visible } = usePrompt();
        watch(mediaController.frame, (frame) => {
            if (!data.dragging) {
                data.frame = frame;
            }
        });
        const dragHandler = {
            start() { data.dragging = true; },
            end() { data.dragging = false; },
        };
        function input(value) {
            if (mediaController.frame.value !== value) {
                mediaController.seek(value);
            }
            data.frame = value;
        }
        function togglePlay(_, keyEvent) {
            // Prevent scroll from spacebar and other default effects.
            keyEvent.preventDefault();
            if (mediaController.playing.value) {
                mediaController.pause();
            }
            else {
                mediaController.play();
            }
        }
        function toggleEnhancements() {
            context.toggle('ImageEnhancements');
        }
        return {
            data,
            mediaController,
            dragHandler,
            input,
            togglePlay,
            toggleEnhancements,
            visible,
        };
    },
});
