import { computed, defineComponent, } from '@vue/composition-api';
import AttributeKeyFilterVue from 'vue-media-annotator/components/AttributeFilter/AttributeKeyFilter.vue';
import { useAttributesFilters, useAttributes } from '../provides';
import TooltipBtn from './TooltipButton.vue';
/* Magic numbers involved in height calculation */
export default defineComponent({
    name: 'AttributeTimeline',
    props: {
        height: {
            type: Number,
            default: 200,
        },
        width: {
            type: Number,
            default: 300,
        },
    },
    components: {
        TooltipBtn,
        AttributeKeyFilter: AttributeKeyFilterVue,
    },
    setup() {
        const { setTimelineEnabled, setTimelineFilter, timelineFilter, timelineEnabled, } = useAttributesFilters();
        const attributesList = useAttributes();
        const filterNames = computed(() => {
            const data = ['all'];
            return data.concat(attributesList.value.map((item) => item.name));
        });
        return {
            setTimelineEnabled,
            setTimelineFilter,
            timelineEnabled,
            timelineFilter,
            filterNames,
        };
    },
});
