import { ref } from '@vue/composition-api';
/**
 * Track manages the state of a track, its
 * frame data, and all metadata.
 */
export default class BaseAnnotation {
    constructor(id, { meta = {}, begin = Infinity, end = 0, confidencePairs = [], attributes = {}, }) {
        this.id = id;
        this.meta = meta;
        this.attributes = attributes;
        this.revision = ref(1);
        this.begin = begin;
        this.end = end;
        this.confidencePairs = confidencePairs;
        this.notifierEnabled = true;
    }
    get length() {
        return (this.end - this.begin) + 1;
    }
    // eslint-disable-next-line class-methods-use-this
    isInitialized() {
        return true;
    }
    depend() {
        return this.revision.value;
    }
    /* Call if the bounds were possibly expanded */
    maybeExpandBounds(frame) {
        const oldval = [this.begin, this.end];
        if (frame < this.begin) {
            // frame below begin
            this.begin = frame;
            this.notify('bounds', oldval);
        }
        else if (frame > this.end) {
            // frame above end
            this.end = frame;
            this.notify('bounds', oldval);
        }
    }
    notify(name, oldValue = undefined) {
        /* Prevent broadcast until the first feature is initialized */
        if (this.isInitialized() && this.notifierEnabled) {
            this.revision.value += 1;
            if (this.notifier) {
                this.notifier({
                    item: this,
                    event: name,
                    oldValue,
                });
            }
        }
    }
    setNotifier(notifier) {
        this.notifier = notifier;
    }
    getType(index = 0) {
        if (this.confidencePairs.length > 0 && this.confidencePairs[index]) {
            return this.confidencePairs[index];
        }
        throw new Error('Index Error: The requested confidencePairs index does not exist.');
    }
    removeTypes(types) {
        if (this.confidencePairs.length > 0) {
            const old = this.confidencePairs;
            this.confidencePairs = this.confidencePairs.filter(([type]) => !types.includes(type));
            this.notify('confidencePairs', old);
        }
        return this.confidencePairs;
    }
    setType(annotationType, confidenceVal = 1, replace) {
        const old = this.confidencePairs;
        if (confidenceVal >= 1) {
            // dont' allow confidence greater than 1
            this.confidencePairs = [[annotationType, 1]];
        }
        else {
            const index = this.confidencePairs.findIndex(([a]) => a === annotationType);
            this.confidencePairs.splice(index, index >= 0 ? 1 : 0, [annotationType, confidenceVal]);
            if (replace) {
                const replaceIndex = this.confidencePairs.findIndex(([a]) => a === replace);
                if (replaceIndex >= 0)
                    this.confidencePairs.splice(replaceIndex, 1);
            }
            this.confidencePairs.sort((a, b) => b[1] - a[1]);
        }
        this.notify('confidencePairs', old);
    }
    setAttribute(key, value, user = null) {
        let oldval = this.attributes[key];
        if (user !== null) {
            if (this.attributes.userAttributes === undefined) {
                this.attributes.userAttributes = {};
            }
            if (this.attributes.userAttributes[user] === undefined) {
                this.attributes.userAttributes[user] = {};
            }
            oldval = this.attributes.userAttributes[user];
            this.attributes.userAttributes[user][key] = value;
        }
        else {
            this.attributes[key] = value;
        }
        this.notify('attributes', { key, value: oldval });
    }
    /**
     * Figure out if any confidence pairs are above any corresponding thresholds
     */
    static exceedsThreshold(pairs, thresholds) {
        const defaultThresh = thresholds.default || 0;
        return pairs.filter(([name, value]) => value >= (thresholds[name] || defaultThresh));
    }
}
