import { cloneDeep } from 'lodash';
import { ref } from '@vue/composition-api';
import Vue from 'vue';
import { removePoint } from 'vue-media-annotator/utils';
const EmptyResponse = { data: {}, union: [], unionWithoutBounds: [] };
export default class PolygonBoundsExpand {
    constructor() {
        this.bus = new Vue();
        this.active = ref(false);
        this.name = 'PolygonBase';
        this.toggleable = ref(true);
        this.icon = ref('mdi-vector-polygon');
    }
    update(mode, frameNum, track, data, key) {
        if (data.length === 1 && mode === 'editing' && this.active.value) {
            const poly = data[0].geometry;
            if (poly.type === 'Polygon') {
                return {
                    data: {
                        [key || '']: data,
                    },
                    union: [],
                    done: true,
                    unionWithoutBounds: [poly],
                };
            }
        }
        return EmptyResponse;
    }
    // eslint-disable-next-line class-methods-use-this
    delete(frame, track, key, type) {
        if (key === '' && type === 'Polygon') {
            track.removeFeatureGeometry(frame, { key: '', type: 'Polygon' });
        }
    }
    // eslint-disable-next-line class-methods-use-this
    deletePoint(frame, track, idx, key, type) {
        if (key === '' && type === 'Polygon') {
            const geoJsonFeatures = track.getFeatureGeometry(frame, {
                type: 'Polygon',
                key: '',
            });
            if (geoJsonFeatures.length === 0)
                return;
            const clone = cloneDeep(geoJsonFeatures[0]);
            if (removePoint(clone, idx)) {
                track.setFeature({ frame }, [clone]);
            }
        }
    }
    // eslint-disable-next-line class-methods-use-this
    activate() {
        // no-op
        this.active.value = true;
        this.bus.$emit('activate', {
            editing: 'Polygon',
            key: '',
            recipeName: this.name,
        });
    }
    // eslint-disable-next-line class-methods-use-this
    deactivate() {
        // no-op
        this.active.value = false;
    }
    // eslint-disable-next-line class-methods-use-this
    mousetrap() {
        return [];
    }
}
